import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74b60291"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width"]
const _hoisted_2 = ["rowspan", "colspan"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "static-table",
    style: _normalizeStyle({ width: _ctx.totalWidth + 'px' })
  }, [
    _createElementVNode("table", {
      class: _normalizeClass({
        'theme': _ctx.theme,
        'row-header': _ctx.theme?.rowHeader,
        'row-footer': _ctx.theme?.rowFooter,
        'col-header': _ctx.theme?.colHeader,
        'col-footer': _ctx.theme?.colFooter,
      }),
      style: _normalizeStyle(`--themeColor: ${_ctx.theme?.color}; --subThemeColor1: ${_ctx.subThemeColor[0]}; --subThemeColor2: ${_ctx.subThemeColor[1]}`)
    }, [
      _createElementVNode("colgroup", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colSizeList, (width, index) => {
          return (_openBlock(), _createElementBlock("col", {
            span: "1",
            key: index,
            width: width
          }, null, 8, _hoisted_1))
        }), 128))
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (rowCells, rowIndex) => {
          return (_openBlock(), _createElementBlock("tr", { key: rowIndex }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(rowCells, (cell, colIndex) => {
              return _withDirectives((_openBlock(), _createElementBlock("td", {
                class: "cell",
                style: _normalizeStyle({
              borderStyle: _ctx.outline.style,
              borderColor: _ctx.outline.color,
              borderWidth: _ctx.outline.width + 'px',
              ..._ctx.getTextStyle(cell.style),
            }),
                key: cell.id,
                rowspan: cell.rowspan,
                colspan: cell.colspan
              }, [
                _createElementVNode("div", {
                  class: "cell-text",
                  innerHTML: _ctx.formatText(cell.text)
                }, null, 8, _hoisted_3)
              ], 12, _hoisted_2)), [
                [_vShow, !_ctx.hideCells.includes(`${rowIndex}_${colIndex}`)]
              ])
            }), 128))
          ]))
        }), 128))
      ])
    ], 6)
  ], 4))
}