
import { defineComponent, ref } from 'vue'
import axios from 'axios'
import {useMainStore, useSlidesStore} from '@/store'
import {storeToRefs} from 'pinia'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default defineComponent({
  name: 'file-input',
  emits: ['change', 'changeVideoSize'],
  props: {
    accept: {
      type: String,
      default: 'video/*',
    },
  },
  components: {
    Loading
  },
  setup(props, { emit }) {
    const isLoading = ref(false)
    const fullPage = ref(true)
    const progresswith = ref<string>('')

    const inputRef = ref<HTMLInputElement>()
    const progressinnerBar = ref<string>('')

    const slidesStore = useSlidesStore()
    const { handleElement } = storeToRefs(useMainStore())

    const handleClick = () => {
      if (!inputRef.value) return
      inputRef.value.value = ''
      inputRef.value.click()
    }
    const handleChange = (e: Event) => {
      const files = (e.target as HTMLInputElement).files
      if (files) emit('change', files)
    }

    const fileUpload = (e: Event) => {
      if (!handleElement.value) return


      startProgress()
      const file_0 = (e.target as HTMLInputElement).files

      const frm = new FormData();
      let data = {
        filePath: "video",

      };

      for (let i in file_0) {
        frm.append('file', file_0[i])

      }

      //frm.append('file', file_list );
      frm.append('data', new Blob([JSON.stringify(data)], {type : 'application/json'}))

      axios.post('/rest/insertLC0File',frm,  {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress:(progressEvent) =>{
          let percentage:number = (progressEvent.loaded*100)/progressEvent.total
          let percentCompleted:number = Math.round(percentage)

          progresswith.value = percentCompleted + '%'

          progressinnerBar.value = 'width: '+progresswith.value;
          console.log(progressinnerBar.value+":"+progresswith.value)
          if(percentCompleted===100){
            stopProgress()
          }
        }
        }).then(response => {
            console.log(response.data.result)
            if (response.data.result == '1' && handleElement.value) {
              console.log("file_size:::"+response.data.files[0].size)
              //alert("https://kls.lms.next-meta.co" + response.data.file_cn)
              const props = { src: "https://kls.lms.next-meta.co" + response.data.file_cn,size:response.data.files[0].size }
              console.log("handleElement.value.id:"+handleElement.value.id)
              slidesStore.updateElement({ id: handleElement.value.id, props })
              if(response.data.files[0].size) {
                emit('changeVideoSize', response.data.files[0].size)
              }


            }
          }).catch(error => {
        console.log(error)

      })
    }
    const startProgress = () => {
      isLoading.value = true
      // simulate AJAX

    }
    const stopProgress = () => {
      isLoading.value = false
    }
    let xxx='70%'
    //startProgress()
    //progressinnerBar.value = 'width: '+ xxx;

    return {
      handleClick,
      handleChange,
      inputRef,
      fileUpload,
      Loading,
      startProgress,
      stopProgress,
      isLoading,
      progresswith,
      progressinnerBar,
    }
  },

})
