import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-de88c808"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbar" }
const _hoisted_2 = { class: "tabs" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentTabs, (tab) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["tab", { 'active': tab.value === _ctx.toolbarState }]),
          key: tab.value,
          onClick: ($event: any) => (_ctx.setToolbarState(tab.value))
        }, _toDisplayString(tab.label), 11, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentPanelComponent)))
    ])
  ]))
}