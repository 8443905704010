
import { defineComponent, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore } from '@/store'
import useGlobalHotkey from '@/hooks/useGlobalHotkey'
import usePasteEvent from '@/hooks/usePasteEvent'

import EditorHeader from './EditorHeader/index.vue'
import Canvas from './Canvas/index.vue'
import CanvasTool from './CanvasTool/index.vue'
import Thumbnails from './Thumbnails/index.vue'
import Toolbar from './Toolbar/index.vue'
import Remark from './Remark/index.vue'
import ExportDialog from './ExportDialog/index.vue'
import SaveDialog from './SaveDialog/index.vue'
import QuizPopDialog from './QuizPopDialog/index.vue'
export default defineComponent({
  name: 'editor',
  components: {
    EditorHeader,
    Canvas,
    CanvasTool,
    Thumbnails,
    Toolbar,
    Remark,
    ExportDialog,
    SaveDialog,
    QuizPopDialog,
  },
  setup() {
    const mainStore = useMainStore()
    const { dialogForExport, dialogSave, dialogQuizPop } = storeToRefs(mainStore)

    const closeExportDialog = () => mainStore.setDialogForExport('')
    const closeSaveDialog = () => mainStore.setDialogSave('')
    const closeQuizPopDialog = () => mainStore.setDialogForQuizPop('')
    const test = () => {
      alert('12345')
    }

    const remarkHeight = ref(40)

    useGlobalHotkey()
    usePasteEvent()

    return {
      remarkHeight,
      dialogForExport,
      dialogSave,
      dialogQuizPop,
      closeExportDialog,
      closeSaveDialog,
      closeQuizPopDialog,
      test,

    }
  },
})
