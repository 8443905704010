
import { computed, defineComponent, onMounted, onUnmounted, PropType, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore, useKeyboardStore } from '@/store'
import { KEYS } from '@/configs/hotkey'
import { ImageClipedEmitData, OperateResizeHandlers } from '@/types/edit'
import { ImageClipDataRange, ImageElementClip } from '@/types/slides'

export default defineComponent({
  name: 'image-clip-handler',
  emits: ['clip'],
  props: {
    src: {
      type: String,
      required: true,
    },
    clipData: {
      type: Object as PropType<ImageElementClip>,
    },
    clipPath: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    top: {
      type: Number,
      required: true,
    },
    left: {
      type: Number,
      required: true,
    },
    rotate: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { canvasScale } = storeToRefs(useMainStore())
    const { ctrlOrShiftKeyActive } = storeToRefs(useKeyboardStore())

    const clipWrapperPositionStyle = ref({
      top: '0',
      left: '0',
    })
    const isSettingClipRange = ref(false)
    const currentRange = ref<ImageClipDataRange | null>(null)

    // 클립 영역 정보 가져오기 (원래 그림의 가로세로 비율, 원래 그림의 위치)
    const getClipDataTransformInfo = () => {
      const [start, end] = props.clipData ? props.clipData.range : [[0, 0], [100, 100]]

      const widthScale = (end[0] - start[0]) / 100
      const heightScale = (end[1] - start[1]) / 100
      const left = start[0] / widthScale
      const top = start[1] / heightScale

      return { widthScale, heightScale, left, top }
    }
    
    // 하단 이미지 위치 크기(마스크 영역 이미지)
    const imgPosition = computed(() => {
      const { widthScale, heightScale, left, top } = getClipDataTransformInfo()
      return {
        left: -left,
        top: -top,
        width: 100 / widthScale,
        height: 100 / heightScale,
      }
    })

    // 하단 이미지 위치 크기 스타일(마스크 영역 이미지)
    const bottomImgPositionStyle = computed(() => {
      return {
        top: imgPosition.value.top + '%',
        left: imgPosition.value.left + '%',
        width: imgPosition.value.width + '%',
        height: imgPosition.value.height + '%',
      }
    })

    // 상단 이미지 컨테이너 위치 크기(커팅 하이라이트 영역)
    const topImgWrapperPosition = ref({
      top: 0,
      left: 0,
      width: 0,
      height: 0,
    })

    // 상단 이미지 컨테이너 위치 크기 스타일(커팅 하이라이트 영역)
    const topImgWrapperPositionStyle = computed(() => {
      const { top, left, width, height } = topImgWrapperPosition.value
      return {
        top: top + '%',
        left: left + '%',
        width: width + '%',
        height: height + '%',
      }
    })

    // 최상위 이미지 위치 크기 스타일(컷존 이미지)
    const topImgPositionStyle = computed(() => {
      const bottomWidth = imgPosition.value.width
      const bottomHeight = imgPosition.value.height
      
      const { top, left, width, height } = topImgWrapperPosition.value
      
      return {
        left: -left * (100 / width) + '%',
        top: -top * (100 / height) + '%',
        width: bottomWidth / width * 100 + '%',
        height: bottomHeight / height * 100 + '%',
      }
    })

    // 트리밍 위치 정보 초기화
    const initClipPosition = () => {
      const { left, top } = getClipDataTransformInfo()
      topImgWrapperPosition.value = {
        left: left,
        top: top,
        width: 100,
        height: 100,
      }
      
      clipWrapperPositionStyle.value = {
        top: -top + '%',
        left: -left + '%',
      }
    }

    // 잘라내기 수행: 잘라낸 그림의 크기와 잘라낸 정보를 계산하고 데이터를 동기화합니다.
    const handleClip = () => {
      if (isSettingClipRange.value) return

      if (!currentRange.value) {
        emit('clip', null)
        return
      }

      const { left, top } = getClipDataTransformInfo()

      const position = {
        left: (topImgWrapperPosition.value.left - left) / 100 * props.width,
        top: (topImgWrapperPosition.value.top - top) / 100 * props.height,
        width: (topImgWrapperPosition.value.width - 100) / 100 * props.width,
        height: (topImgWrapperPosition.value.height - 100) / 100 * props.height,
      }

      const clipedEmitData: ImageClipedEmitData = {
        range: currentRange.value,
        position,
      }
      emit('clip', clipedEmitData)
    }

    // 단축키 수신: 차량 후진 확인 자르기
    const keyboardListener = (e: KeyboardEvent) => {
      const key = e.key.toUpperCase()
      if (key === KEYS.ENTER) handleClip()
    }

    onMounted(() => {
      initClipPosition()
      document.addEventListener('keydown', keyboardListener)
    })
    onUnmounted(() => {
      document.removeEventListener('keydown', keyboardListener)
    })

    // 트리밍 영역 데이터 계산 및 업데이트
    const updateRange = () => {
      const retPosition = {
        left: parseInt(topImgPositionStyle.value.left),
        top: parseInt(topImgPositionStyle.value.top),
        width: parseInt(topImgPositionStyle.value.width),
        height: parseInt(topImgPositionStyle.value.height),
      }

      const widthScale = 100 / retPosition.width
      const heightScale = 100 / retPosition.height

      const start: [number, number] = [
        -retPosition.left * widthScale,
        -retPosition.top * heightScale,
      ]
      const end: [number, number] = [
        widthScale * 100 + start[0],
        heightScale * 100 + start[1],
      ]

      currentRange.value = [start, end]
    }

    // 잘라내기 영역 이동
    const moveClipRange = (e: MouseEvent) => {
      isSettingClipRange.value = true
      let isMouseDown = true

      const startPageX = e.pageX
      const startPageY = e.pageY
      const bottomPosition = imgPosition.value
      const originPositopn = { ...topImgWrapperPosition.value }

      document.onmousemove = e => {
        if (!isMouseDown) return

        const currentPageX = e.pageX
        const currentPageY = e.pageY

        let moveX = (currentPageX - startPageX) / canvasScale.value / props.width * 100
        let moveY = (currentPageY - startPageY) / canvasScale.value / props.height * 100

        if (props.rotate > 45 && props.rotate < 135) {
          moveX = (currentPageY - startPageY) / canvasScale.value / props.width * 100
          moveY = -(currentPageX - startPageX) / canvasScale.value / props.height * 100
        }
        if ((props.rotate >= 135 && props.rotate <= 180) || (props.rotate >= -180 && props.rotate <= -135)) {
          moveX = -moveX
          moveY = -moveY
        }
        if (props.rotate > -135 && props.rotate < -45) {
          moveX = -(currentPageY - startPageY) / canvasScale.value / props.width * 100
          moveY = (currentPageX - startPageX) / canvasScale.value / props.height * 100
        }

        let targetLeft = originPositopn.left + moveX
        let targetTop = originPositopn.top + moveY

        if (targetLeft < 0) targetLeft = 0
        else if (targetLeft + originPositopn.width > bottomPosition.width) {
          targetLeft = bottomPosition.width - originPositopn.width
        }
        if (targetTop < 0) targetTop = 0
        else if (targetTop + originPositopn.height > bottomPosition.height) {
          targetTop = bottomPosition.height - originPositopn.height
        }
        
        topImgWrapperPosition.value = {
          ...topImgWrapperPosition.value,
          left: targetLeft,
          top: targetTop,
        }
      }

      document.onmouseup = () => {
        isMouseDown = false
        document.onmousemove = null
        document.onmouseup = null

        updateRange()

        setTimeout(() => {
          isSettingClipRange.value = false
        }, 0)
      }
    }

    // 잘라낸 영역 확대/ 축소
    const scaleClipRange = (e: MouseEvent, type: OperateResizeHandlers) => {
      isSettingClipRange.value = true
      let isMouseDown = true

      const minWidth = 50 / props.width * 100
      const minHeight = 50 / props.height * 100
      
      const startPageX = e.pageX
      const startPageY = e.pageY
      const bottomPosition = imgPosition.value
      const originPositopn = { ...topImgWrapperPosition.value }

      const aspectRatio = topImgWrapperPosition.value.width / topImgWrapperPosition.value.height

      document.onmousemove = e => {
        if (!isMouseDown) return

        const currentPageX = e.pageX
        const currentPageY = e.pageY

        let moveX = (currentPageX - startPageX) / canvasScale.value / props.width * 100
        let moveY = (currentPageY - startPageY) / canvasScale.value / props.height * 100

        if (props.rotate > 45 && props.rotate < 135) {
          moveX = (currentPageY - startPageY) / canvasScale.value / props.width * 100
          moveY = -(currentPageX - startPageX) / canvasScale.value / props.height * 100
        }
        if ((props.rotate >= 135 && props.rotate <= 180) || (props.rotate >= -180 && props.rotate <= -135)) {
          moveX = -moveX
          moveY = -moveY
        }
        if (props.rotate > -135 && props.rotate < -45) {
          moveX = -(currentPageY - startPageY) / canvasScale.value / props.width * 100
          moveY = (currentPageX - startPageX) / canvasScale.value / props.height * 100
        }

        if (ctrlOrShiftKeyActive.value) {
          if (type === OperateResizeHandlers.RIGHT_BOTTOM || type === OperateResizeHandlers.LEFT_TOP) moveY = moveX / aspectRatio
          if (type === OperateResizeHandlers.LEFT_BOTTOM || type === OperateResizeHandlers.RIGHT_TOP) moveY = -moveX / aspectRatio
        }

        let targetLeft, targetTop, targetWidth, targetHeight

        if (type === OperateResizeHandlers.LEFT_TOP) {
          if (originPositopn.left + moveX < 0) {
            moveX = -originPositopn.left
          }
          if (originPositopn.top + moveY < 0) {
            moveY = -originPositopn.top
          }
          if (originPositopn.width - moveX < minWidth) {
            moveX = originPositopn.width - minWidth
          }
          if (originPositopn.height - moveY < minHeight) {
            moveY = originPositopn.height - minHeight
          }
          targetWidth = originPositopn.width - moveX
          targetHeight = originPositopn.height - moveY
          targetLeft = originPositopn.left + moveX
          targetTop = originPositopn.top + moveY
        }
        else if (type === OperateResizeHandlers.RIGHT_TOP) {
          if (originPositopn.left + originPositopn.width + moveX > bottomPosition.width) {
            moveX = bottomPosition.width - (originPositopn.left + originPositopn.width)
          }
          if (originPositopn.top + moveY < 0) {
            moveY = -originPositopn.top
          }
          if (originPositopn.width + moveX < minWidth) {
            moveX = minWidth - originPositopn.width
          }
          if (originPositopn.height - moveY < minHeight) {
            moveY = originPositopn.height - minHeight
          }
          targetWidth = originPositopn.width + moveX
          targetHeight = originPositopn.height - moveY
          targetLeft = originPositopn.left
          targetTop = originPositopn.top + moveY
        }
        else if (type === OperateResizeHandlers.LEFT_BOTTOM) {
          if (originPositopn.left + moveX < 0) {
            moveX = -originPositopn.left
          }
          if (originPositopn.top + originPositopn.height + moveY > bottomPosition.height) {
            moveY = bottomPosition.height - (originPositopn.top + originPositopn.height)
          }
          if (originPositopn.width - moveX < minWidth) {
            moveX = originPositopn.width - minWidth
          }
          if (originPositopn.height + moveY < minHeight) {
            moveY = minHeight - originPositopn.height
          }
          targetWidth = originPositopn.width - moveX
          targetHeight = originPositopn.height + moveY
          targetLeft = originPositopn.left + moveX
          targetTop = originPositopn.top
        }
        else if (type === OperateResizeHandlers.RIGHT_BOTTOM) {
          if (originPositopn.left + originPositopn.width + moveX > bottomPosition.width) {
            moveX = bottomPosition.width - (originPositopn.left + originPositopn.width)
          }
          if (originPositopn.top + originPositopn.height + moveY > bottomPosition.height) {
            moveY = bottomPosition.height - (originPositopn.top + originPositopn.height)
          }
          if (originPositopn.width + moveX < minWidth) {
            moveX = minWidth - originPositopn.width
          }
          if (originPositopn.height + moveY < minHeight) {
            moveY = minHeight - originPositopn.height
          }
          targetWidth = originPositopn.width + moveX
          targetHeight = originPositopn.height + moveY
          targetLeft = originPositopn.left
          targetTop = originPositopn.top
        }
        else if (type === OperateResizeHandlers.TOP) {
          if (originPositopn.top + moveY < 0) {
            moveY = -originPositopn.top
          }
          if (originPositopn.height - moveY < minHeight) {
            moveY = originPositopn.height - minHeight
          }
          targetWidth = originPositopn.width
          targetHeight = originPositopn.height - moveY
          targetLeft = originPositopn.left
          targetTop = originPositopn.top + moveY
        }
        else if (type === OperateResizeHandlers.BOTTOM) {
          if (originPositopn.top + originPositopn.height + moveY > bottomPosition.height) {
            moveY = bottomPosition.height - (originPositopn.top + originPositopn.height)
          }
          if (originPositopn.height + moveY < minHeight) {
            moveY = minHeight - originPositopn.height
          }
          targetWidth = originPositopn.width
          targetHeight = originPositopn.height + moveY
          targetLeft = originPositopn.left
          targetTop = originPositopn.top
        }
        else if (type === OperateResizeHandlers.LEFT) {
          if (originPositopn.left + moveX < 0) {
            moveX = -originPositopn.left
          }
          if (originPositopn.width - moveX < minWidth) {
            moveX = originPositopn.width - minWidth
          }
          targetWidth = originPositopn.width - moveX
          targetHeight = originPositopn.height
          targetLeft = originPositopn.left + moveX
          targetTop = originPositopn.top
        }
        else {
          if (originPositopn.left + originPositopn.width + moveX > bottomPosition.width) {
            moveX = bottomPosition.width - (originPositopn.left + originPositopn.width)
          }
          if (originPositopn.width + moveX < minWidth) {
            moveX = minWidth - originPositopn.width
          }
          targetHeight = originPositopn.height
          targetWidth = originPositopn.width + moveX
          targetLeft = originPositopn.left
          targetTop = originPositopn.top
        }

        topImgWrapperPosition.value = {
          left: targetLeft,
          top: targetTop,
          width: targetWidth,
          height: targetHeight,
        }
      }

      document.onmouseup = () => {
        isMouseDown = false
        document.onmousemove = null
        document.onmouseup = null

        updateRange()

        setTimeout(() => isSettingClipRange.value = false, 0)
      }
    }

    const rotateClassName = computed(() => {
      const prefix = 'rotate-'
      const rotate = props.rotate
      if (rotate > -22.5 && rotate <= 22.5) return prefix + 0
      else if (rotate > 22.5 && rotate <= 67.5) return prefix + 45
      else if (rotate > 67.5 && rotate <= 112.5) return prefix + 90
      else if (rotate > 112.5 && rotate <= 157.5) return prefix + 135
      else if (rotate > 157.5 || rotate <= -157.5) return prefix + 0
      else if (rotate > -157.5 && rotate <= -112.5) return prefix + 45
      else if (rotate > -112.5 && rotate <= -67.5) return prefix + 90
      else if (rotate > -67.5 && rotate <= -22.5) return prefix + 135
      return prefix + 0
    })

    const cornerPoint = [
      OperateResizeHandlers.LEFT_TOP,
      OperateResizeHandlers.RIGHT_TOP,
      OperateResizeHandlers.LEFT_BOTTOM,
      OperateResizeHandlers.RIGHT_BOTTOM,
    ]
    const edgePoints = [
      OperateResizeHandlers.TOP,
      OperateResizeHandlers.BOTTOM,
      OperateResizeHandlers.LEFT,
      OperateResizeHandlers.RIGHT,
    ]

    return {
      clipWrapperPositionStyle,
      bottomImgPositionStyle,
      topImgWrapperPositionStyle,
      topImgPositionStyle,
      rotateClassName,
      edgePoints,
      cornerPoint,
      handleClip,
      moveClipRange,
      scaleClipRange,
    }
  },
})
