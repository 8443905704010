import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b7f87f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bitist-editor" }
const _hoisted_2 = { class: "layout-content" }
const _hoisted_3 = { class: "layout-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditorHeader = _resolveComponent("EditorHeader")!
  const _component_Thumbnails = _resolveComponent("Thumbnails")!
  const _component_CanvasTool = _resolveComponent("CanvasTool")!
  const _component_Canvas = _resolveComponent("Canvas")!
  const _component_Remark = _resolveComponent("Remark")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_ExportDialog = _resolveComponent("ExportDialog")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_SaveDialog = _resolveComponent("SaveDialog")!
  const _component_QuizPopDialog = _resolveComponent("QuizPopDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_EditorHeader, { class: "layout-header" }),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Thumbnails, { class: "layout-content-left" }),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_CanvasTool, { class: "center-top" }),
          _createVNode(_component_Canvas, {
            class: "center-body",
            style: _normalizeStyle({ height: `calc(100% - ${_ctx.remarkHeight + 40}px)` })
          }, null, 8, ["style"]),
          _createVNode(_component_Remark, {
            class: "center-bottom",
            height: _ctx.remarkHeight,
            "onUpdate:height": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.remarkHeight) = $event)),
            style: _normalizeStyle({ height: `${_ctx.remarkHeight}px` })
          }, null, 8, ["height", "style"])
        ]),
        _createVNode(_component_Toolbar, { class: "layout-content-right" })
      ])
    ]),
    _createVNode(_component_Modal, {
      visible: !!_ctx.dialogForExport,
      footer: null,
      centered: "",
      closable: false,
      width: 680,
      destroyOnClose: "",
      onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeExportDialog()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ExportDialog)
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Modal, {
      visible: !!_ctx.dialogSave,
      footer: null,
      centered: "",
      closable: false,
      width: 720,
      destroyOnClose: "",
      onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeSaveDialog()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_SaveDialog)
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Modal, {
      visible: !!_ctx.dialogQuizPop,
      footer: null,
      centered: "",
      closable: false,
      width: 350,
      destroyOnClose: "",
      onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeQuizPopDialog()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_QuizPopDialog)
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}