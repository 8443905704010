
import {computed, defineComponent, ref, watch} from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore, useSlidesStore } from '@/store'
import {MainTheme, Slide, SlideBackground, SlideTheme} from '@/types/slides'
import { PRESET_THEMES } from '@/configs/theme'
import { WEB_FONTS } from '@/configs/font'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

import ColorButton from './common/ColorButton.vue'
import { getImageDataURL } from '@/utils/image'

const themes = PRESET_THEMES
const webFonts = WEB_FONTS

export default defineComponent({
  name: 'slide-design-panel',
  components: {
    ColorButton,
  },
  setup() {
    const slidesStore = useSlidesStore()
    const { handleElement, availableFonts } = storeToRefs(useMainStore())
    const { slides, currentSlide, viewportRatio, theme ,mainTheme } = storeToRefs(slidesStore)


    const getThemeStyle = (bodyColor1: string, bodyColor2: string) => {

      return 'background: transparent linear-gradient(180deg, '+bodyColor1 + ' 0%, '+bodyColor2+' 100%) 0% 0% no-repeat padding-box;'
    }
//backgroundColor

    const background = computed(() => {
      if (!currentSlide.value.background) {
        return {
          type: 'solid',
          value: '#fff',
        } as SlideBackground
      }
      return currentSlide.value.background
    })

    const { addHistorySnapshot } = useHistorySnapshot()

    // 배경 모드 설정: 퓨어, 이미지, 그라데이션
    const updateBackgroundType = (type: 'solid' | 'image' | 'gradient') => {
      if (type === 'solid') {
        const newBackground: SlideBackground = {
          ...background.value,
          type: 'solid',
          color: background.value.color || '#fff',
        }
        slidesStore.updateSlide({ background: newBackground })
      }
      else if (type === 'image') {
        const newBackground: SlideBackground = {
          ...background.value,
          type: 'image',
          image: background.value.image || '',
          imageSize: background.value.imageSize || 'cover',
        }
        slidesStore.updateSlide({ background: newBackground })
      }
      else {
        const newBackground: SlideBackground = {
          ...background.value,
          type: 'gradient',
          gradientType: background.value.gradientType || 'linear',
          gradientColor: background.value.gradientColor || ['#fff', '#fff'],
          gradientRotate: background.value.gradientRotate || 0,
        }
        slidesStore.updateSlide({ background: newBackground })
      }
      addHistorySnapshot()
    }

    // 배경 그림 설정
    const updateBackground = (props: Partial<SlideBackground>) => {
      slidesStore.updateSlide({ background: { ...background.value, ...props } })
      addHistorySnapshot()
    }

    // 배경 그림 업로드
    const uploadBackgroundImage = (files: File[]) => {
      const imageFile = files[0]
      if (!imageFile) return
      getImageDataURL(imageFile).then(dataURL => updateBackground({ image: dataURL }))
    }

    // 현재 페이지의 배경을 모든 페이지에 적용합니다
    const applyBackgroundAllSlide = () => {
      const newSlides = slides.value.map(slide => {
        return {
          ...slide,
          background: currentSlide.value.background,
        }
      })
      slidesStore.setSlides(newSlides)
      addHistorySnapshot()
    }

    // 테마 설정
    const updateMainTheme = (themeProps: Partial<MainTheme>) => {

      slidesStore.setMainTheme(themeProps)
      applyMainTheme()
    }

    const temp_ = slidesStore.slides

    const applyMainTheme = () =>{

      if(temp_[0] && temp_[0].main_theme) {

        temp_[0].main_theme = {"headerColor1":mainTheme.value.headerColor1,"headerColor2":mainTheme.value.headerColor2,"bodyColor1":mainTheme.value.bodyColor1,"bodyColor2":mainTheme.value.bodyColor2}
      }else {

        slidesStore.slides[0].main_theme = {"headerColor1":mainTheme.value.headerColor1,"headerColor2":mainTheme.value.headerColor2,"bodyColor1":mainTheme.value.bodyColor1,"bodyColor2":mainTheme.value.bodyColor2}

      }
      console.log(JSON.stringify(slidesStore.slides[0]))
    }
    /*
      기본
      headerColor1: '#0abac8',
      headerColor2: '#3D9CC2',
      bodyColor1: '#F86E68',
      bodyColor2: '#FF3749',
     */


    /*

    // 모든 페이지에 현재 테마 적용
    const applyThemeAllSlide = () => {
      const newSlides: Slide[] = JSON.parse(JSON.stringify(slides.value))
      const { themeColor, backgroundColor, fontColor, fontName } = theme.value

      for (const slide of newSlides) {
        if (!slide.background || slide.background.type !== 'image') {
          slide.background = {
            ...slide.background,
            type: 'solid',
            color: backgroundColor
          }
        }

        const elements = slide.elements
        for (const el of elements) {
          if (el.type === 'shape') el.fill = themeColor
          else if (el.type === 'line') el.color = themeColor
          else if (el.type === 'text') {
            el.defaultColor = fontColor
            el.defaultFontName = fontName
            if (el.fill) el.fill = themeColor
          }
          else if (el.type === 'table') {
            if (el.theme) el.theme.color = themeColor
            for (const rowCells of el.data) {
              for (const cell of rowCells) {
                if (cell.style) {
                  cell.style.color = fontColor
                  cell.style.fontname = fontName
                }
              }
            }
          }
          else if (el.type === 'chart') {
            el.themeColor = [themeColor]
            el.gridColor = fontColor
          }
          else if (el.type === 'latex') el.color = fontColor
          else if (el.type === 'audio') el.color = themeColor
        }
      }
      slidesStore.setSlides(newSlides)
      addHistorySnapshot()
    }

     */

    // 미리 설정한 테마를 표시할 지 여부
    const showPresetThemes = ref(true)
    const togglePresetThemesVisible = () => {
      showPresetThemes.value = !showPresetThemes.value
    }

    // 캔버스 크기 설정( 가로 세로 크기)
    const updateViewportRatio = (value: number) => {
      slidesStore.setViewportRatio(value)
    }



    return {
      availableFonts,
      background,
      updateBackgroundType,
      updateBackground,
      uploadBackgroundImage,
      applyBackgroundAllSlide,
      themes,
      theme,
      mainTheme,
      webFonts,
      updateMainTheme,
      applyMainTheme,
      viewportRatio,
      updateViewportRatio,
      showPresetThemes,
      togglePresetThemesVisible,
      getThemeStyle,

    }
  },
})
