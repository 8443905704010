import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f3479f82"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "shape-pool" }
const _hoisted_2 = { class: "category-name" }
const _hoisted_3 = { class: "shape-list" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  overflow: "visible",
  width: "18",
  height: "18"
}
const _hoisted_6 = ["transform"]
const _hoisted_7 = ["fill", "stroke", "d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shapeList, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "category",
        key: item.type
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(item.type), 1),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (shape, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "shape-item",
              key: index
            }, [
              _createElementVNode("div", {
                class: "shape-content",
                onClick: ($event: any) => (_ctx.selectShape(shape))
              }, [
                (_openBlock(), _createElementBlock("svg", _hoisted_5, [
                  _createElementVNode("g", {
                    transform: `scale(${18 / shape.viewBox[0]}, ${18 / shape.viewBox[1]}) translate(0,0) matrix(1,0,0,1,0,0)`
                  }, [
                    _createElementVNode("path", {
                      class: _normalizeClass(["shape-path", { 'outlined': shape.outlined }]),
                      "vector-effect": "non-scaling-stroke",
                      "stroke-linecap": "butt",
                      "stroke-miterlimit": "8",
                      fill: shape.outlined ? '#999' : 'transparent',
                      stroke: shape.outlined ? 'transparent' : '#999',
                      "stroke-width": "2",
                      d: shape.path
                    }, null, 10, _hoisted_7)
                  ], 8, _hoisted_6)
                ]))
              ], 8, _hoisted_4)
            ]))
          }), 128))
        ])
      ]))
    }), 128))
  ]))
}