import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-81197444"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElementOutline = _resolveComponent("ElementOutline")!

  return (_openBlock(), _createElementBlock("div", {
    class: "base-element-text",
    style: _normalizeStyle({
      top: _ctx.elementInfo.top + 'px',
      left: _ctx.elementInfo.left + 'px',
      width: _ctx.elementInfo.width + 'px',
    })
  }, [
    _createElementVNode("div", {
      class: "rotate-wrapper",
      style: _normalizeStyle({ transform: `rotate(${_ctx.elementInfo.rotate}deg)` })
    }, [
      _createElementVNode("div", {
        class: "element-content",
        style: _normalizeStyle({
          backgroundColor: _ctx.elementInfo.fill,
          opacity: _ctx.elementInfo.opacity,
          textShadow: _ctx.shadowStyle,
          lineHeight: _ctx.elementInfo.lineHeight,
          letterSpacing: (_ctx.elementInfo.wordSpace || 0) + 'px',
          color: _ctx.elementInfo.defaultColor,
          fontFamily: _ctx.elementInfo.defaultFontName,
        })
      }, [
        _createVNode(_component_ElementOutline, {
          width: _ctx.elementInfo.width,
          height: _ctx.elementInfo.height,
          outline: _ctx.elementInfo.outline
        }, null, 8, ["width", "height", "outline"]),
        _createElementVNode("div", {
          class: "text ProseMirror-static",
          style: _normalizeStyle(_ctx.cssVar),
          innerHTML: _ctx.elementInfo.content
        }, null, 12, _hoisted_1)
      ], 4)
    ], 4)
  ], 4))
}