
import { defineComponent, nextTick, onMounted, onUnmounted, PropType, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore, useSlidesStore } from '@/store'
import { PPTTableElement, TableCell } from '@/types/slides'
import { ContextmenuItem } from '@/components/Contextmenu/types'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

import EditableTable from './EditableTable.vue'

export default defineComponent({
  name: 'editable-element-table',
  components: {
    EditableTable,
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTTableElement>,
      required: true,
    },
    selectElement: {
      type: Function as PropType<(e: MouseEvent | TouchEvent, element: PPTTableElement, canMove?: boolean) => void>,
      required: true,
    },
    contextmenus: {
      type: Function as PropType<() => ContextmenuItem[] | null>,
    },
  },
  setup(props) {
    const mainStore = useMainStore()
    const slidesStore = useSlidesStore()
    const { canvasScale, handleElementId, isScaling } = storeToRefs(mainStore)
    
    const elementRef = ref<HTMLElement>()

    const { addHistorySnapshot } = useHistorySnapshot()

    const handleSelectElement = (e: MouseEvent | TouchEvent) => {
      if (props.elementInfo.lock) return
      e.stopPropagation()

      props.selectElement(e, props.elementInfo)
    }

    // 테이블 편집 가능 상태를 업데이트합니다. 테이블 편집 시 전역 단축키를 비활성화해야 합니다
    const editable = ref(false)

    watch(handleElementId, () => {
      if (handleElementId.value !== props.elementInfo.id) editable.value = false
    })

    watch(editable, () => {
      mainStore.setDisableHotkeysState(editable.value)
    })

    const startEdit = () => {
      if (!props.elementInfo.lock) editable.value = true
    }

    // 테이블 요소의 크기 변경 사항을 듣고 높이가 변경될 때 vuex로 높이 업데이트
    // 높이가 변경되었을 때 확대/ 축소 작업이 진행 중이면 확대/ 축소 작업이 끝날 때까지 기다립니다다시 업데이트하기
    const realHeightCache = ref(-1)

    watch(isScaling, () => {
      if (handleElementId.value !== props.elementInfo.id) return

      if (isScaling.value) editable.value = false

      if (!isScaling.value && realHeightCache.value !== -1) {
        slidesStore.updateElement({
          id: props.elementInfo.id,
          props: { height: realHeightCache.value },
        })
        realHeightCache.value = -1
      }
    })

    const updateTableElementHeight = (entries: ResizeObserverEntry[]) => {
      const contentRect = entries[0].contentRect
      if (!elementRef.value) return

      const realHeight = contentRect.height

      if (props.elementInfo.height !== realHeight) {
        if (!isScaling.value) {
          slidesStore.updateElement({
            id: props.elementInfo.id,
            props: { height: realHeight },
          })
        }
        else realHeightCache.value = realHeight
      }
    }

    const resizeObserver = new ResizeObserver(updateTableElementHeight)

    onMounted(() => {
      if (elementRef.value) resizeObserver.observe(elementRef.value)
    })
    onUnmounted(() => {
      if (elementRef.value) resizeObserver.unobserve(elementRef.value)
    })

    // 데이터를 업데이트하고 있다. 내용 표
    const updateTableCells = (data: TableCell[][]) => {
      slidesStore.updateElement({
        id: props.elementInfo.id, 
        props: { data },
      })
      addHistorySnapshot()
    }

    // 표의 열 너비 데이터 업데이트
    const updateColWidths = (widths: number[]) => {
      const width = widths.reduce((a, b) => a + b)
      const colWidths = widths.map(item => item / width)

      slidesStore.updateElement({
        id: props.elementInfo.id, 
        props: { width, colWidths },
      })
      addHistorySnapshot()
    }

    // 테이블에서 현재 선택된 셀을 업데이트합니다.
    const updateSelectedCells = (cells: string[]) => {
      nextTick(() => mainStore.setSelectedTableCells(cells))
    }

    return {
      elementRef,
      canvasScale,
      handleSelectElement,
      updateTableCells,
      updateColWidths,
      editable,
      startEdit,
      updateSelectedCells,
    }
  },
})
