import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BorderLine = _resolveComponent("BorderLine")!
  const _component_ResizeHandler = _resolveComponent("ResizeHandler")!

  return (_openBlock(), _createElementBlock("div", {
    class: "multi-select-operate",
    style: _normalizeStyle({
      left: _ctx.range.minX * _ctx.canvasScale + 'px',
      top: _ctx.range.minY * _ctx.canvasScale + 'px',
    })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.borderLines, (line) => {
      return (_openBlock(), _createBlock(_component_BorderLine, {
        key: line.type,
        type: line.type,
        style: _normalizeStyle(line.style)
      }, null, 8, ["type", "style"]))
    }), 128)),
    (!_ctx.disableResize)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.resizeHandlers, (point) => {
          return (_openBlock(), _createBlock(_component_ResizeHandler, {
            key: point.direction,
            type: point.direction,
            style: _normalizeStyle(point.style),
            onMousedown: _withModifiers(($event: any) => (_ctx.scaleMultiElement($event, _ctx.range, point.direction)), ["stop"])
          }, null, 8, ["type", "style", "onMousedown"]))
        }), 128))
      : _createCommentVNode("", true)
  ], 4))
}