
import { computed, defineComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useSlidesStore } from '@/store'
import { TurningMode } from '@/types/slides'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

interface Animations {
  label: string
  value: TurningMode
}

export default defineComponent({
  name: 'slide-animation-panel',
  setup() {
    const slidesStore = useSlidesStore()
    const { slides, currentSlide } = storeToRefs(slidesStore)

    const currentTurningMode = computed(() => currentSlide.value.turningMode || 'slideY')

    const animations: Animations[] = [
      { label: '없음', value: 'no' },
      { label: '천천히들어감', value: 'fade' },
      { label: '좌우추이', value: 'slideX' },
      { label: '상하추이', value: 'slideY' },
    ]

    const { addHistorySnapshot } = useHistorySnapshot()

    // 재생 시 페이지 전환 방법 수정
    const updateTurningMode = (mode: TurningMode) => {
      if (mode === currentTurningMode.value) return
      slidesStore.updateSlide({ turningMode: mode })
      addHistorySnapshot()
    }

    // 현재 페이지의 전환 페이지를 모든 페이지에 적용합니다
    const applyAllSlide = () => {
      const newSlides = slides.value.map(slide => {
        return {
          ...slide,
          turningMode: currentSlide.value.turningMode,
        }
      })
      slidesStore.setSlides(newSlides)
      addHistorySnapshot()
    }

    return {
      currentTurningMode,
      animations,
      updateTurningMode,
      applyAllSlide,
    }
  },
})
