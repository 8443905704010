import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "mobile-editable-element",
    style: _normalizeStyle({
      zIndex: _ctx.elementIndex,
    })
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentElementComponent), {
      elementInfo: _ctx.elementInfo,
      selectElement: _ctx.selectElement,
      contextmenus: () => null
    }, null, 8, ["elementInfo", "selectElement", "contextmenus"]))
  ], 4))
}