import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60f9f1f7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "line-element-operate" }
const _hoisted_2 = ["width", "height", "stroke"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["x1", "y1", "x2", "y2"]
const _hoisted_5 = ["x1", "y1", "x2", "y2"]
const _hoisted_6 = ["x1", "y1", "x2", "y2"]
const _hoisted_7 = ["x1", "y1", "x2", "y2"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResizeHandler = _resolveComponent("ResizeHandler")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.handlerVisible)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resizeHandlers, (point) => {
            return (_openBlock(), _createBlock(_component_ResizeHandler, {
              class: "operate-resize-handler",
              key: point.handler,
              style: _normalizeStyle(point.style),
              onMousedown: _withModifiers($event => _ctx.dragLineElement($event, _ctx.elementInfo, point.handler), ["stop"])
            }, null, 8, ["style", "onMousedown"]))
          }), 128)),
          (_openBlock(), _createElementBlock("svg", {
            width: _ctx.svgWidth || 1,
            height: _ctx.svgHeight || 1,
            stroke: _ctx.elementInfo.color,
            overflow: "visible",
            style: _normalizeStyle({ transform: `scale(${_ctx.canvasScale})` })
          }, [
            (_ctx.elementInfo.curve)
              ? (_openBlock(), _createElementBlock("g", _hoisted_3, [
                  _createElementVNode("line", {
                    class: "anchor-line",
                    x1: _ctx.elementInfo.start[0],
                    y1: _ctx.elementInfo.start[1],
                    x2: _ctx.elementInfo.curve[0],
                    y2: _ctx.elementInfo.curve[1]
                  }, null, 8, _hoisted_4),
                  _createElementVNode("line", {
                    class: "anchor-line",
                    x1: _ctx.elementInfo.end[0],
                    y1: _ctx.elementInfo.end[1],
                    x2: _ctx.elementInfo.curve[0],
                    y2: _ctx.elementInfo.curve[1]
                  }, null, 8, _hoisted_5)
                ]))
              : _createCommentVNode("", true),
            (_ctx.elementInfo.cubic)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.elementInfo.cubic, (item, index) => {
                  return (_openBlock(), _createElementBlock("g", { key: index }, [
                    (index === 0)
                      ? (_openBlock(), _createElementBlock("line", {
                          key: 0,
                          class: "anchor-line",
                          x1: _ctx.elementInfo.start[0],
                          y1: _ctx.elementInfo.start[1],
                          x2: item[0],
                          y2: item[1]
                        }, null, 8, _hoisted_6))
                      : _createCommentVNode("", true),
                    (index === 1)
                      ? (_openBlock(), _createElementBlock("line", {
                          key: 1,
                          class: "anchor-line",
                          x1: _ctx.elementInfo.end[0],
                          y1: _ctx.elementInfo.end[1],
                          x2: item[0],
                          y2: item[1]
                        }, null, 8, _hoisted_7))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              : _createCommentVNode("", true)
          ], 12, _hoisted_2))
        ], 64))
      : _createCommentVNode("", true)
  ]))
}