import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01900375"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "screen-slide-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScreenSlide = _resolveComponent("ScreenSlide")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass([
        'slide-item', 
        `turning-mode-${slide.turningMode || 'slideY'}`,
        {
          'current': index === _ctx.slideIndex,
          'before': index < _ctx.slideIndex,
          'after': index > _ctx.slideIndex,
          'hide': (index === _ctx.slideIndex - 1 || index === _ctx.slideIndex + 1) && slide.turningMode !== _ctx.currentSlide.turningMode,
        }
      ]),
        key: slide.id
      }, [
        (Math.abs(_ctx.slideIndex - index) < 2 || slide.animations?.length)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "slide-content",
              style: _normalizeStyle({
          width: _ctx.slideWidth + 'px',
          height: _ctx.slideHeight + 'px',
        })
            }, [
              _createVNode(_component_ScreenSlide, {
                slide: slide,
                scale: _ctx.scale,
                animationIndex: _ctx.animationIndex,
                turnSlideToId: _ctx.turnSlideToId,
                manualExitFullscreen: _ctx.manualExitFullscreen
              }, null, 8, ["slide", "scale", "animationIndex", "turnSlideToId", "manualExitFullscreen"])
            ], 4))
          : _createCommentVNode("", true)
      ], 2))
    }), 128))
  ]))
}