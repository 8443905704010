
import { computed, defineComponent, nextTick, onMounted, onUnmounted, PropType, ref, watch } from 'vue'
import { debounce, isEqual } from 'lodash'
import { storeToRefs } from 'pinia'
import { nanoid } from 'nanoid'
import { useMainStore } from '@/store'
import { PPTElementOutline, TableCell, TableTheme } from '@/types/slides'
import { ContextmenuItem } from '@/components/Contextmenu/types'
import { KEYS } from '@/configs/hotkey'
import { getTextStyle, formatText } from './utils'
import useHideCells from './useHideCells'
import useSubThemeColor from './useSubThemeColor'

import CustomTextarea from './CustomTextarea.vue'

export default defineComponent({
  name: 'editable-table',
  emits: ['change', 'changeColWidths', 'changeSelectedCells'],
  components: {
    CustomTextarea,
  },
  props: {
    data: {
      type: Array as PropType<TableCell[][]>,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    colWidths: {
      type: Array as PropType<number[]>,
      required: true,
    },
    outline: {
      type: Object as PropType<PPTElementOutline>,
      required: true,
    },
    theme: {
      type: Object as PropType<TableTheme>,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { canvasScale } = storeToRefs(useMainStore())
    
    const isStartSelect = ref(false)
    const startCell = ref<number[]>([])
    const endCell = ref<number[]>([])

    const tableCells = computed<TableCell[][]>({
      get() {
        return props.data
      },
      set(newData) {
        emit('change', newData)
      },
    })

    // 테마 보조색
    const theme = computed(() => props.theme)
    const { subThemeColor } = useSubThemeColor(theme)

    // 테이블의 각 열 너비와 전체 너비 계산
    const colSizeList = ref<number[]>([])
    const totalWidth = computed(() => colSizeList.value.reduce((a, b) => a + b))
    watch([
      () => props.colWidths,
      () => props.width,
    ], () => {
      colSizeList.value = props.colWidths.map(item => item * props.width)
    }, { immediate: true })
    
    // 모든 셀 선택 지우기
    // 테이블이 편집 불가능한 상태일 때도 지워야 합니다
    const removeSelectedCells = () => {
      startCell.value = []
      endCell.value = []
    }

    watch(() => props.editable, () => {
      if (!props.editable) removeSelectedCells()
    })

    // 열 너비를 끌 때 사용할 작업 노드 위치
    const dragLinePosition = computed(() => {
      const dragLinePosition: number[] = []
      for (let i = 1; i < colSizeList.value.length + 1; i++) {
        const pos = colSizeList.value.slice(0, i).reduce((a, b) => (a + b))
        dragLinePosition.push(pos)
      }
      return dragLinePosition
    })

    // 열 너비를 끌 때 사용할 작업 노드 위치
    const cells = computed(() => props.data)
    const { hideCells } = useHideCells(cells)

    // 현재 선택된 셀 모음집
    const selectedCells = computed(() => {
      if (!startCell.value.length) return []
      const [startX, startY] = startCell.value

      if (!endCell.value.length) return [`${startX}_${startY}`]
      const [endX, endY] = endCell.value

      if (startX === endX && startY === endY) return [`${startX}_${startY}`]

      const selectedCells = []

      const minX = Math.min(startX, endX)
      const minY = Math.min(startY, endY)
      const maxX = Math.max(startX, endX)
      const maxY = Math.max(startY, endY)

      for (let i = 0; i < tableCells.value.length; i++) {
        const rowCells = tableCells.value[i]
        for (let j = 0; j < rowCells.length; j++) {
          if (i >= minX && i <= maxX && j >= minY && j <= maxY) selectedCells.push(`${i}_${j}`)
        }
      }
      return selectedCells
    })

    watch(selectedCells, (value, oldValue) => {
      if (isEqual(value, oldValue)) return
      emit('changeSelectedCells', selectedCells.value)
    })

    // 현재 활성화된 셀: 선택한 셀이 하나만 있을 때 활성화되는 셀입니다.
    const activedCell = computed(() => {
      if (selectedCells.value.length > 1) return null
      return selectedCells.value[0]
    })

    // 현재 선택된 셀의 위치 범위
    const selectedRange = computed(() => {
      if (!startCell.value.length) return null
      const [startX, startY] = startCell.value

      if (!endCell.value.length) return { row: [startX, startX], col: [startY, startY] }
      const [endX, endY] = endCell.value

      if (startX === endX && startY === endY) return { row: [startX, startX], col: [startY, startY] }

      const minX = Math.min(startX, endX)
      const minY = Math.min(startY, endY)
      const maxX = Math.max(startX, endX)
      const maxY = Math.max(startY, endY)

      return {
        row: [minX, maxX],
        col: [minY, maxY],
      }
    })

    // 선택 셀 상태 설정 (마우스 클릭 또는 드래그)
    const handleMouseup = () => isStartSelect.value = false

    const handleCellMousedown = (e: MouseEvent, rowIndex: number, colIndex: number) => {
      if (e.button === 0) {
        endCell.value = []
        isStartSelect.value = true
        startCell.value = [rowIndex, colIndex]
      }
    }

    const handleCellMouseenter = (rowIndex: number, colIndex: number) => {
      if (!isStartSelect.value) return
      endCell.value = [rowIndex, colIndex]
    }

    onMounted(() => {
      document.addEventListener('mouseup', handleMouseup)
    })
    onUnmounted(() => {
      document.removeEventListener('mouseup', handleMouseup)
    })

    // 위치가 무효 셀인지 여부(합병된 위치)
    const isHideCell = (rowIndex: number, colIndex: number) => hideCells.value.includes(`${rowIndex}_${colIndex}`)

    // 지정한 열 선택
    const selectCol = (index: number) => {
      const maxRow = tableCells.value.length - 1
      startCell.value = [0, index]
      endCell.value = [maxRow, index]
    }

    // 지정한 행 선택
    const selectRow = (index: number) => {
      const maxCol = tableCells.value[index].length - 1
      startCell.value = [index, 0]
      endCell.value = [index, maxCol]
    }

    // 모든 셀 선택
    const selectAll = () => {
      const maxRow = tableCells.value.length - 1
      const maxCol = tableCells.value[maxRow].length - 1
      startCell.value = [0, 0]
      endCell.value = [maxRow, maxCol]
    }

    // 행 삭제
    const deleteRow = (rowIndex: number) => {
      const _tableCells: TableCell[][] = JSON.parse(JSON.stringify(tableCells.value))

      const targetCells = tableCells.value[rowIndex]
      const hideCellsPos = []
      for (let i = 0; i < targetCells.length; i++) {
        if (isHideCell(rowIndex, i)) hideCellsPos.push(i)
      }
      
      for (const pos of hideCellsPos) {
        for (let i = rowIndex; i >= 0; i--) {
          if (!isHideCell(i, pos)) {
            _tableCells[i][pos].rowspan = _tableCells[i][pos].rowspan - 1
            break
          }
        }
      }

      _tableCells.splice(rowIndex, 1)
      tableCells.value = _tableCells
    }

    // 열 삭제
    const deleteCol = (colIndex: number) => {
      const _tableCells: TableCell[][] = JSON.parse(JSON.stringify(tableCells.value))

      const hideCellsPos = []
      for (let i = 0; i < tableCells.value.length; i++) {
        if (isHideCell(i, colIndex)) hideCellsPos.push(i)
      }

      for (const pos of hideCellsPos) {
        for (let i = colIndex; i >= 0; i--) {
          if (!isHideCell(pos, i)) {
            _tableCells[pos][i].colspan = _tableCells[pos][i].colspan - 1
            break
          }
        }
      }

      tableCells.value = _tableCells.map(item => {
        item.splice(colIndex, 1)
        return item
      })
      colSizeList.value.splice(colIndex, 1)
      emit('changeColWidths', colSizeList.value)
    }
    
    // 한 줄 삽입
    const insertRow = (rowIndex: number) => {
      const _tableCells: TableCell[][] = JSON.parse(JSON.stringify(tableCells.value))

      const rowCells: TableCell[] = []
      for (let i = 0; i < _tableCells[0].length; i++) {
        rowCells.push({
          colspan: 1,
          rowspan: 1,
          text: '',
          id: nanoid(10),
        })
      }

      _tableCells.splice(rowIndex, 0, rowCells)
      tableCells.value = _tableCells
    }

    // 열 삽입
    const insertCol = (colIndex: number) => {
      tableCells.value = tableCells.value.map(item => {
        const cell = {
          colspan: 1,
          rowspan: 1,
          text: '',
          id: nanoid(10),
        }
        item.splice(colIndex, 0, cell)
        return item
      })
      colSizeList.value.splice(colIndex, 0, 100)
      emit('changeColWidths', colSizeList.value)
    }

    // 지정한 행/ 열 수 채우기
    const fillTable = (rowCount: number, colCount: number) => {
      let _tableCells: TableCell[][] = JSON.parse(JSON.stringify(tableCells.value))
      const defaultCell = { colspan: 1, rowspan: 1, text: '' }
      
      if (rowCount) {
        const newRows = []
        for (let i = 0; i < rowCount; i++) {
          const rowCells: TableCell[] = []
          for (let j = 0; j < _tableCells[0].length; j++) {
            rowCells.push({
              ...defaultCell,
              id: nanoid(10),
            })
          }
          newRows.push(rowCells)
        }
        _tableCells = [..._tableCells, ...newRows]
      }
      if (colCount) {
        _tableCells = _tableCells.map(item => {
          const cells: TableCell[] = []
          for (let i = 0; i < colCount; i++) {
            const cell = {
              ...defaultCell,
              id: nanoid(10),
            }
            cells.push(cell)
          }
          return [...item, ...cells]
        })
        colSizeList.value = [...colSizeList.value, ...new Array(colCount).fill(100)]
        emit('changeColWidths', colSizeList.value)
      }

      tableCells.value = _tableCells
    }
    
    // 셀 병합
    const mergeCells = () => {
      const [startX, startY] = startCell.value
      const [endX, endY] = endCell.value

      const minX = Math.min(startX, endX)
      const minY = Math.min(startY, endY)
      const maxX = Math.max(startX, endX)
      const maxY = Math.max(startY, endY)

      const _tableCells: TableCell[][] = JSON.parse(JSON.stringify(tableCells.value))
      
      _tableCells[minX][minY].rowspan = maxX - minX + 1
      _tableCells[minX][minY].colspan = maxY - minY + 1

      tableCells.value = _tableCells
      removeSelectedCells()
    }

    // 셀 분할
    const splitCells = (rowIndex: number, colIndex: number) => {
      const _tableCells: TableCell[][] = JSON.parse(JSON.stringify(tableCells.value))
      _tableCells[rowIndex][colIndex].rowspan = 1
      _tableCells[rowIndex][colIndex].colspan = 1

      tableCells.value = _tableCells
      removeSelectedCells()
    }

    // 마우스 끌기 열 너비 조정
    const handleMousedownColHandler = (e: MouseEvent, colIndex: number) => {
      removeSelectedCells()
      let isMouseDown = true

      const originWidth = colSizeList.value[colIndex]
      const startPageX = e.pageX

      const minWidth = 50

      document.onmousemove = e => {
        if (!isMouseDown) return
        
        const moveX = (e.pageX - startPageX) / canvasScale.value
        const width = originWidth + moveX < minWidth ? minWidth : Math.round(originWidth + moveX)

        colSizeList.value[colIndex] = width
      }
      document.onmouseup = () => {
        isMouseDown = false
        document.onmousemove = null
        document.onmouseup = null

        emit('changeColWidths', colSizeList.value)
      }
    }

    // 선택한 셀의 텍스트를 비웁니다.
    const clearSelectedCellText = () => {
      const _tableCells: TableCell[][] = JSON.parse(JSON.stringify(tableCells.value))

      for (let i = 0; i < _tableCells.length; i++) {
        for (let j = 0; j < _tableCells[i].length; j++) {
          if (selectedCells.value.includes(`${i}_${j}`)) {
            _tableCells[i][j].text = ''
          }
        }
      }
      tableCells.value = _tableCells
    }

    // 다음 셀로 포커스 이동
    // 현재 줄 오른쪽에 셀이 있습니다때, 초점이 오른쪽으로 이동합니다.
    // 현재 줄 오른쪽에 셀 없음(이미 행의 끝에 있음) 다음 행이 있을 때 포커스가 다음 행으로 이동첫걸음
    // 현재 줄 오른쪽에 셀 없음(이미 행의 끝에 있음) 다음 행이 존재하지 않음 (이미 마지막 행에 있음)) 을( 를) 만들 때, 다음 행의 선두로 포커스를 이동합니다
    const tabActiveCell = () => {
      const getNextCell = (i: number, j: number): [number, number] | null => {
        if (!tableCells.value[i]) return null
        if (!tableCells.value[i][j]) return getNextCell(i + 1, 0)
        if (isHideCell(i, j)) return getNextCell(i, j + 1)
        return [i, j]
      }

      endCell.value = []

      const nextRow = startCell.value[0]
      const nextCol = startCell.value[1] + 1

      const nextCell = getNextCell(nextRow, nextCol)
      if (!nextCell) {
        insertRow(nextRow + 1)
        startCell.value = [nextRow + 1, 0]
      }
      else startCell.value = nextCell

      // 초점 이동 후 자동으로 텍스트 초점 맞추기
      nextTick(() => {
        const textRef = document.querySelector('.cell-text.active') as HTMLInputElement
        if (textRef) textRef.focus()
      })
    }

    // 테이블 바로 가기 키 수신
    const keydownListener = (e: KeyboardEvent) => {
      if (!props.editable || !selectedCells.value.length) return

      const key = e.key.toUpperCase()
      if (selectedCells.value.length < 2) {
        if (key === KEYS.TAB) {
          e.preventDefault()
          tabActiveCell()
        }
        if (e.ctrlKey && key === KEYS.UP) {
          e.preventDefault()
          const rowIndex = +selectedCells.value[0].split('_')[0]
          insertRow(rowIndex)
        }
        if (e.ctrlKey && key === KEYS.DOWN) {
          e.preventDefault()
          const rowIndex = +selectedCells.value[0].split('_')[0]
          insertRow(rowIndex + 1)
        }
        if (e.ctrlKey && key === KEYS.LEFT) {
          e.preventDefault()
          const colIndex = +selectedCells.value[0].split('_')[1]
          insertCol(colIndex)
        }
        if (e.ctrlKey && key === KEYS.RIGHT) {
          e.preventDefault()
          const colIndex = +selectedCells.value[0].split('_')[1]
          insertCol(colIndex + 1)
        }
      }
      else if (key === KEYS.DELETE) {
        clearSelectedCellText()
      }
    }

    onMounted(() => {
      document.addEventListener('keydown', keydownListener)
    })
    onUnmounted(() => {
      document.removeEventListener('keydown', keydownListener)
    })

    // 셀 텍스트 입력 시 테이블 데이터 업데이트
    const handleInput = debounce(function(value, rowIndex, colIndex) {
      tableCells.value[rowIndex][colIndex].text = value
      emit('change', tableCells.value)
    }, 300, { trailing: true })

    // 데이터를 삽입한 표에서 온 Excel 될 때 자동으로 보충해 부족하다/. 열
    const insertExcelData = (data: string[][], rowIndex: number, colIndex: number) => {
      const maxRow = data.length
      const maxCol = data[0].length

      let fillRowCount = 0
      let fillColCount = 0
      if (rowIndex + maxRow > tableCells.value.length) fillRowCount = rowIndex + maxRow - tableCells.value.length
      if (colIndex + maxCol > tableCells.value[0].length) fillColCount = colIndex + maxCol - tableCells.value[0].length
      if (fillRowCount || fillColCount) fillTable(fillRowCount, fillColCount)

      nextTick(() => {
        for (let i = 0; i < maxRow; i++) {
          for (let j = 0; j < maxCol; j++) {
            if (tableCells.value[rowIndex + i][colIndex + j]) {
              tableCells.value[rowIndex + i][colIndex + j].text = data[i][j]
            }
          }
        }
        emit('change', tableCells.value)
      })
    }

    // 올바른 셀 가져오기 (연결된 셀 제외)
    const getEffectiveTableCells = () => {
      const effectiveTableCells = []

      for (let i = 0; i < tableCells.value.length; i++) {
        const rowCells = tableCells.value[i]
        const _rowCells = []
        for (let j = 0; j < rowCells.length; j++) {
          if (!isHideCell(i, j)) _rowCells.push(rowCells[j])
        }
        if (_rowCells.length) effectiveTableCells.push(_rowCells)
      }

      return effectiveTableCells
    }

    // 행과 열을 삭제할 수 있는지 확인합니다. 올바른 행/ 열 수가 1보다 많습니다.
    const checkCanDeleteRowOrCol = () => {
      const effectiveTableCells = getEffectiveTableCells()
      const canDeleteRow = effectiveTableCells.length > 1
      const canDeleteCol = effectiveTableCells[0].length > 1

      return { canDeleteRow, canDeleteCol }
    }

    // 병합하거나 분할할 수 있는지 검사
    // 많이 골라야 잘 어울릴 것 같아요함께
    // 필수 항목과 선택 항목셀이 병합되어 있어야 분할할 수 있습니다
    const checkCanMergeOrSplit = (rowIndex: number, colIndex: number) => {
      const isMultiSelected = selectedCells.value.length > 1
      const targetCell = tableCells.value[rowIndex][colIndex]

      const canMerge = isMultiSelected
      const canSplit = !isMultiSelected && (targetCell.rowspan > 1 || targetCell.colspan > 1)

      return { canMerge, canSplit }
    }

    const contextmenus = (el: HTMLElement): ContextmenuItem[] => {
      const cellIndex = el.dataset.cellIndex as string
      const rowIndex = +cellIndex.split('_')[0]
      const colIndex = +cellIndex.split('_')[1]

      if (!selectedCells.value.includes(`${rowIndex}_${colIndex}`)) {
        startCell.value = [rowIndex, colIndex]
        endCell.value = []
      }

      const { canMerge, canSplit } = checkCanMergeOrSplit(rowIndex, colIndex)
      const { canDeleteRow, canDeleteCol } = checkCanDeleteRowOrCol()

      return [
        {
          text: '열삽입',
          children: [
            { text: '왼쪽으로', handler: () => insertCol(colIndex) },
            { text: '우측으로', handler: () => insertCol(colIndex + 1) },
          ],
        },
        {
          text: '줄삽입',
          children: [
            { text: '위쪽으로', handler: () => insertRow(rowIndex) },
            { text: '아래쬭으로', handler: () => insertRow(rowIndex + 1) },
          ],
        },
        {
          text: '열삭제',
          disable: !canDeleteCol,
          handler: () => deleteCol(colIndex),
        },
        {
          text: '줄삭제',
          disable: !canDeleteRow,
          handler: () => deleteRow(rowIndex),
        },
        { divider: true },
        {
          text: '셀병합',
          disable: !canMerge,
          handler: mergeCells,
        },
        {
          text: '셀병합 취소',
          disable: !canSplit,
          handler: () => splitCells(rowIndex, colIndex),
        },
        { divider: true },
        {
          text: '현재열선택',
          handler: () => selectCol(colIndex),
        },
        {
          text: '현재줄선택',
          handler: () => selectRow(rowIndex),
        },
        {
          text: '모든셀선택',
          handler: selectAll,
        },
      ]
    }

    return {
      getTextStyle,
      dragLinePosition,
      tableCells,
      colSizeList,
      totalWidth,
      hideCells,
      selectedCells,
      activedCell,
      selectedRange,
      handleCellMousedown,
      handleCellMouseenter,
      selectCol,
      selectRow,
      handleMousedownColHandler,
      contextmenus,
      handleInput,
      insertExcelData,
      subThemeColor,
      formatText,
    }
  },
})
