import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b484044"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-thumbnails" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ThumbnailSlide = _resolveComponent("ThumbnailSlide")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["thumbnail-item", { 'active': _ctx.slideIndex === index }]),
        key: slide.id,
        onClick: ($event: any) => (_ctx.changeSlideIndex(index))
      }, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(index + 1), 1),
        _createVNode(_component_ThumbnailSlide, {
          class: "thumbnail",
          slide: slide,
          size: 120,
          visible: index < _ctx.slidesLoadLimit
        }, null, 8, ["slide", "visible"])
      ], 10, _hoisted_2))
    }), 128))
  ]))
}