import { onMounted, onUnmounted, Ref, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useSlidesStore } from '@/store'

export default (wrapRef?: Ref<HTMLElement | undefined>) => {
  const slidesStore = useSlidesStore()
  const { viewportRatio } = storeToRefs(slidesStore)

  const slideWidth = ref(0)
  const slideHeight = ref(0)

  // 슬라이드 내용 크기 계산 및 업데이트 (스케일 조정 화면)
  const setSlideContentSize = () => {
    const slideWrapRef = wrapRef?.value || document.body
    const winWidth = slideWrapRef.clientWidth
    const winHeight = slideWrapRef.clientHeight
    let width, height

    if (winHeight / winWidth === viewportRatio.value) {
      width = winWidth
      height = winHeight
    }
    else if (winHeight / winWidth > viewportRatio.value) {
      width = winWidth
      height = winWidth * viewportRatio.value
    }
    else {
      width = winHeight / viewportRatio.value
      height = winHeight
    }
    slideWidth.value = 390
    slideHeight.value = 570
  }

  onMounted(() => {
    setSlideContentSize()
    window.addEventListener('resize', setSlideContentSize)
  })
  onUnmounted(() => {
    window.removeEventListener('resize', setSlideContentSize)
  })

  return {
    slideWidth,
    slideHeight,
  }
}