import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a0b4837"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "save-dialog" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentDialogComponent), {
        onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setDialogSave('')))
      }, null, 32))
    ])
  ]))
}