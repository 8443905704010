
import { defineComponent, PropType } from 'vue'
import { PPTVideoElement } from '@/types/slides'

export default defineComponent({
  name: 'base-element-video',
  props: {
    elementInfo: {
      type: Object as PropType<PPTVideoElement>,
      required: true,
    },
  },
})
