import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-204d666d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "color-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconPlatte = _resolveComponent("IconPlatte")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, { class: "color-btn" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "content",
          style: _normalizeStyle({ backgroundColor: _ctx.color })
        }, null, 4)
      ]),
      _createVNode(_component_IconPlatte, { class: "color-btn-icon" })
    ]),
    _: 1
  }))
}