import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-362270d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "writing-board",
  ref: "writingBoardRef"
}
const _hoisted_2 = {
  key: 0,
  class: "blackboard"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconWrite = _resolveComponent("IconWrite")!
  const _component_IconHighLight = _resolveComponent("IconHighLight")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.blackboard)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("canvas", {
      class: "canvas",
      ref: "canvasRef",
      style: _normalizeStyle({
        width: _ctx.canvasWidth + 'px',
        height: _ctx.canvasHeight + 'px',
      }),
      onMousedown: _cache[0] || (_cache[0] = $event => _ctx.handleMousedown($event)),
      onMousemove: _cache[1] || (_cache[1] = $event => _ctx.handleMousemove($event)),
      onMouseup: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleMouseup())),
      onTouchstart: _cache[3] || (_cache[3] = $event => _ctx.handleMousedown($event)),
      onTouchmove: _cache[4] || (_cache[4] = $event => _ctx.handleMousemove($event)),
      onTouchend: _cache[5] || (_cache[5] = ($event: any) => {_ctx.handleMouseup(); _ctx.mouseInCanvas = false}),
      onMouseleave: _cache[6] || (_cache[6] = ($event: any) => {_ctx.handleMouseup(); _ctx.mouseInCanvas = false}),
      onMouseenter: _cache[7] || (_cache[7] = ($event: any) => (_ctx.mouseInCanvas = true)),
      onWheel: _cache[8] || (_cache[8] = $event => _ctx.mousewheelListener($event))
    }, null, 36),
    (_ctx.mouseInCanvas)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.model === 'eraser')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "eraser",
                style: _normalizeStyle({
          left: _ctx.mouse.x - _ctx.rubberSize / 2 + 'px',
          top: _ctx.mouse.y - _ctx.rubberSize / 2 + 'px',
          width: _ctx.rubberSize + 'px',
          height: _ctx.rubberSize + 'px',
        })
              }, null, 4))
            : _createCommentVNode("", true),
          (_ctx.model === 'pen')
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "pen",
                style: _normalizeStyle({
          left: _ctx.mouse.x - _ctx.penSize / 2 + 'px',
          top: _ctx.mouse.y - _ctx.penSize * 6 + _ctx.penSize / 2 + 'px',
          color: _ctx.color,
        })
              }, [
                (_ctx.model === 'pen')
                  ? (_openBlock(), _createBlock(_component_IconWrite, {
                      key: 0,
                      class: "icon",
                      size: _ctx.penSize * 6
                    }, null, 8, ["size"]))
                  : _createCommentVNode("", true)
              ], 4))
            : _createCommentVNode("", true),
          (_ctx.model === 'mark')
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "pen",
                style: _normalizeStyle({
          left: _ctx.mouse.x - _ctx.markSize / 2 + 'px',
          top: _ctx.mouse.y + 'px',
          color: _ctx.color,
        })
              }, [
                (_ctx.model === 'mark')
                  ? (_openBlock(), _createBlock(_component_IconHighLight, {
                      key: 0,
                      class: "icon",
                      size: _ctx.markSize * 1.5
                    }, null, 8, ["size"]))
                  : _createCommentVNode("", true)
              ], 4))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 512))
}