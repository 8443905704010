
import { defineComponent } from 'vue'
import { Basic_List, LinePoolItem } from '@/configs/basicFields'



export default defineComponent({
  name: 'line-pool',
  emits: ['select'],
  components: {

  },
  setup(props, { emit }) {
    const lineList = Basic_List

    const selectLine = (line: LinePoolItem) => {
      emit('select', line)
    }

    return {
      lineList,
      selectLine,
    }
  },
})
