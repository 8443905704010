import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49291da6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "writing-board-tool" }
const _hoisted_2 = { class: "colors" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WritingBoard = _resolveComponent("WritingBoard")!
  const _component_IconWrite = _resolveComponent("IconWrite")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_IconHighLight = _resolveComponent("IconHighLight")!
  const _component_IconErase = _resolveComponent("IconErase")!
  const _component_IconClear = _resolveComponent("IconClear")!
  const _component_IconFill = _resolveComponent("IconFill")!
  const _component_IconClose = _resolveComponent("IconClose")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "writing-board-wrap",
      style: _normalizeStyle({
        width: _ctx.slideWidth + 'px',
        height: _ctx.slideHeight + 'px',
      })
    }, [
      _createVNode(_component_WritingBoard, {
        ref: "writingBoardRef",
        color: _ctx.writingBoardColor,
        blackboard: _ctx.blackboard,
        model: _ctx.writingBoardModel
      }, null, 8, ["color", "blackboard", "model"])
    ], 4),
    _createElementVNode("div", {
      class: "tools",
      style: _normalizeStyle(_ctx.position)
    }, [
      _createVNode(_component_Tooltip, {
        mouseLeaveDelay: 0,
        mouseEnterDelay: 0.3,
        title: "펜"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["btn", { 'active': _ctx.writingBoardModel === 'pen' }]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeModel('pen')))
          }, [
            _createVNode(_component_IconWrite, { class: "icon" })
          ], 2)
        ]),
        _: 1
      }, 8, ["mouseEnterDelay"]),
      _createVNode(_component_Tooltip, {
        mouseLeaveDelay: 0,
        mouseEnterDelay: 0.3,
        title: "형광펜"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["btn", { 'active': _ctx.writingBoardModel === 'mark' }]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeModel('mark')))
          }, [
            _createVNode(_component_IconHighLight, { class: "icon" })
          ], 2)
        ]),
        _: 1
      }, 8, ["mouseEnterDelay"]),
      _createVNode(_component_Tooltip, {
        mouseLeaveDelay: 0,
        mouseEnterDelay: 0.3,
        title: "지우개"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["btn", { 'active': _ctx.writingBoardModel === 'eraser' }]),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeModel('eraser')))
          }, [
            _createVNode(_component_IconErase, { class: "icon" })
          ], 2)
        ]),
        _: 1
      }, 8, ["mouseEnterDelay"]),
      _createVNode(_component_Tooltip, {
        mouseLeaveDelay: 0,
        mouseEnterDelay: 0.3,
        title: "먹물지우개"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "btn",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clearCanvas()))
          }, [
            _createVNode(_component_IconClear, { class: "icon" })
          ])
        ]),
        _: 1
      }, 8, ["mouseEnterDelay"]),
      _createVNode(_component_Tooltip, {
        mouseLeaveDelay: 0,
        mouseEnterDelay: 0.3,
        title: "칠판"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["btn", { 'active': _ctx.blackboard }]),
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.blackboard = !_ctx.blackboard))
          }, [
            _createVNode(_component_IconFill, { class: "icon" })
          ], 2)
        ]),
        _: 1
      }, 8, ["mouseEnterDelay"]),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.writingBoardColors, (color) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["color", { 'active': color === _ctx.writingBoardColor }]),
            key: color,
            style: _normalizeStyle({ backgroundColor: color }),
            onClick: ($event: any) => (_ctx.changeColor(color))
          }, null, 14, _hoisted_3))
        }), 128))
      ]),
      _createVNode(_component_Tooltip, {
        mouseLeaveDelay: 0,
        mouseEnterDelay: 0.3,
        title: "브러시 닫기"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "btn",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.closeWritingBoard()))
          }, [
            _createVNode(_component_IconClose, { class: "icon" })
          ])
        ]),
        _: 1
      }, 8, ["mouseEnterDelay"])
    ], 4)
  ]))
}