import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20b746f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["transform"]
const _hoisted_4 = ["d", "fill", "stroke", "stroke-width", "stroke-dasharray"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GradientDefs = _resolveComponent("GradientDefs")!
  const _component_ProsemirrorEditor = _resolveComponent("ProsemirrorEditor")!
  const _directive_contextmenu = _resolveDirective("contextmenu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["editable-element-shape", { 'lock': _ctx.elementInfo.lock }]),
    style: _normalizeStyle({
      top: _ctx.elementInfo.top + 'px',
      left: _ctx.elementInfo.left + 'px',
      width: _ctx.elementInfo.width + 'px',
      height: _ctx.elementInfo.height + 'px',
    })
  }, [
    _createElementVNode("div", {
      class: "rotate-wrapper",
      style: _normalizeStyle({ transform: `rotate(${_ctx.elementInfo.rotate}deg)` })
    }, [
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "element-content",
        style: _normalizeStyle({
          opacity: _ctx.elementInfo.opacity,
          filter: _ctx.shadowStyle ? `drop-shadow(${_ctx.shadowStyle})` : '',
          transform: _ctx.flipStyle,
          color: _ctx.text.defaultColor,
          fontFamily: _ctx.text.defaultFontName,
        }),
        onMousedown: _cache[4] || (_cache[4] = $event => _ctx.handleSelectElement($event)),
        onTouchstart: _cache[5] || (_cache[5] = $event => _ctx.handleSelectElement($event)),
        onDblclick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.editable = true))
      }, [
        (_openBlock(), _createElementBlock("svg", {
          overflow: "visible",
          width: _ctx.elementInfo.width,
          height: _ctx.elementInfo.height
        }, [
          (_ctx.elementInfo.gradient)
            ? (_openBlock(), _createElementBlock("defs", _hoisted_2, [
                _createVNode(_component_GradientDefs, {
                  id: `editabel-gradient-${_ctx.elementInfo.id}`,
                  type: _ctx.elementInfo.gradient.type,
                  color1: _ctx.elementInfo.gradient.color[0],
                  color2: _ctx.elementInfo.gradient.color[1],
                  rotate: _ctx.elementInfo.gradient.rotate
                }, null, 8, ["id", "type", "color1", "color2", "rotate"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("g", {
            transform: `scale(${_ctx.elementInfo.width / _ctx.elementInfo.viewBox[0]}, ${_ctx.elementInfo.height / _ctx.elementInfo.viewBox[1]}) translate(0,0) matrix(1,0,0,1,0,0)`
          }, [
            _createElementVNode("path", {
              class: "shape-path",
              "vector-effect": "non-scaling-stroke",
              "stroke-linecap": "butt",
              "stroke-miterlimit": "8",
              d: _ctx.elementInfo.path,
              fill: _ctx.elementInfo.gradient ? `url(#editabel-gradient-${_ctx.elementInfo.id})` : _ctx.elementInfo.fill,
              stroke: _ctx.outlineColor,
              "stroke-width": _ctx.outlineWidth,
              "stroke-dasharray": _ctx.outlineStyle === 'dashed' ? '10 6' : '0 0'
            }, null, 8, _hoisted_4)
          ], 8, _hoisted_3)
        ], 8, _hoisted_1)),
        _createElementVNode("div", {
          class: _normalizeClass(["shape-text", [_ctx.text.align, { 'editable': _ctx.editable || _ctx.text.content }]])
        }, [
          (_ctx.editable || _ctx.text.content)
            ? (_openBlock(), _createBlock(_component_ProsemirrorEditor, {
                key: 0,
                elementId: _ctx.elementInfo.id,
                defaultColor: _ctx.text.defaultColor,
                defaultFontName: _ctx.text.defaultFontName,
                editable: !_ctx.elementInfo.lock,
                autoFocus: true,
                value: _ctx.text.content,
                onUpdate: _cache[0] || (_cache[0] = value => _ctx.updateText(value)),
                onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.checkEmptyText())),
                onMousedown: _cache[2] || (_cache[2] = $event => _ctx.handleSelectElement($event, false)),
                onTouchstart: _cache[3] || (_cache[3] = $event => _ctx.handleSelectElement($event, false))
              }, null, 8, ["elementId", "defaultColor", "defaultFontName", "editable", "value"]))
            : _createCommentVNode("", true)
        ], 2)
      ], 36)), [
        [_directive_contextmenu, _ctx.contextmenus]
      ])
    ], 4)
  ], 6))
}