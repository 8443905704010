
import { defineComponent, PropType } from 'vue'
import { storeToRefs } from 'pinia'
import { useSnapshotStore } from '@/store'
import { Mode } from '@/types/mobile'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

export default defineComponent({
  name: 'mobile-editor-header',
  props: {
    changeMode: {
      type: Function as PropType<(mode: Mode) => void>,
      required: true,
    },
  },
  setup() {
    const { canUndo, canRedo } = storeToRefs(useSnapshotStore())
    const { redo, undo } = useHistorySnapshot()

    return {
      redo,
      undo,
      canUndo,
      canRedo,
    }
  },
})
