import CryptoJS from 'crypto-js'

const CRYPTO_KEY = 'bitist'

/**
 * 빽빽하게 하다.
 * @param msg 암호화될 문자열
 */
export const encrypt = (msg: string) => {
  return CryptoJS.AES.encrypt(msg, CRYPTO_KEY).toString()
}

/**
 * 암호를 풀다
 * @param ciphertext 암호 해독할 문자열
 */
export const decrypt = (ciphertext: string) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, CRYPTO_KEY)
  return bytes.toString(CryptoJS.enc.Utf8)
}