import { computed, Ref } from 'vue'
import { PPTElementOutline } from '@/types/slides'

// 경계선 관련 속성값 계산, 기본값 처리
export default (outline: Ref<PPTElementOutline | undefined>) => {
  const outlineWidth = computed(() => outline.value?.width ?? 0)
  const outlineStyle = computed(() => outline.value?.style || 'solid')
  const outlineColor = computed(() => outline.value?.color || '#d14424')

  return {
    outlineWidth,
    outlineStyle,
    outlineColor,
  }
}