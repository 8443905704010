import { defineStore } from 'pinia'
import { IndexableTypeArray } from 'dexie'
import { db, deleteDiscardedDB, Snapshot } from '@/utils/database'

import { useSlidesStore } from './slides'
import { useMainStore } from './main'

export interface ScreenState {
  snapshotCursor: number
  snapshotLength: number
}

export const useSnapshotStore = defineStore('snapshot', {
  state: (): ScreenState => ({
    snapshotCursor: -1, // 과거 스냅샷 포인터
    snapshotLength: 0, // 히스토리 스냅샷 길이
  }),

  getters: {
    canUndo(state) {
      return state.snapshotCursor > 0
    },
    canRedo(state) {
      return state.snapshotCursor < state.snapshotLength - 1
    },
  },

  actions: {
    setSnapshotCursor(cursor: number) {
      this.snapshotCursor = cursor
    },
    setSnapshotLength(length: number) {
      this.snapshotLength = length
    },

    async initSnapshotDatabase() {
      const slidesStore = useSlidesStore()

      await deleteDiscardedDB()
  
      const newFirstSnapshot = {
        index: slidesStore.slideIndex,
        slides: slidesStore.slides,
      }
      await db.snapshots.add(newFirstSnapshot)
      this.setSnapshotCursor(0)
      this.setSnapshotLength(1)
    },
  
    async addSnapshot() {
      const slidesStore = useSlidesStore()

      // 현재 indexeddb에 있는 모든 스냅샷의 ID 가져오기
      const allKeys = await db.snapshots.orderBy('id').keys()
  
      let needDeleteKeys: IndexableTypeArray = []
  
      // 삭제할 스냅샷 ID 기록
      // 현재 스냅샷 포인터가 마지막 위치에 있지 않으면 스냅샷을 추가할 때 현재 포인터 위치 뒤의 스냅샷을 모두 삭제합니다. 실제 상황은 다음과 같습니다.
      // 사용자가 여러 번 실행 취소한 후 다시 작업을 수행합니다(스냅샷 추가). 이때 원래 실행 취소된 스냅샷은 모두 삭제되어야 합니다.
      if (this.snapshotCursor >= 0 && this.snapshotCursor < allKeys.length - 1) {
        needDeleteKeys = allKeys.slice(this.snapshotCursor + 1)
      }
  
      // 새 스냅샷 추가
      const snapshot = {
        index: slidesStore.slideIndex,
        slides: slidesStore.slides,
      }
      await db.snapshots.add(snapshot)
  
      // 현재 스냅숏의 길이를 계산합니다. (이때 포인터는 마지막이 되어야 합니다. - 1)
      let snapshotLength = allKeys.length - needDeleteKeys.length + 1
  
      // 스냅샷 수가 길이 제한을 초과할 경우 헤더의 여분의 스냅샷은 삭제해야 합니다
      const snapshotLengthLimit = 20
      if (snapshotLength > snapshotLengthLimit) {
        needDeleteKeys.push(allKeys[0])
        snapshotLength--
      }
  
      // 스냅샷 수가 1보다 클 경우, 실행 취소 후에도 페이지의 초점을 일정하게 유지해야 합니다. 즉, 마지막 두 번째 스냅샷에 해당하는 인덱스를 현재 페이지의 인덱스로 설정합니다.
      if (snapshotLength >= 2) {
        db.snapshots.update(allKeys[snapshotLength - 2] as number, { index: slidesStore.slideIndex })
      }
  
      await db.snapshots.bulkDelete(needDeleteKeys)
  
      this.setSnapshotCursor(snapshotLength - 1)
      this.setSnapshotLength(snapshotLength)
    },
  
    async unDo() {
      if (this.snapshotCursor <= 0) return

      const slidesStore = useSlidesStore()
      const mainStore = useMainStore()
  
      const snapshotCursor = this.snapshotCursor - 1
      const snapshots: Snapshot[] = await db.snapshots.orderBy('id').toArray()
      const snapshot = snapshots[snapshotCursor]
      const { index, slides } = snapshot
  
      const slideIndex = index > slides.length - 1 ? slides.length - 1 : index
  
      slidesStore.setSlides(slides)
      slidesStore.updateSlideIndex(slideIndex)
      this.setSnapshotCursor(snapshotCursor)
      mainStore.setActiveElementIdList([])
    },
  
    async reDo() {
      if (this.snapshotCursor >= this.snapshotLength - 1) return

      const slidesStore = useSlidesStore()
      const mainStore = useMainStore()
  
      const snapshotCursor = this.snapshotCursor + 1
      const snapshots: Snapshot[] = await db.snapshots.orderBy('id').toArray()
      const snapshot = snapshots[snapshotCursor]
      const { index, slides } = snapshot
  
      const slideIndex = index > slides.length - 1 ? slides.length - 1 : index
  
      slidesStore.setSlides(slides)
      slidesStore.updateSlideIndex(slideIndex)
      this.setSnapshotCursor(snapshotCursor)
      mainStore.setActiveElementIdList([])
    },
  },
})