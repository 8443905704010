export const enum KEYS {
  C = 'C',
  X = 'X',
  Z = 'Z',
  Y = 'Y',
  A = 'A',
  G = 'G',
  L = 'L',
  F = 'F',
  D = 'D',
  B = 'B',
  P = 'P',
  MINUS = '-',
  EQUAL = '=',
  DIGIT_0 = '0',
  DELETE = 'DELETE',
  UP = 'ARROWUP',
  DOWN = 'ARROWDOWN',
  LEFT = 'ARROWLEFT',
  RIGHT = 'ARROWRIGHT',
  ENTER = 'ENTER',
  SPACE = ' ',
  TAB = 'TAB',
  BACKSPACE = 'BACKSPACE',
  ESC = 'ESCAPE',
  PAGEUP = 'PAGEUP',
  PAGEDOWN = 'PAGEDOWN',
  F5 = 'F5',
}

export const HOTKEY_DOC = [
  {
    type: '범용',
    children: [
      { label: '잘라내기', value: 'Ctrl + X' },
      { label: '복사', value: 'Ctrl + C' },
      { label: '붙여넣기', value: 'Ctrl + V' },
      { label: '빠른 복사 붙여넣기', value: 'Ctrl + D' },
      { label: '모두 선택', value: 'Ctrl + A' },
      { label: '취소', value: 'Ctrl + Z' },
      { label: '복구', value: 'Ctrl + Y' },
      { label: '삭제', value: 'Delete / Backspace' },
      { label: '복수선택', value: 'Ctrl이나 Shift를 누르면서' },
      { label: '인쇄', value: 'Ctrl + P' },
      { label: '팝업 닫기', value: 'ESC' },
    ],
  },
  {
    type: '슬라이드쇼',
    children: [
      { label: '슬라이드 처음부터 다시 시작', value: 'F5' },
      { label: '지금부터 슬라이드 쇼', value: 'Shift + F5' },
      { label: '이전 페이지 전환', value: '↑ / ← / PgUp' },
      { label: '다음 페이지 전환', value: '↓ / → / PgDown' },
      { label: '다음 페이지 전환', value: 'Enter / Space' },
      { label: '종료', value: 'ESC' },
      { label: '브러쉬 터치 크기 조정', value: '마우스롤러' },
    ],
  },
  {
    type: '슬라이드 편집',
    children: [
      { label: '새슬라이드', value: 'Enter' },
      { label: '움직이는 캔버스', value: 'Space + 마우스드래그' },
      { label: '스케일 캔버스', value: 'Ctrl + 마우스휠' },
      { label: '캔버스 확대', value: 'Ctrl + =' },
      { label: '캔버스 축소', value: 'Ctrl + -' },
      { label: '앞화면에 캔버스를 맞춥니다', value: 'Ctrl + 0' },
      { label: '이전 페이지 (선택되지 않은 요소)', value: '↑ / ←' },
      { label: '다음 페이지 (선택되지 않은 요소)', value: '↓ / →' },
      { label: '이전 페이지', value: '마우스업 / PgUp' },
      { label: '다음 페이지', value: '마우스다운 / PgDown' },
    ],
  },
  {
    type: '원소조작',
    children: [
      { label: '이동', value: '↑ / ← / ↓ / →' },
      { label: '잠금', value: 'Ctrl + L' },
      { label: '그룹', value: 'Ctrl + G' },
      { label: '팀취소', value: 'Ctrl + Shift + G' },
      { label: '톱레벨', value: 'Alt + F' },
      { label: '밑을깔다', value: 'Alt + B' },
      { label: '높이 및 가로 비율 고정', value: 'Ctrl이나 Shift 키를 누르면서' },
      { label: '수평선/ 수직선 만들기', value: 'Ctrl 키를 누르거나 Shift' },
      { label: '초점 요소 전환', value: 'Tab' },
      { label: '이미지컷 확인', value: 'Enter' },
    ],
  },
  {
    type: '표 편집',
    children: [
      { label: '다음 셀에 초점 맞추기', value: 'Tab' },
      { label: '위쪽에 줄을 삽입합니다', value: 'Ctrl + ↑' },
      { label: '아래에 한 줄 삽입', value: 'Ctrl + ↓' },
      { label: '왼쪽 열 삽입', value: 'Ctrl + ←' },
      { label: '오른쪽에 열을 삽입합니다', value: 'Ctrl + →' },
    ],
  },
  {
    type: '그래프 데이터 편집',
    children: [
      { label: '다음 줄에 초점 맞추기', value: 'Enter' },
    ],
  },
  {
    type: '텍스트 편집',
    children: [
      { label: '굵게', value: 'Ctrl + B' },
      { label: '이탤릭체', value: 'Ctrl + I' },
      { label: '밑줄', value: 'Ctrl + U' },
      { label: '선 삭제', value: 'Ctrl + D' },
    ],
  },
]