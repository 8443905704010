import { storeToRefs } from 'pinia'
import { useMainStore, useSlidesStore } from '@/store'
import { PPTElement } from '@/types/slides'
import { KEYS } from '@/configs/hotkey'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

export default () => {
  const slidesStore = useSlidesStore()
  const { activeElementIdList, activeGroupElementId } = storeToRefs(useMainStore())
  const { currentSlide } = storeToRefs(slidesStore)

  const { addHistorySnapshot } = useHistorySnapshot()

  /**
   * 지정한 거리만큼 요소를 이동합니다
   * 컴포지트 요소 멤버 중 독립적으로 작동하도록 선택된 요소가 있을 때 해당 요소를 이동합니다.그렇지 않으면 선택한 모든 요소를 기본값으로 이동
   * @param command 이동방향
   * @param step 이동거리
   */
  const moveElement = (command: string, step = 1) => {
    let newElementList: PPTElement[] = []

    const move = (el: PPTElement) => {
      let { left, top } = el
      switch (command) {
        case KEYS.LEFT: 
          left = left - step
          break
        case KEYS.RIGHT: 
          left = left + step
          break
        case KEYS.UP: 
          top = top - step
          break
        case KEYS.DOWN: 
          top = top + step
          break
        default: break
      }
      return { ...el, left, top }
    }

    if (activeGroupElementId.value) {
      newElementList = currentSlide.value.elements.map(el => {
        return activeGroupElementId.value === el.id ? move(el) : el
      })
    }
    else {
      newElementList = currentSlide.value.elements.map(el => {
        return activeElementIdList.value.includes(el.id) ? move(el) : el
      })
    }

    slidesStore.updateSlide({ elements: newElementList })
    addHistorySnapshot()
  }

  return {
    moveElement,
  }
}