import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BorderLine = _resolveComponent("BorderLine")!
  const _component_ResizeHandler = _resolveComponent("ResizeHandler")!

  return (_openBlock(), _createElementBlock("div", {
    class: "mobile-operate",
    style: _normalizeStyle({
      top: _ctx.elementInfo.top * _ctx.canvasScale + 'px',
      left: _ctx.elementInfo.left * _ctx.canvasScale + 'px',
      transform: `rotate(${_ctx.rotate}deg)`,
      transformOrigin: `${_ctx.elementInfo.width * _ctx.canvasScale / 2}px ${_ctx.elementInfo.height * _ctx.canvasScale / 2}px`,
    })
  }, [
    (_ctx.isSelected)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.borderLines, (line) => {
            return (_openBlock(), _createBlock(_component_BorderLine, {
              class: "operate-border-line",
              key: line.type,
              type: line.type,
              style: _normalizeStyle(line.style)
            }, null, 8, ["type", "style"]))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resizeHandlers, (point) => {
            return (_openBlock(), _createBlock(_component_ResizeHandler, {
              class: "operate-resize-handler",
              key: point.direction,
              type: point.direction,
              rotate: _ctx.elementInfo.rotate,
              style: _normalizeStyle(point.style),
              onTouchstart: _withModifiers($event => _ctx.scaleElement($event, _ctx.elementInfo, point.direction), ["stop"])
            }, null, 8, ["type", "rotate", "style", "onTouchstart"]))
          }), 128))
        ], 64))
      : _createCommentVNode("", true)
  ], 4))
}