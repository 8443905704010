
import {defineComponent, ref, watch} from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore, useSlidesStore } from '@/store'
import { PPTVideoElement } from '@/types/slides'
import { getImageDataURL } from '@/utils/image'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

export default defineComponent({
  name: 'video-style-panel',
  setup() {
    const slidesStore = useSlidesStore()
    const { currentSlide } = storeToRefs(slidesStore)
    const { handleElement,handleElementId } = storeToRefs(useMainStore())

    const { addHistorySnapshot } = useHistorySnapshot()
    const video_size = ref<number>()
    const video_size_t = ref<string>('')
    const updateVideo = (props: Partial<PPTVideoElement>) => {


      if (!handleElement.value) return

      slidesStore.updateElement({ id: handleElement.value.id, props })

      addHistorySnapshot()
    }

    // 비디오 미리보기 표지 설정
    const setVideoPoster = (files: File[]) => {
      const imageFile = files[0]
      if (!imageFile) return
      getImageDataURL(imageFile).then(dataURL => updateVideo({ poster: dataURL }))
    }

    const setVideoUrl = (c_name: string) => {
      console.log('c_name' + c_name)
      //alert(c_name)
      const imageFile = c_name
      if (!imageFile) return
      if (!handleElement.value) return
      alert(888)
      //alert(c_name)
      const props = { src: c_name}
      slidesStore.updateElement({ id: handleElement.value.id, props })


    }

    const setVideoSize = (size: number) => {

      if(size) {
        video_size_t.value = (size / 1000000).toString() + 'MB'
      }else{
        video_size_t.value = "- MB"
      }
    }


    // eslint-disable-next-line no-empty
    if(handleElement && handleElement.value  && (currentSlide.value.content_id) === 'content_0002') {
      console.log("size2:"+handleElement.value['size'])
      if(handleElement.value['size']) {
        video_size_t.value = (handleElement.value['size'] /1000000).toString() +"MB"
        console.log("size3:"+video_size_t.value)
      }else{
        video_size_t.value = "- MB"
        console.log("size4:"+video_size_t.value)
      }
    }

    watch(handleElementId, () => {
      if(handleElement && handleElement.value  ) {

       // if (handleElement.value.hasOwnProperty('size') && handleElement.value['size'] > 0) {

        video_size_t.value = "- MB"
       // }
      }
    })

    return {
      handleElement,
      updateVideo,
      setVideoPoster,
      setVideoUrl,
      video_size,
      video_size_t,
      setVideoSize
    }
  }
})
