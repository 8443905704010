import { pasteCustomClipboardString } from '@/utils/clipboard'
import { parseText2Paragraphs } from '@/utils/textParser'
import useCreateElement from '@/hooks/useCreateElement'
import useAddSlidesOrElements from '@/hooks/useAddSlidesOrElements'

interface PasteTextClipboardDataOptions {
  onlySlide?: boolean
  onlyElements?: boolean
}

export default () => {
  const { createTextElement } = useCreateElement()
  const { addElementsFromData, addSlidesFromData } = useAddSlidesOrElements()

  /**
   * 일반 텍스트 붙여넣기: 새 텍스트 요소로 만들기
   * @param text 文本
   */
  const createTextElementFromClipboard = (text: string) => {
    createTextElement({
      left: 0,
      top: 0,
      width: 600,
      height: 50,
    }, text)
  }

  /**
   * 클립보드 내용을 해석하고 해석 결과에 따라 적절한 붙여넣기 방식을 선택합니다.
   * @param text 클립보드 내용
   * @param options 설정 항목: onlySlide - 페이지 붙여넣기만 처리, onlyElements - 요소 붙여넣기만 처리；
   */
  const pasteTextClipboardData = (text: string, options?: PasteTextClipboardDataOptions) => {
    const onlySlide = options?.onlySlide || false
    const onlyElements = options?.onlyElements || false

    const clipboardData = pasteCustomClipboardString(text)

    // 요소나 페이지
    if (typeof clipboardData === 'object') {
      const { type, data } = clipboardData

      if (type === 'elements' && !onlySlide) addElementsFromData(data)
      else if (type === 'slides' && !onlyElements) addSlidesFromData(data)
    }

    // 일반 텍스트
    else if (!onlyElements && !onlySlide) {
      const string = parseText2Paragraphs(clipboardData)
      createTextElementFromClipboard(string)
    }
  }

  return {
    pasteTextClipboardData,
  }
}