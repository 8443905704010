
import { defineComponent, ref } from 'vue'
import { storeToRefs } from 'pinia'
import {useMainStore, useSlidesStore} from '@/store'
import useScreening from '@/hooks/useScreening'
import useSlideHandler from '@/hooks/useSlideHandler'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'
import useExport from '@/hooks/useExport'
import axios from 'axios'
import {MainTheme, Slide} from '@/types/slides'
import ScreenPreviewDialog from '@/views/Editor/ScreenPreviewDialog/index.vue'


export default defineComponent({
  name: 'editor-header',
  components: {ScreenPreviewDialog},
  setup() {
    const mainStore = useMainStore()
    const { showGridLines, showRuler, dialogScreenPreview } = storeToRefs(mainStore)

    const { enterScreening, enterScreeningFromStart } = useScreening()
    const { createSlide, deleteSlide, resetSlides } = useSlideHandler()
    const { redo, undo } = useHistorySnapshot()
    const { importSpecificFile } = useExport()

    const setDialogForExport = mainStore.setDialogForExport
    const setDialogSave = mainStore.setDialogSave

    const slidesStore = useSlidesStore()
    const { mainTheme } = storeToRefs(slidesStore)
    const setDialogForScreenPreview = mainStore.setDialogForScreenPreview
    
    const sub_code = ref<string>('')
    const sub_name = ref<string>('')
    const class_no = ref<string>('')
    const class_subject = ref<string>('')
    const class_content = ref<string>('')

    const closeScreenPreviewDialog = () => {

      mainStore.setDialogForScreenPreview('')
    }

    const updateMainTheme = (themeProps: Partial<MainTheme>) => {

      slidesStore.setMainTheme(themeProps)
    }

    function getData() {


      const html_json = null

      const url = window.location.href.split('?')
      const params = url[1].split('&')
      let seq_ty = ''
      let seq_tyc = ''

      
      for (let i = 0; i < params.length; i++) {
        const temp = params[i].split('=')
        if (temp.length === 2 && temp[0] === 'seq_ty') {
          seq_ty = temp[1]
        }
        else if (temp.length === 2 && temp[0] === 'seq_tyc') {
          seq_tyc = temp[1]
        }
        else if (temp.length === 2 && temp[0] === 'sub_code') {
          sub_code.value = temp[1]
        }
        else if (temp.length === 2 && temp[0] === 'sub_name') {
          sub_name.value = temp[1]
        }
        else if (temp.length === 2 && temp[0] === 'class_no') {
          class_no.value = temp[1]
        }
        else if (temp.length === 2 && temp[0] === 'class_subject') {
          class_subject.value = decodeURI(decodeURIComponent(temp[1]))
        }
        else if (temp.length === 2 && temp[0] === 'class_content') {
          class_content.value = decodeURI(decodeURIComponent(temp[1]))
        }
      }




      const data = {
        seq_ty: seq_ty,
        seq_tyc: seq_tyc,
      }

      axios.post('/rest/selectLS40300', data
      ).then(response => {



        if (response && response.data && response.data.result && response.data.result.html_1) {
          const slides = JSON.parse(response.data.result.html_1)
          slidesStore.setSlides(slides)

          if (slides && slides.length > 0 && slides[0].main_theme) {
            const headerColor1 = slides[0].main_theme.headerColor1
            const headerColor2 = slides[0].main_theme.headerColor2
            const bodyColor1 = slides[0].main_theme.bodyColor1
            const bodyColor2 = slides[0].main_theme.bodyColor2
            updateMainTheme({headerColor1: headerColor1})
            updateMainTheme({headerColor2: headerColor2})
            updateMainTheme({bodyColor1: bodyColor1})
            updateMainTheme({bodyColor2: bodyColor2})
          }
          else {
            updateMainTheme({headerColor1: '#0abac8'})
            updateMainTheme({headerColor2: '#3D9CC2'})
            updateMainTheme({bodyColor1: '#F86E68'})
            updateMainTheme({bodyColor2: '#FF3749'})

          }

        }
        else {
          const initArrr:Slide[] = JSON.parse('[{"id":"test-slide-1","elements":[{"type":"shape","id":"ookHrf","left":0,"top":0,"width":300,"height":320,"viewBox":[200,200],"path":"M 0 0 L 0 200 L 200 200 Z","fill":"#5b9bd5","fixedRatio":false,"flipV":true,"rotate":0},{"type":"text","id":"idn7Mx","left":161,"top":97.25,"width":585,"height":154.390625,"lineHeight":1.2,"content":"<p><strong><span style=\'font-size:  112px\'>MEME</span></strong></p>","rotate":0,"defaultFontName":"Microsoft Yahei","defaultColor":"#333"},{"type":"text","id":"7stmVP","left":161,"top":285.25,"width":585,"height":56,"content":"<p><span style=\'font-size:  24px\'>온라인 퀴즈만들기 응용프로그램</span></p>","rotate":0,"defaultFontName":"Microsoft Yahei","defaultColor":"#333"},{"type":"line","id":"FnpZs4","left":167,"top":270,"start":[0,0],"end":[549,0],"points":["",""],"color":"#5b9bd5","style":"solid","width":2}],"background":{"type":"solid","color":"#ffffff"}}]')
          slidesStore.setSlides(initArrr)
        }

      }).catch(error => {
        console.log(error)

      })



    }


    deleteSlide()
    getData()

    const toggleGridLines = () => {
      mainStore.setGridLinesState(!showGridLines.value)
    }

    const toggleRuler = () => {
      mainStore.setRulerState(!showRuler.value)
    }

    const hotkeyDrawerVisible = ref(false)

    const goIssues = () => {
      window.open('http://bitcodaq.com')
    }

    const {
      createSlideByTemplate,
    } = useSlideHandler()

    const presetLayoutPopoverVisible = ref(false)

    return {
      redo,
      undo,
      showGridLines,
      showRuler,
      hotkeyDrawerVisible,
      importSpecificFile,
      setDialogForExport,
      setDialogSave,
      enterScreening,
      enterScreeningFromStart,
      setDialogForScreenPreview,
      createSlide,
      deleteSlide,
      toggleGridLines,
      toggleRuler,
      resetSlides,
      goIssues,
      dialogScreenPreview,
      closeScreenPreviewDialog,
      sub_code,
      sub_name,
      class_no,
      class_subject,
      class_content,
    }
  },
})
