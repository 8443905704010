
import { defineComponent, onUnmounted, ref, watch } from 'vue'
import { pasteCustomClipboardString, pasteExcelClipboardString } from '@/utils/clipboard'

export default defineComponent({
  name: 'custom-textarea',
  emits: ['updateValue', 'insertExcelData'],
  props: {
    value: {
      type: String,
      default: '',
    },
    contenteditable: {
      type: [Boolean, String],
      default: false,
    },
  },
  setup(props, { emit }) {
    const textareaRef = ref<HTMLElement>()
    const text = ref('')
    const isFocus = ref(false)

    // 사용자 정의 v-modal, 데이터 동기화
    // 텍스트 상자가 초점을 맞출 때 데이터 동기화 안 함
    watch(() => props.value, () => {
      if (isFocus.value) return
      text.value = props.value
      if (textareaRef.value) textareaRef.value.innerHTML = props.value
    }, { immediate: true })

    const handleInput = () => {
      if (!textareaRef.value) return
      const text = textareaRef.value.innerHTML
      emit('updateValue', text)
    }

    // 초점을 맞출 때 초점 태그 업데이트 및 붙여넣기 이벤트 듣기
    const handleFocus = () => {
      isFocus.value = true

      if (!textareaRef.value) return
      textareaRef.value.onpaste = (e: ClipboardEvent) => {
        e.preventDefault()
        if (!e.clipboardData) return

        const clipboardDataFirstItem = e.clipboardData.items[0]

        if (clipboardDataFirstItem && clipboardDataFirstItem.kind === 'string' && clipboardDataFirstItem.type === 'text/plain') {
          clipboardDataFirstItem.getAsString(text => {
            const clipboardData = pasteCustomClipboardString(text)
            if (typeof clipboardData === 'object') return
 
            const excelData = pasteExcelClipboardString(text)
            if (excelData) {
              emit('insertExcelData', excelData)
              if (textareaRef.value) textareaRef.value.innerHTML = excelData[0][0]
              return
            }

            emit('updateValue', text)
            document.execCommand('insertText', false, text)
          })
        }
      }
    }

    // 초점이 맞지 않을 때 초점 마커 업데이트, 붙여넣기 이벤트 수신 지우기
    const handleBlur = () => {
      isFocus.value = false
      if (textareaRef.value) textareaRef.value.onpaste = null
    }

    // 붙여넣기 이벤트 듣기 지우기
    onUnmounted(() => {
      if (textareaRef.value) textareaRef.value.onpaste = null
    })

    return {
      textareaRef,
      handleFocus,
      handleInput,
      handleBlur,
      text,
    }
  },
})
