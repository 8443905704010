
import { computed, defineComponent, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore, useSlidesStore, useKeyboardStore } from '@/store'
import { fillDigit } from '@/utils/common'
import { ContextmenuItem } from '@/components/Contextmenu/types'
import useSlideHandler from '@/hooks/useSlideHandler'
import useScreening from '@/hooks/useScreening'
import useLoadSlides from '@/hooks/useLoadSlides'

import Draggable from 'vuedraggable'
import ThumbnailSlide from '@/views/components/ThumbnailSlide/index.vue'
import LayoutPool from './LayoutPool.vue'

export default defineComponent({
  name: 'thumbnails',
  components: {
    Draggable,
    ThumbnailSlide,
    LayoutPool,
  },
  setup() {
    const mainStore = useMainStore()
    const slidesStore = useSlidesStore()
    const keyboardStore = useKeyboardStore()
    const { selectedSlidesIndex: _selectedSlidesIndex, thumbnailsFocus } = storeToRefs(mainStore)
    const { slides, slideIndex } = storeToRefs(slidesStore)
    const { ctrlKeyState, shiftKeyState } = storeToRefs(keyboardStore)

    const { slidesLoadLimit } = useLoadSlides()

    const selectedSlidesIndex = computed(() => [..._selectedSlidesIndex.value, slideIndex.value])

    const presetLayoutPopoverVisible = ref(false)

    const {
      copySlide,
      pasteSlide,
      createSlide,
      createSlideByTemplate,
      copyAndPasteSlide,
      deleteSlide,
      cutSlide,
      selectAllSlide,
    } = useSlideHandler()

    // 페이지 전환
    const changSlideIndex = (index: number) => {
      mainStore.setActiveElementIdList([])

      if (slideIndex.value === index) return
      slidesStore.updateSlideIndex(index)
    }

    // 미리보기 그림클릭
    const handleClickSlideThumbnail = (e: MouseEvent, index: number) => {
      const isMultiSelected = selectedSlidesIndex.value.length > 1

      if (isMultiSelected && selectedSlidesIndex.value.includes(index) && e.button !== 0) return

      // Ctrl 키를 누르면서 슬라이드를 누르고 선택한 페이지를 다시 누르면 선택 취소
      if (ctrlKeyState.value) {
        if (slideIndex.value === index) {
          if (!isMultiSelected) return

          const newSelectedSlidesIndex = selectedSlidesIndex.value.filter(item => item !== index)
          mainStore.updateSelectedSlidesIndex(newSelectedSlidesIndex)
          changSlideIndex(selectedSlidesIndex.value[0])
        }
        else {
          if (selectedSlidesIndex.value.includes(index)) {
            const newSelectedSlidesIndex = selectedSlidesIndex.value.filter(item => item !== index)
            mainStore.updateSelectedSlidesIndex(newSelectedSlidesIndex)
          }
          else {
            const newSelectedSlidesIndex = [...selectedSlidesIndex.value, index]
            mainStore.updateSelectedSlidesIndex(newSelectedSlidesIndex)
            changSlideIndex(index)
          }
        }
      }
      // Shift 키를 누르면서 모든 슬라이드를 선택합니다.
      else if (shiftKeyState.value) {
        if (slideIndex.value === index && !isMultiSelected) return

        let minIndex = Math.min(...selectedSlidesIndex.value)
        let maxIndex = index

        if (index < minIndex) {
          maxIndex = Math.max(...selectedSlidesIndex.value)
          minIndex = index
        }

        const newSelectedSlidesIndex = []
        for (let i = minIndex; i <= maxIndex; i++) newSelectedSlidesIndex.push(i)
        mainStore.updateSelectedSlidesIndex(newSelectedSlidesIndex)
        changSlideIndex(index)
      }
      // 정상적인 페이지 전환
      else {
        mainStore.updateSelectedSlidesIndex([])
        changSlideIndex(index)
      }
    }

    // 미리 보기 도구 모음의 초점 상태 설정 (초점 상태에서만 바로 가기 키가 활성화됨)
    const setThumbnailsFocus = (focus: boolean) => {
      if (thumbnailsFocus.value === focus) return
      mainStore.setThumbnailsFocus(focus)

      if (!focus) mainStore.updateSelectedSlidesIndex([])
    }

    // 드래그하여 순서를 조정한 후 데이터를 동기화하다
    const handleDragEnd = (eventData: { newIndex: number; oldIndex: number }) => {
      const { newIndex, oldIndex } = eventData
      if (oldIndex === newIndex) return

      const _slides = JSON.parse(JSON.stringify(slides.value))
      const _slide = _slides[oldIndex]
      _slides.splice(oldIndex, 1)
      _slides.splice(newIndex, 0, _slide)
      slidesStore.setSlides(_slides)
      slidesStore.updateSlideIndex(newIndex)
    }

    const { enterScreening, enterScreeningFromStart } = useScreening()

    const contextmenusThumbnails = (): ContextmenuItem[] => {
      return [
        {
          text: '붙여넣기',
          subText: 'Ctrl + V',
          handler: pasteSlide,
        },
        {
          text: '전체선택',
          subText: 'Ctrl + A',
          handler: selectAllSlide,
        },
        {
          text: '새파일',
          subText: 'Enter',
          handler: createSlide,
        },
        {
          text: '슬라이드쇼',
          subText: 'F5',
          handler: enterScreeningFromStart,
        },
      ]
    }

    const contextmenusThumbnailItem = (): ContextmenuItem[] => {
      return [
        {
          text: '잘라내기',
          subText: 'Ctrl + X',
          handler: cutSlide,
        },
        {
          text: '복사',
          subText: 'Ctrl + C',
          handler: copySlide,
        },
        {
          text: '붙여넣기',
          subText: 'Ctrl + V',
          handler: pasteSlide,
        },
        {
          text: '전체선택',
          subText: 'Ctrl + A',
          handler: selectAllSlide,
        },
        { divider: true },
        {
          text: '새파일',
          subText: 'Enter',
          handler: createSlide,
        },
        {
          text: '페이지복사',
          subText: 'Ctrl + D',
          handler: copyAndPasteSlide,
        },
        {
          text: '페이지삭제',
          subText: 'Delete',
          handler: () => deleteSlide(),
        },
        { divider: true },
        {
          text: '현재재생',
          subText: 'Shift + F5',
          handler: enterScreening,
        },
      ]
    }

    return {
      slides,
      slideIndex,
      selectedSlidesIndex,
      presetLayoutPopoverVisible,
      slidesLoadLimit,
      createSlide,
      createSlideByTemplate,
      setThumbnailsFocus,
      handleClickSlideThumbnail,
      contextmenusThumbnails,
      contextmenusThumbnailItem,
      fillDigit,
      handleDragEnd,
    }
  },
})
