import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c009b6ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "custom-textarea",
    ref: "textareaRef",
    onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleFocus && _ctx.handleFocus(...args))),
    onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
    onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleInput())),
    innerHTML: _ctx.text
  }, null, 40, _hoisted_1))
}