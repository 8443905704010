
import { defineComponent, onMounted, onUnmounted, ref } from 'vue'
import { KEYS } from '@/configs/hotkey'
import useScreening from '@/hooks/useScreening'

import BaseView from './BaseView.vue'
import PresenterView from './PresenterView.vue'

export default defineComponent({
  name: 'bitist-screen',
  components: {
    BaseView,
    PresenterView,
  },
  setup() {
    const viewMode = ref<'base' | 'presenter'>('base')

    const changeViewMode = (mode: 'base' | 'presenter') => {
      viewMode.value = mode
    }

    const { exitScreening } = useScreening()

    // 단축키 종료
    const keydownListener = (e: KeyboardEvent) => {
      const key = e.key.toUpperCase()
      if (key === KEYS.ESC) exitScreening()
    }

    onMounted(() => document.addEventListener('keydown', keydownListener))
    onUnmounted(() => document.removeEventListener('keydown', keydownListener))

    return {
      viewMode,
      changeViewMode,
    }
  },
})
