import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49540c1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["accept"]
const _hoisted_2 = { class: "vld-parent" }
const _hoisted_3 = { class: "progressBar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "file-input",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleClick()))
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      _createElementVNode("input", {
        class: "input",
        type: "file",
        name: "upload",
        ref: "inputRef",
        accept: _ctx.accept,
        onChange: _cache[0] || (_cache[0] = $event => _ctx.fileUpload($event))
      }, null, 40, _hoisted_1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_loading, {
        active: _ctx.isLoading,
        "onUpdate:active": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isLoading) = $event)),
        "can-cancel": false,
        "on-cancel": _ctx.stopProgress,
        "is-full-page": true
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              id: "bar",
              class: "innerbar",
              style: _normalizeStyle(_ctx.progressinnerBar)
            }, _toDisplayString(_ctx.progresswith), 5)
          ])
        ]),
        _: 1
      }, 8, ["active", "on-cancel"])
    ])
  ], 64))
}