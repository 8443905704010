import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElementOutline = _resolveComponent("ElementOutline")!
  const _component_Chart = _resolveComponent("Chart")!

  return (_openBlock(), _createElementBlock("div", {
    class: "base-element-chart",
    style: _normalizeStyle({
      top: _ctx.elementInfo.top + 'px',
      left: _ctx.elementInfo.left + 'px',
      width: _ctx.elementInfo.width + 'px',
      height: _ctx.elementInfo.height + 'px',
    })
  }, [
    _createElementVNode("div", {
      class: "rotate-wrapper",
      style: _normalizeStyle({ transform: `rotate(${_ctx.elementInfo.rotate}deg)` })
    }, [
      _createElementVNode("div", {
        class: "element-content",
        style: _normalizeStyle({
          backgroundColor: _ctx.elementInfo.fill,
        })
      }, [
        _createVNode(_component_ElementOutline, {
          width: _ctx.elementInfo.width,
          height: _ctx.elementInfo.height,
          outline: _ctx.elementInfo.outline
        }, null, 8, ["width", "height", "outline"]),
        _createVNode(_component_Chart, {
          width: _ctx.elementInfo.width * _ctx.zoom,
          height: _ctx.elementInfo.height * _ctx.zoom,
          type: _ctx.elementInfo.chartType,
          data: _ctx.elementInfo.data,
          options: _ctx.elementInfo.options,
          themeColor: _ctx.elementInfo.themeColor,
          gridColor: _ctx.elementInfo.gridColor,
          legends: _ctx.elementInfo.data.legends,
          legend: _ctx.elementInfo.legend || '',
          style: _normalizeStyle({ zoom: 1 / _ctx.zoom })
        }, null, 8, ["width", "height", "type", "data", "options", "themeColor", "gridColor", "legends", "legend", "style"])
      ], 4)
    ], 4)
  ], 4))
}