
import { defineComponent } from 'vue'
import { ElementAlignCommands } from '@/types/edit'
import useCombineElement from '@/hooks/useCombineElement'
import useAlignActiveElement from '@/hooks/useAlignActiveElement'
import useAlignElementToCanvas from '@/hooks/useAlignElementToCanvas'
import useUniformDisplayElement from '@/hooks/useUniformDisplayElement'

export default defineComponent({
  name: 'multi-position-panel',
  setup() {
    const { canCombine, combineElements, uncombineElements } = useCombineElement()
    const { alignActiveElement } = useAlignActiveElement()
    const { alignElementToCanvas } = useAlignElementToCanvas()
    const { displayItemCount, uniformHorizontalDisplay, uniformVerticalDisplay } = useUniformDisplayElement()

    // 다중 선택 요소 정렬. 선택한 요소의 상태를 먼저 판단합니다.
    // 선택한 요소가 조합된 요소일 경우 정렬하기캔버스
    // 선택한 요소가 결합 요소가 아니거나 결합 요소가 아닌 경우(즉, 현재 결합 가능한 상태)에서는 여러 개의 (여러 개의) 요소를 서로 정렬합니다.
    const alignElement = (command: ElementAlignCommands) => {
      if (canCombine.value) alignActiveElement(command)
      else alignElementToCanvas(command)
    }

    return {
      canCombine,
      displayItemCount,
      combineElements,
      uncombineElements,
      uniformHorizontalDisplay,
      uniformVerticalDisplay,
      alignElement,
      ElementAlignCommands,
    }
  },
})
