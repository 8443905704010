
import { defineComponent, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore, useSlidesStore } from '@/store'
import { ImageOrShapeFlip } from '@/types/slides'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

export default defineComponent({
  name: 'element-flip',
  setup() {
    const slidesStore = useSlidesStore()
    const { handleElement } = storeToRefs(useMainStore())

    const flipH = ref(false)
    const flipV = ref(false)

    watch(handleElement, () => {
      if (handleElement.value && (handleElement.value.type === 'image' || handleElement.value.type === 'shape')) {
        flipH.value = !!handleElement.value.flipH
        flipV.value = !!handleElement.value.flipV
      }
    }, { deep: true, immediate: true })

    const { addHistorySnapshot } = useHistorySnapshot()

    const updateFlip = (flipProps: ImageOrShapeFlip) => {
      if (!handleElement.value) return
      slidesStore.updateElement({ id: handleElement.value.id, props: flipProps })
      addHistorySnapshot()
    }

    return {
      flipH,
      flipV,
      updateFlip,
    }
  },
})
