
import { computed, defineComponent, onMounted, onUnmounted, PropType, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore, useSlidesStore } from '@/store'
import { PPTTextElement } from '@/types/slides'
import { ContextmenuItem } from '@/components/Contextmenu/types'
import useElementShadow from '@/views/components/element/hooks/useElementShadow'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

import ElementOutline from '@/views/components/element/ElementOutline.vue'
import ProsemirrorEditor from '@/views/components/element/ProsemirrorEditor.vue'

export default defineComponent({
  name: 'editable-element-text',
  components: {
    ElementOutline,
    ProsemirrorEditor,
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTTextElement>,
      required: true,
    },
    selectElement: {
      type: Function as PropType<(e: MouseEvent | TouchEvent, element: PPTTextElement, canMove?: boolean) => void>,
      required: true,
    },
    contextmenus: {
      type: Function as PropType<() => ContextmenuItem[] | null>,
    },
  },
  setup(props) {
    const mainStore = useMainStore()
    const slidesStore = useSlidesStore()
    const { handleElementId, isScaling } = storeToRefs(mainStore)
    
    const { addHistorySnapshot } = useHistorySnapshot()

    const elementRef = ref<HTMLElement>()

    const shadow = computed(() => props.elementInfo.shadow)
    const { shadowStyle } = useElementShadow(shadow)

    const handleSelectElement = (e: MouseEvent | TouchEvent, canMove = true) => {
      if (props.elementInfo.lock) return
      e.stopPropagation()

      props.selectElement(e, props.elementInfo, canMove)
    }

    // 텍스트 요소의 크기 변경 사항을 듣고 높이가 변경될 때 vuex로 높이 업데이트하기
    // 높이가 변경되었을 때 확대/ 축소 작업이 진행 중이면 확대/ 축소 작업이 끝날 때까지 기다립니다다시 업데이트하기
    const realHeightCache = ref(-1)

    watch(isScaling, () => {


      if (handleElementId.value !== props.elementInfo.id) return

      if (!isScaling.value && realHeightCache.value !== -1) {
        slidesStore.updateElement({
          id: props.elementInfo.id,
          props: { height: realHeightCache.value },
        })
        realHeightCache.value = -1
      }
    })

    const updateTextElementHeight = (entries: ResizeObserverEntry[]) => {
      const contentRect = entries[0].contentRect
      if (!elementRef.value) return

      const realHeight = contentRect.height

      if (props.elementInfo.height !== realHeight) {
        if (!isScaling.value) {
          slidesStore.updateElement({
            id: props.elementInfo.id,
            props: { height: realHeight },
          })
        }
        else realHeightCache.value = realHeight
      }
    }
    const resizeObserver = new ResizeObserver(updateTextElementHeight)

    onMounted(() => {
      if (elementRef.value) resizeObserver.observe(elementRef.value)
    })
    onUnmounted(() => {
      if (elementRef.value) resizeObserver.unobserve(elementRef.value)
    })

    const updateContent = (content: string) => {
      slidesStore.updateElement({
        id: props.elementInfo.id,
        props: { content },
      })
      
      addHistorySnapshot()
    }

    const checkEmptyText = () => {
      const pureText = props.elementInfo.content.replaceAll(/<[^>]+>/g, '')
      if (!pureText) slidesStore.deleteElement(props.elementInfo.id)
    }

    const isHandleElement = computed(() => handleElementId.value === props.elementInfo.id)
    watch(isHandleElement, () => {
      if (!isHandleElement.value) checkEmptyText()
    })

    return {
      elementRef,
      shadowStyle,
      updateContent,
      handleSelectElement,
      checkEmptyText,
    }
  },
})
