
import { defineComponent, computed } from 'vue'
import tinycolor from 'tinycolor2'
import { storeToRefs } from 'pinia'
import { useMainStore, useSlidesStore } from '@/store'
import { VIEWPORT_SIZE } from '@/configs/canvas'
import { SlideBackground } from '@/types/slides'

export default defineComponent({
  name: 'grid-lines',
  setup() {
    const { canvasScale } = storeToRefs(useMainStore())
    const { currentSlide, viewportRatio } = storeToRefs(useSlidesStore())

    const background = computed<SlideBackground | undefined>(() => currentSlide.value?.background)

    // 그리드 선의 색을 계산하여 배경색과 너무 비슷하지 않게 합니다
    const gridColor = computed(() => {
      const bgColor = background.value?.color || '#fff'
      const colorList = ['#000', '#fff']
      return tinycolor.mostReadable(bgColor, colorList, { includeFallbackColors: true }).setAlpha(.5).toRgbString()
    })

    const gridSize = 50

    // 그리드 경로 계산
    const getPath = () => {
      const maxX = VIEWPORT_SIZE
      const maxY = VIEWPORT_SIZE * viewportRatio.value

      let path = ''
      for (let i = 0; i <= Math.floor(maxY / gridSize); i++) {
        path += `M0 ${i * gridSize} L${maxX} ${i * gridSize} `
      }
      for (let i = 0; i <= Math.floor(maxX / gridSize); i++) {
        path += `M${i * gridSize} 0 L${i * gridSize} ${maxY} `
      }
      return path
    }

    return {
      canvasScale,
      gridColor,
      width: VIEWPORT_SIZE,
      height: VIEWPORT_SIZE * viewportRatio.value,
      path: getPath(),
    }
  },
})
