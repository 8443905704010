
import { defineComponent, Ref, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore, useSlidesStore } from '@/store'
import { PPTImageElement, SlideBackground } from '@/types/slides'
import { CLIPPATHS } from '@/configs/imageClip'
import { getImageDataURL } from '@/utils/image'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

import ElementOutline from '../common/ElementOutline.vue'
import ElementShadow from '../common/ElementShadow.vue'
import ElementFlip from '../common/ElementFlip.vue'
import ElementFilter from '../common/ElementFilter.vue'
import ElementQuizPopupSetting from '../common/ElementQuizPopupSetting.vue'
//import QuizPopDialog from '../../QuizPopDialog/ExportJSON.vue'
//import Modal from '../../QuizPopDialog/ExportJSON.vue'

import axios from 'axios'
import {DialogForQuizPop} from '@/types/quizpop'

const shapeClipPathOptions = CLIPPATHS



const ratioClipOptions = [
  {
    label: '종횡비(사각형)',
    children: [
      { key: '1:1', ratio: 1 / 1 },
    ],
  },
  {
    label: '종횡비(세로)',
    children: [
      { key: '2:3', ratio: 3 / 2 },
      { key: '3:4', ratio: 4 / 3 },
      { key: '3:5', ratio: 5 / 3 },
      { key: '4:5', ratio: 5 / 4 },
    ],
  },
  {
    label: '종횡비(가로)',
    children: [
      { key: '3:2', ratio: 2 / 3 },
      { key: '4:3', ratio: 3 / 4 },
      { key: '5:3', ratio: 3 / 5 },
      { key: '5:4', ratio: 4 / 5 },
    ],
  },
  {
    children: [
      { key: '16:9', ratio: 9 / 16 },
      { key: '16:10', ratio: 10 / 16 },
    ],
  },
]

export default defineComponent({
  name: 'image-style-panel',
  components: {
    ElementOutline,
    ElementShadow,
    ElementFlip,
    ElementFilter,
    ElementQuizPopupSetting,

  },
  data() {
    return {
      showModal: false
    }
  },
  setup() {
    const mainStore = useMainStore()
    const slidesStore = useSlidesStore()
    const { handleElement, handleElementId } = storeToRefs(mainStore)
    const { currentSlide } = storeToRefs(slidesStore)

    const clipPanelVisible = ref(false)

    const { addHistorySnapshot } = useHistorySnapshot()

    //const { dialogQuizPop } = storeToRefs(mainStore)
    // 프리컷 열기
    const clipImage = () => {
      mainStore.setClipingImageElementId(handleElementId.value)
      clipPanelVisible.value = false
    }
    const question_text = () => {
      // alert(handleElementId.value)
      // alert((<HTMLInputElement>document.getElementById("text_value")).value)

      const temp_ = slidesStore.slides

      for (let i = 0; i < temp_.length ; i++) {
        for (let t = 0 ; t < temp_[i].elements.length ; t++ ){
          if(temp_[i].id === currentSlide.value.id && temp_[i].elements[t].id === handleElementId.value){
            temp_[i].elements[t]['question_text'] = (<HTMLInputElement>document.getElementById("text_value")).value ;

            const imgFIle = document.getElementById("img_value") as HTMLInputElement | null
            if(imgFIle !=null && imgFIle.files !=null && imgFIle.files.length > 0) {
              const temp___ = window.URL.createObjectURL((imgFIle.files[0]))

              temp_[i].elements[t]['question_img'] =  temp___.split('/')[temp___.split('/').length-1];

            }

            //if((<HTMLInputElement>document.getElementById("img_value")).files[0] !== null ){
            //
            //}
            //  window.URL.createObjectURL((<HTMLInputElement>document.getElementById("img_value")).files[0]);
            // let itemImage = (<HTMLInputElement>document.getElementById("img_value")); //img dom 접근

            //itemImage.src = window.URL.createObjectURL(image.files[0]);//img src에 blob주소 변환

            // this.itemImageInfo.uploadImages = itemImage.src;


            break;
          }
        }
      }

    }
    // 원본 그림의 위치 크기 가져오기
    const getImageElementDataBeforeClip = () => {
      const _handleElement = handleElement.value as PPTImageElement

      // 그림의 현재 위치 크기와 잘라내기 범위
      const imgWidth = _handleElement.width
      const imgHeight = _handleElement.height
      const imgLeft = _handleElement.left
      const imgTop = _handleElement.top
      const originClipRange: [[number, number], [number, number]] = _handleElement.clip ? _handleElement.clip.range : [[0, 0], [100, 100]]

      const originWidth = imgWidth / ((originClipRange[1][0] - originClipRange[0][0]) / 100)
      const originHeight = imgHeight / ((originClipRange[1][1] - originClipRange[0][1]) / 100)
      const originLeft = imgLeft - originWidth * (originClipRange[0][0] / 100)
      const originTop = imgTop - originHeight * (originClipRange[0][1] / 100)

      return {
        originClipRange,
        originWidth,
        originHeight,
        originLeft,
        originTop,
      }
    }

    // 프리셋 커팅
    const presetImageClip = (shape: string, ratio = 0) => {
      const _handleElement = handleElement.value as PPTImageElement

      const {
        originClipRange,
        originWidth,
        originHeight,
        originLeft,
        originTop,
      } = getImageElementDataBeforeClip()

      // 종횡비컷(형상은 직사각형으로 고정)
      if (ratio) {
        const imageRatio = originHeight / originWidth

        const min = 0
        const max = 100
        let range: [[number, number], [number, number]]

        if (imageRatio > ratio) {
          const distance = ((1 - ratio / imageRatio) / 2) * 100
          range = [[min, distance], [max, max - distance]]
        }
        else {
          const distance = ((1 - imageRatio / ratio) / 2) * 100
          range = [[distance, min], [max - distance, max]]
        }
        slidesStore.updateElement({
          id: handleElementId.value,
          props: {
            clip: { ..._handleElement.clip, shape, range },
            left: originLeft + originWidth * (range[0][0] / 100),
            top: originTop + originHeight * (range[0][1] / 100),
            width: originWidth * (range[1][0] - range[0][0]) / 100,
            height: originHeight * (range[1][1] - range[0][1]) / 100,
          },
        })
      }
      // 셰이프 컷 (현재 컷 범위 유지)
      else {
        slidesStore.updateElement({
          id: handleElementId.value,
          props: {
            clip: { ..._handleElement.clip, shape, range: originClipRange }
          },
        })
      }
      clipImage()
      addHistorySnapshot()
    }

    // 그림 바꾸기 (현재 스타일 유지)
    const replaceImage = (files: File[]) => {
      const imageFile = files[0]
      if (!imageFile) return
      getImageDataURL(imageFile).then(dataURL => {
        const props = { src: dataURL }
        slidesStore.updateElement({ id: handleElementId.value, props })
      })
      addHistorySnapshot()
    }


    // 그림 바꾸기 (현재 스타일 유지)
    const replaceImageServerUpdate = (files: File[]) => {
      const imageFile = files[0]
      if (!imageFile) return

      const frm = new FormData();
      let data = {filePath: "image"};
      frm.append('file', imageFile)
      //frm.append('file', file_list );
      frm.append('data', new Blob([JSON.stringify(data)], {type : 'application/json'}));

      axios.post('/rest/insertLC0File',frm,  {
        headers: {
          'Content-Type': 'multipart/form-data'
        }})
          .then(response => {
            console.log(response.data.result)
            if (response.data.result == '1') {

              //alert(response.data.file_cn +":"+handleElementId.value)
              // getImageDataURL(imageFile).then(dataURL => {
              const props = { src:"https://kls.lms.next-meta.co" + response.data.file_cn }
              slidesStore.updateElement({ id: handleElementId.value, props })
              //})
            }
          })


      addHistorySnapshot()
    }

    // 그림 재설정: 모든 스타일 지우기
    const resetImage = () => {
      const _handleElement = handleElement.value as PPTImageElement

      if (_handleElement.clip) {
        const {
          originWidth,
          originHeight,
          originLeft,
          originTop,
        } = getImageElementDataBeforeClip()

        slidesStore.updateElement({
          id: handleElementId.value,
          props: {
            left: originLeft,
            top: originTop,
            width: originWidth,
            height: originHeight,
          },
        })
      }

      slidesStore.removeElementProps({
        id: handleElementId.value,
        propName: ['clip', 'outline', 'flip', 'shadow', 'filters'],
      })
      addHistorySnapshot()
    }

    // 그림 배경 그림 설정
    const setBackgroundImage = () => {
      const _handleElement = handleElement.value as PPTImageElement

      const background: SlideBackground = {
        ...currentSlide.value.background,
        type: 'image',
        image: _handleElement.src,
        imageSize: 'cover',
      }
      slidesStore.updateSlide({ background })
      addHistorySnapshot()
    }


    const question_ok = () => {

      // alert((<HTMLInputElement>document.getElementById("text_value")).value)

      const temp_ = slidesStore.slides

      for (let i = 0; i < temp_.length ; i++) {
        for (let t = 0 ; t < temp_[i].elements.length ; t++ ){
          if(temp_[i].id === currentSlide.value.id && temp_[i].elements[t].id === handleElementId.value){
            temp_[i].elements[t]['question'] = 'Y'
            break
          }
        }
      }
      // slidesStore.updateElement({id: handleElementId.value, ''})
      // addHistorySnapshot()
      //localStorage.setItem(handleElementId.value, 'document.getElementById(\'editable-element-' + handleElementId.value + '\').onclick = function(e) { alert("'+(<HTMLInputElement>document.getElementById("text_value")).value+'");}')


    }



    const question_no = () => {
      // alert(handleElementId.value)

      // localStorage.setItem(handleElementId.value, 'document.getElementById(\'editable-element-' + handleElementId.value + '\').onclick = function(e) { alert("오답");}')
      const temp_ = slidesStore.slides

      for (let i = 0; i < temp_.length ; i++) {
        for (let t = 0 ; t < temp_[i].elements.length ; t++ ){
          if(temp_[i].id === currentSlide.value.id && temp_[i].elements[t].id === handleElementId.value){
            temp_[i].elements[t]['question'] = 'N'
            break
          }
        }
      }

    }


    return {
      clipPanelVisible,
      shapeClipPathOptions,
      ratioClipOptions,
      question_text,
      handleElement: handleElement as Ref<PPTImageElement>,
      clipImage,
      presetImageClip,
      replaceImage,
      replaceImageServerUpdate,
      resetImage,
      setBackgroundImage,
      question_ok,
      question_no,



    }
  },
})
