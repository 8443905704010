import { ref, computed, onMounted, onUnmounted, Ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore, useSlidesStore } from '@/store'
import { VIEWPORT_SIZE } from '@/configs/canvas'

export default (canvasRef: Ref<HTMLElement | undefined>) => {
  const viewportLeft = ref(0)
  const viewportTop = ref(0)

  const mainStore = useMainStore()
  const { canvasPercentage, canvasDragged } = storeToRefs(mainStore)
  const { viewportRatio } = storeToRefs(useSlidesStore())

  // 캔버스 가시 영역의 위치를 계산하다
  const setViewportPosition = () => {
    1
  }

  // 보기 영역의 확대/ 축소 또는 축척이 변경되었을 때 보기 영역의 위치를 업데이트합니다
  watch([canvasPercentage, viewportRatio], setViewportPosition)

  // (캔버스)상태 변화 복원 드래그 할 때, 가시 권역의 위치를 업데이트하고 있다.
  watch(canvasDragged, () => {
    if (!canvasDragged.value) setViewportPosition()
  })


  // 캔버스 가시영역 위치 및 크기
  const viewportStyles = computed(() => ({
    width: VIEWPORT_SIZE,
    height: VIEWPORT_SIZE * 1.5,
    left: viewportLeft.value,
    top: viewportTop.value,
  }))

  //  캔버스 사이즈가 변경되었을 때, 가시 영역의 위치를 갱신합니다
  const resizeObserver = new ResizeObserver(setViewportPosition)

  onMounted(() => {
    if (canvasRef.value) resizeObserver.observe(canvasRef.value)
  })
  onUnmounted(() => {
    if (canvasRef.value) resizeObserver.unobserve(canvasRef.value)
  })

  // 캔버스를 끌다.
  const dragViewport = (e: MouseEvent) => {
    let isMouseDown = true

    const startPageX = e.pageX
    const startPageY = e.pageY

    const originLeft = viewportLeft.value
    const originTop = viewportTop.value

    document.onmousemove = e => {
      if (!isMouseDown) return

      const currentPageX = e.pageX
      const currentPageY = e.pageY

      viewportLeft.value = originLeft + (currentPageX - startPageX)
      viewportTop.value = originTop + (currentPageY - startPageY)
    }

    document.onmouseup = () => {
      isMouseDown = false
      document.onmousemove = null
      document.onmouseup = null

      mainStore.setCanvasDragged(true)
    }
  }

  return {
    viewportStyles,
    dragViewport,
  }
}