export const ANIMATION_DEFAULT_DURATION = 1000
export const ANIMATION_DEFAULT_TRIGGER = 'click'
export const ANIMATION_CLASS_PREFIX = 'animate__'

export const ENTER_ANIMATIONS = [
  {
    type: 'bounce',
    name: '튐김',
    children: [
      { name: '튕겨들어감', value: 'bounceIn' },
      { name: '오른쪽으로 튕기기', value: 'bounceInLeft' },
      { name: '왼쪽으로 튕기기', value: 'bounceInRight' },
      { name: '위로 튕기기', value: 'bounceInUp' },
      { name: '아래로 튕겨넣기', value: 'bounceInDown' },
    ],
  },
  {
    type: 'fade',
    name: '띄우다',
    children: [
      { name: '들뜨다', value: 'fadeIn' },
      { name: '아래로뜨다', value: 'fadeInDown' },
      { name: '길게아래로뜨다', value: 'fadeInDownBig' },
      { name: '오른쪽으로띄우다', value: 'fadeInLeft' },
      { name: '오른쪽으로길게띄우다', value: 'fadeInLeftBig' },
      { name: '왼쪽으로띄우다', value: 'fadeInRight' },
      { name: '왼쪽으로길거띄워넣다', value: 'fadeInRightBig' },
      { name: '위로떠오르다', value: 'fadeInUp' },
      { name: '위로길게떠오르다', value: 'fadeInUpBig' },
      { name: '왼쪽위에서떠오르다', value: 'fadeInTopLeft' },
      { name: '오른쪽위에서떠오르다', value: 'fadeInTopRight' },
      { name: '왼쪽아래에서띄우다', value: 'fadeInBottomLeft' },
      { name: '오른쪽아래에서띄우다', value: 'fadeInBottomRight' },
    ],
  },
  {
    type: 'rotate',
    name: '회전',
    children: [
      { name: '회전진입', value: 'rotateIn' },
      { name: '왼쪽아래돌아들어감', value: 'rotateInDownLeft' },
      { name: '오른쪽아래돌아들어감', value: 'rotateInDownRight' },
      { name: '좌측상단을돌아들어감', value: 'rotateInUpLeft' },
      { name: '우측상단을돌아들어감', value: 'rotateInUpRight' },
    ],
  },
  {
    type: 'zoom',
    name: '확대축소',
    children: [
      { name: '확대진입', value: 'zoomIn' },
      { name: '아래로확대해서들어감', value: 'zoomInDown' },
      { name: '왼쪽에서확대해서들어감', value: 'zoomInLeft' },
      { name: '오른쪽에서확대해서들어감', value: 'zoomInRight' },
      { name: '위로확대해서들어감', value: 'zoomInUp' },
    ],
  },
  {
    type: 'slide',
    name: '슬라이드',
    children: [
      { name: '아래로슬라이드', value: 'slideInDown' },
      { name: '오른쪽으로슬라이드', value: 'slideInLeft' },
      { name: '왼쪽으로슬라이드', value: 'slideInRight' },
      { name: '위로슬라이드', value: 'slideInUp' },
    ],
  },
  {
    type: 'flip',
    name: '반전',
    children: [
      { name: 'X축반전진입', value: 'flipInX' },
      { name: 'Y축반전진입', value: 'flipInY' },
    ],
  },
  {
    type: 'back',
    name: '확장슬라이드',
    children: [
      { name: '아래로확장슬라이드', value: 'backInDown' },
      { name: '왼쪽에서확장슬라이드', value: 'backInLeft' },
      { name: '오른쪽에서확장슬라이드', value: 'backInRight' },
      { name: '위로확장슬라이드', value: 'backInUp' },
    ],
  },
  {
    type: 'lightSpeed',
    name: '날아들다',
    children: [
      { name: '오른쪽에서날아들다', value: 'lightSpeedInRight' },
      { name: '왼쪽에서날아들다', value: 'lightSpeedInLeft' },
    ],
  },
]

export const EXIT_ANIMATIONS = [
  {
    type: 'bounce',
    name: '팝업',
    children: [
      { name: '팝업', value: 'bounceIn' },
      { name: '우측으로 팝업', value: 'bounceInLeft' },
      { name: '왼쪽 방향으로 팝업', value: 'bounceInRight' },
      { name: '위로 팝업', value: 'bounceInUp' },
      { name: '아래로 밀어넣기', value: 'bounceInDown' },
    ],
  },
  {
    type: 'fade',
    name: '떠나다',
    children: [
      { name: '떠들다', value: 'fadeOut' },
      { name: '아래로 띄우기', value: 'fadeOutDown' },
      { name: '아래로 길게 뜨기', value: 'fadeOutDownBig' },
      { name: '오른쪽으로 띄우기', value: 'fadeOutLeft' },
      { name: '오른쪽으로 길게 띄우기', value: 'fadeOutLeftBig' },
      { name: '왼쪽 방향으로 띄우기', value: 'fadeOutRight' },
      { name: '좌향으로 이동', value: 'fadeOutRightBig' },
      { name: '떠올라라', value: 'fadeOutUp' },
      { name: '긴 거리를 위로 이동', value: 'fadeOutUpBig' },
      { name: '왼쪽에서 위로 뜬다', value: 'fadeOutTopLeft' },
      { name: '오른쪽에서 뜬다', value: 'fadeOutTopRight' },
      { name: '왼쪽 아래에서 뜬다', value: 'fadeOutBottomLeft' },
      { name: '오른쪽 아래에서 뜬다', value: 'fadeOutBottomRight' },
    ],
  },
  {
    type: 'rotate',
    name: '회전',
    children: [
      { name: '회전 입력', value: 'rotateOut' },
      { name: '왼쪽 아래를 돌아 들어가세요.', value: 'rotateOutDownLeft' },
      { name: '오른쪽 아래를 돌아 들어오다', value: 'rotateOutDownRight' },
      { name: '왼쪽 위쪽으로 돌아가세요', value: 'rotateOutUpLeft' },
      { name: '오른쪽 위로 돌아가기', value: 'rotateOutUpRight' },
    ],
  },
  {
    type: 'zoom',
    name: '스케일링',
    children: [
      { name: '확대 입력', value: 'zoomOut' },
      { name: '아래로 줌 인', value: 'zoomOutDown' },
      { name: '왼쪽에서 확대해서 들어갑니다', value: 'zoomOutLeft' },
      { name: '오른쪽에서 확대해서 들어갑니다', value: 'zoomOutRight' },
      { name: '위쪽으로 줌 인', value: 'zoomOutUp' },
    ],
  },
  {
    type: 'slide',
    name: '슬라이드 인',
    children: [
      { name: '아래로 미끄러짐', value: 'slideOutDown' },
      { name: '오른쪽에서 미끄러져 들어가다', value: 'slideOutLeft' },
      { name: '왼쪽에서 미끄러져 들어가다', value: 'slideOutRight' },
      { name: '슬라이드 업', value: 'slideOutUp' },
    ],
  },
  {
    type: 'flip',
    name: '뒤집기',
    children: [
      { name: 'X축 반전 입력', value: 'flipOutX' },
      { name: 'Y축 반전 입력', value: 'flipOutY' },
    ],
  },
  {
    type: 'back',
    name: '확대 미끄러짐',
    children: [
      { name: '아래로 확대/ 축소하기', value: 'backOutDown' },
      { name: '왼쪽에서 확대/ 축소하기', value: 'backOutLeft' },
      { name: '오른쪽에서 확대/ 축소하기', value: 'backOutRight' },
      { name: '위로 슬라이드 업', value: 'backOutUp' },
    ],
  },
  {
    type: 'lightSpeed',
    name: '날아라',
    children: [
      { name: '오른쪽에서 날아들다', value: 'lightSpeedOutRight' },
      { name: '왼쪽에서 날아들다', value: 'lightSpeedOutLeft' },
    ],
  },
]

export const ATTENTION_ANIMATIONS = [
  {
    type: 'shake',
    name: '흔들흔들',
    children: [
      { name: '좌우 흔들림', value: 'shakeX' },
      { name: '위아래로 흔들어요', value: 'shakeY' },
      { name: '절레', value: 'headShake' },
      { name: '흔들림', value: 'swing' },
      { name: '흔들흔들', value: 'wobble' },
      { name: '놀람', value: 'tada' },
      { name: '젤리', value: 'jello' },
    ],
  },
  {
    type: 'other',
    name: '기타',
    children: [
      { name: '팝업', value: 'bounce' },
      { name: '반짝반짝', value: 'flash' },
      { name: '맥박', value: 'pulse' },
      { name: '고무줄', value: 'rubberBand' },
      { name: '심쿵(빠르게)', value: 'heartBeat' },
    ],
  },
]