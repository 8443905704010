import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "chart",
    style: _normalizeStyle({ flexDirection: _ctx.legend === 'top' ? 'column-reverse' : 'column' })
  }, [
    _createElementVNode("div", {
      class: "chart-content",
      ref: "chartRef",
      style: _normalizeStyle({
        width: _ctx.width + 'px',
        height: _ctx.chartHeight + 'px',
        transform: `scale(${1 / _ctx.slideScale})`,
      })
    }, null, 4),
    (_ctx.legend)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "legends",
          style: _normalizeStyle({ transform: `scale(${1 / _ctx.slideScale})` })
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.legends, (legend, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "legend",
              key: index,
              style: _normalizeStyle({ color: _ctx.gridColor })
            }, [
              _createElementVNode("div", {
                class: "block",
                style: _normalizeStyle({ backgroundColor: _ctx.themeColors[index] })
              }, null, 4),
              _createTextVNode(" " + _toDisplayString(legend), 1)
            ], 4))
          }), 128))
        ], 4))
      : _createCommentVNode("", true)
  ], 4))
}