
import { computed, defineComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore } from '@/store'

import ExportJSON from './ExportJSON.vue'
import ExportLIST from './ExportLIST.vue'
export default defineComponent({
  name: 'save-dialog',
  setup() {
    const mainStore = useMainStore()
    const { dialogQuizPop } = storeToRefs(mainStore)

    const setDialogForQuizPop = mainStore.setDialogForQuizPop


    const currentDialogComponent = computed(() => {
      const dialogMap = {
        'json': ExportJSON,
        'listItem': ExportLIST,
      }

      return dialogMap[dialogQuizPop.value] || null
    })

    return {
      currentDialogComponent,
      dialogQuizPop,
      setDialogForQuizPop,
    }
  },
})
