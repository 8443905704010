export const PRESET_THEMES = [
  { headerColor1: '#0abac8', headerColor2: '#3D9CC2', bodyColor1: '#F86E68', bodyColor2: '#FF3749'},
  { headerColor1: '#50CA6B', headerColor2: '#01A125', bodyColor1: '#ECBF9E', bodyColor2: '#F0DAC9'},
  { headerColor1: '#06BDFD', headerColor2: '#06BDFD', bodyColor1: '#FBECA0', bodyColor2: '#FFE76A'},
  { headerColor1: '#E2534D', headerColor2: '#EC736E', bodyColor1: '#D6A989', bodyColor2: '#EEECE1'},

]
/*
export const PRESET_THEMES = [
  { color: '#d14424', background: '#ffffff', text: '#333' },
  { color: '#42464b', background: '#ffffff', text: '#333' },
  { color: '#5d82ba', background: '#ffffff', text: '#333' },
  { color: '#005a6f', background: '#ffffff', text: '#333' },
  { color: '#d0614c', background: '#dfb044', text: '#333' },
  { color: '#86a1ad', background: '#dfdbd4', text: '#333' },
  { color: '#697586', background: '#d5c4a4', text: '#333' },
  { color: '#333333', background: '#7acfa6', text: '#333' },
  { color: '#42464b', background: '#415065', text: '#fff' },
  { color: '#0c5999', background: '#35a2cd', text: '#fff' },
  { color: '#c49a41', background: '#8c4357', text: '#fff' },
  { color: '#dfaa00', background: '#2e4e7d', text: '#fff' },
  { color: '#d1ad88', background: '#f99070', text: '#fff' },
  { color: '#464d52', background: '#657984', text: '#fff' },
  { color: '#ffcfb6', background: '#1e4c6f', text: '#fff' },
  { color: '#c3a043', background: '#43292a', text: '#fff' },
  { color: '#464d52', background: '#60546f', text: '#fff' },
  { color: '#df9636', background: '#5b89a0', text: '#fff' },
  { color: '#b898a4', background: '#93716b', text: '#fff' },
  { color: '#c47a11', background: '#187db1', text: '#fff' },
  { color: '#333333', background: '#759564', text: '#fff' },
  { color: '#355b5e', background: '#424b50', text: '#fff' },
  { color: '#d29090', background: '#942a32', text: '#fff' },
  { color: '#00cfdf', background: '#3b434d', text: '#fff' },
  { color: '#424246', background: '#c70042', text: '#fff' },
  { color: '#2e4155', background: '#b35d44', text: '#fff' },
  { color: '#11bfce', background: '#8f98aa', text: '#fff' },
  { color: '#333333', background: '#549688', text: '#fff' },
]

 */