
import { computed, defineComponent, onMounted, onUnmounted, PropType, ref, watch } from 'vue'
import { throttle } from 'lodash'

export default defineComponent({
  name: 'writing-board',
  props: {
    color: {
      type: String,
      default: '#ffcc00',
    },
    model: {
      type: String as PropType<'pen' | 'eraser' | 'mark'>,
      default: 'pen',
    },
    blackboard: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    let ctx: CanvasRenderingContext2D | null = null
    const writingBoardRef = ref<HTMLElement>()
    const canvasRef = ref<HTMLCanvasElement>()

    const penSize = ref(6)
    const rubberSize = ref(80)
    const markSize = ref(24)

    let lastPos = {
      x: 0,
      y: 0,
    }
    let isMouseDown = false
    let lastTime = 0
    let lastLineWidth = -1

    // 마우스 위치 좌표: 브러시나 지우개 위치 추적에 사용
    const mouse = ref({
      x: 0,
      y: 0,
    })
    
    // 마우스가 캔버스 범위 내에 있는지 여부: 브러시나 지우개를 표시하려면 범위 내에 있어야 합니다
    const mouseInCanvas = ref(false)

    // canvas 크기 업데이트 듣기
    const canvasWidth = ref(0)
    const canvasHeight = ref(0)

    const widthScale = computed(() => canvasRef.value ? canvasWidth.value / canvasRef.value.width : 1)
    const heightScale = computed(() => canvasRef.value ? canvasHeight.value / canvasRef.value.height : 1)

    const updateCanvasSize = () => {
      if (!writingBoardRef.value) return
      canvasWidth.value = writingBoardRef.value.clientWidth
      canvasHeight.value = writingBoardRef.value.clientHeight
    }
    const resizeObserver = new ResizeObserver(updateCanvasSize)
    onMounted(() => {
      if (writingBoardRef.value) resizeObserver.observe(writingBoardRef.value)
    })
    onUnmounted(() => {
      if (writingBoardRef.value) resizeObserver.unobserve(writingBoardRef.value)
    })

    // 캔버스 초기화
    const initCanvas = () => {
      if (!canvasRef.value || !writingBoardRef.value) return

      ctx = canvasRef.value.getContext('2d')
      if (!ctx) return

      canvasRef.value.width = writingBoardRef.value.clientWidth
      canvasRef.value.height = writingBoardRef.value.clientHeight

      ctx.lineCap = 'round'
      ctx.lineJoin = 'round'
    }
    onMounted(initCanvas)

    // 브러시 모드를 바꿀 때 canvas ctx 설정을 업데이트합니다
    const updateCtx = () => {
      if (!ctx) return
      if (props.model === 'mark') {
        ctx.globalCompositeOperation = 'xor'
        ctx.globalAlpha = 0.5
      }
      else if (props.model === 'pen') {
        ctx.globalCompositeOperation = 'source-over'
        ctx.globalAlpha = 1
      }
    }
    watch(() => props.model, updateCtx)

    // 브러시 잉크 그리기 방법
    const draw = (posX: number, posY: number, lineWidth: number) => {
      if (!ctx) return

      const lastPosX = lastPos.x
      const lastPosY = lastPos.y

      ctx.lineWidth = lineWidth
      ctx.strokeStyle = props.color
      ctx.beginPath()
      ctx.moveTo(lastPosX, lastPosY)
      ctx.lineTo(posX, posY)
      ctx.stroke()
      ctx.closePath()
    }

    // 잉크 자국을 지우는 방법
    const erase = (posX: number, posY: number) => {
      if (!ctx || !canvasRef.value) return
      const lastPosX = lastPos.x
      const lastPosY = lastPos.y

      const radius = rubberSize.value / 2

      const sinRadius = radius * Math.sin(Math.atan((posY - lastPosY) / (posX - lastPosX)))
      const cosRadius = radius * Math.cos(Math.atan((posY - lastPosY) / (posX - lastPosX)))
      const rectPoint1: [number, number] = [lastPosX + sinRadius, lastPosY - cosRadius]
      const rectPoint2: [number, number] = [lastPosX - sinRadius, lastPosY + cosRadius]
      const rectPoint3: [number, number] = [posX + sinRadius, posY - cosRadius]
      const rectPoint4: [number, number] = [posX - sinRadius, posY + cosRadius]

      ctx.save()
      ctx.beginPath()
      ctx.arc(posX, posY, radius, 0, Math.PI * 2)
      ctx.clip()
      ctx.clearRect(0, 0, canvasRef.value.width, canvasRef.value.height)
      ctx.restore()

      ctx.save()
      ctx.beginPath()
      ctx.moveTo(...rectPoint1)
      ctx.lineTo(...rectPoint3)
      ctx.lineTo(...rectPoint4)
      ctx.lineTo(...rectPoint2)
      ctx.closePath()
      ctx.clip()
      ctx.clearRect(0, 0, canvasRef.value.width, canvasRef.value.height)
      ctx.restore()
    }

    // 두 번의 마우스 움직임 사이의 거리 계산
    const getDistance = (posX: number, posY: number) => {
      const lastPosX = lastPos.x
      const lastPosY = lastPos.y
      return Math.sqrt((posX - lastPosX) * (posX - lastPosX) + (posY - lastPosY) * (posY - lastPosY))
    }

    // 마우스를 두 번 움직인 거리 s와 시간 t에 따라 그리기 속도를 계산하면 속도가 빠를수록 잉크 자국이 가늘어진다.
    const getLineWidth = (s: number, t: number) => {
      const maxV = 10
      const minV = 0.1
      const maxWidth = penSize.value
      const minWidth = 3
      const v = s / t
      let lineWidth

      if (v <= minV) lineWidth = maxWidth
      else if (v >= maxV) lineWidth = minWidth
      else lineWidth = maxWidth - v / maxV * maxWidth

      if (lastLineWidth === -1) return lineWidth
      return lineWidth * 1 / 3 + lastLineWidth * 2 / 3
    }

    // 경로 조작
    const handleMove = (x: number, y: number) => {
      const time = new Date().getTime()

      if (props.model === 'pen') {
        const s = getDistance(x, y)
        const t = time - lastTime
        const lineWidth = getLineWidth(s, t)

        draw(x, y, lineWidth)
        lastLineWidth = lineWidth
      }
      else if (props.model === 'mark') draw(x, y, markSize.value)
      else erase(x, y)

      lastPos = { x, y }
      lastTime = new Date().getTime()
    }

    // canvas에서 마우스 상대 위치 가져오기
    const getMouseOffsetPosition = (e: MouseEvent | TouchEvent) => {
      if (!canvasRef.value) return [0, 0]
      const event = e instanceof MouseEvent ? e : e.changedTouches[0]
      const canvasRect = canvasRef.value.getBoundingClientRect()
      const x = event.pageX - canvasRect.x
      const y = event.pageY - canvasRect.y
      return [x, y]
    }

    // 마우스 이벤트 처리 (터치)
    // 그리기 시작 준비하기/먹자국 지우기 (낙필)
    const handleMousedown = (e: MouseEvent | TouchEvent) => {
      const [mouseX, mouseY] = getMouseOffsetPosition(e)
      const x = mouseX / widthScale.value
      const y = mouseY / heightScale.value

      isMouseDown = true
      lastPos = { x, y }
      lastTime = new Date().getTime()

      if (!(e instanceof MouseEvent)) {
        mouse.value = { x: mouseX, y: mouseY }
        mouseInCanvas.value = true
      }
    }

    // 잉크 그리기/ 지우기 시작 (이동)
    const handleMousemove = (e: MouseEvent | TouchEvent) => {
      const [mouseX, mouseY] = getMouseOffsetPosition(e)
      const x = mouseX / widthScale.value
      const y = mouseY / heightScale.value

      mouse.value = { x: mouseX, y: mouseY }

      if (isMouseDown) handleMove(x, y)
    }

    // 잉크 그리기/ 지우기 끝 (붓을 멈추기)
    const handleMouseup = () => {
      if (!isMouseDown) return
      isMouseDown = false
    }

    // 캔버스를 비우다.
    const clearCanvas = () => {
      if (!ctx || !canvasRef.value) return
      ctx.clearRect(0, 0, canvasRef.value.width, canvasRef.value.height)
    }

    // DataURL 가져오기
    const getImageDataURL = () => {
      return canvasRef.value?.toDataURL()
    }
    
    // DataURL 설정 (canvas에 그림 그리기)
    const setImageDataURL = (imageDataURL: string) => {
      const img = new Image()
      img.src = imageDataURL
      img.onload = () => {
        if (!ctx) return
        ctx.drawImage(img, 0, 0)
      }
    }

    // 마우스 휠 스크롤, 펜촉 크기 조정
    const mousewheelListener = throttle(function(e: WheelEvent) {
      if (props.model === 'eraser') {
        if (e.deltaY < 0 && rubberSize.value < 200) rubberSize.value += 20
        else if (e.deltaY > 0 && rubberSize.value > 20) rubberSize.value -= 20
      }
      if (props.model === 'pen') {
        if (e.deltaY < 0 && penSize.value < 10) penSize.value += 2
        else if (e.deltaY > 0 && penSize.value > 4) penSize.value -= 2
      }
      if (props.model === 'mark') {
        if (e.deltaY < 0 && markSize.value < 40) markSize.value += 4
        else if (e.deltaY > 0 && markSize.value > 16) markSize.value -= 4
      }
    }, 300, { leading: true, trailing: false })

    return {
      mouse,
      mouseInCanvas,
      penSize,
      rubberSize,
      markSize,
      writingBoardRef,
      canvasRef,
      canvasWidth,
      canvasHeight,
      handleMousedown,
      handleMousemove,
      handleMouseup,
      clearCanvas,
      getImageDataURL,
      setImageDataURL,
      mousewheelListener,
    }
  },
})
