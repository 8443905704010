
import { defineComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useSlidesStore } from '@/store'
import useLoadSlides from '@/hooks/useLoadSlides'

import ThumbnailSlide from '@/views/components/ThumbnailSlide/index.vue'

export default defineComponent({
  name: 'mobile-thumbnails',
  components: {
    ThumbnailSlide,
  },
  setup() {
    const slidesStore = useSlidesStore()
    const { slides, slideIndex } = storeToRefs(slidesStore)

    const { slidesLoadLimit } = useLoadSlides()
    const changeSlideIndex = (index: number) => {
      slidesStore.updateSlideIndex(index)
    }

    return {
      slides,
      slideIndex,
      slidesLoadLimit,
      changeSlideIndex,
    }
  },
})
