import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ddaa3c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "element-create-selection",
    ref: "selectionRef",
    onMousedown: _cache[0] || (_cache[0] = _withModifiers($event => _ctx.createSelection($event), ["stop"])),
    onContextmenu: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop","prevent"]))
  }, [
    (_ctx.start && _ctx.end)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(['selection', _ctx.creatingElement?.type]),
          style: _normalizeStyle(_ctx.position)
        }, [
          (_ctx.creatingElement?.type === 'line' && _ctx.lineData)
            ? (_openBlock(), _createElementBlock("svg", {
                key: 0,
                overflow: "visible",
                width: _ctx.lineData.svgWidth,
                height: _ctx.lineData.svgHeight
              }, [
                _createElementVNode("path", {
                  d: _ctx.lineData.path,
                  stroke: "#d14424",
                  fill: "none",
                  "stroke-width": "2"
                }, null, 8, _hoisted_2)
              ], 8, _hoisted_1))
            : _createCommentVNode("", true)
        ], 6))
      : _createCommentVNode("", true)
  ], 544))
}