import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElementOutline = _resolveComponent("ElementOutline")!
  const _component_Chart = _resolveComponent("Chart")!
  const _directive_contextmenu = _resolveDirective("contextmenu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["editable-element-chart", { 'lock': _ctx.elementInfo.lock }]),
    style: _normalizeStyle({
      top: _ctx.elementInfo.top + 'px',
      left: _ctx.elementInfo.left + 'px',
      width: _ctx.elementInfo.width + 'px',
      height: _ctx.elementInfo.height + 'px',
    })
  }, [
    _createElementVNode("div", {
      class: "rotate-wrapper",
      style: _normalizeStyle({ transform: `rotate(${_ctx.elementInfo.rotate}deg)` })
    }, [
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "element-content",
        style: _normalizeStyle({
          backgroundColor: _ctx.elementInfo.fill,
        }),
        onMousedown: _cache[0] || (_cache[0] = $event => _ctx.handleSelectElement($event)),
        onTouchstart: _cache[1] || (_cache[1] = $event => _ctx.handleSelectElement($event)),
        onDblclick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openDataEditor()))
      }, [
        _createVNode(_component_ElementOutline, {
          width: _ctx.elementInfo.width,
          height: _ctx.elementInfo.height,
          outline: _ctx.elementInfo.outline
        }, null, 8, ["width", "height", "outline"]),
        _createVNode(_component_Chart, {
          width: _ctx.elementInfo.width,
          height: _ctx.elementInfo.height,
          type: _ctx.elementInfo.chartType,
          data: _ctx.elementInfo.data,
          options: _ctx.elementInfo.options,
          themeColor: _ctx.elementInfo.themeColor,
          gridColor: _ctx.elementInfo.gridColor,
          legends: _ctx.elementInfo.data.legends,
          legend: _ctx.elementInfo.legend || ''
        }, null, 8, ["width", "height", "type", "data", "options", "themeColor", "gridColor", "legends", "legend"])
      ], 36)), [
        [_directive_contextmenu, _ctx.contextmenus]
      ])
    ], 4)
  ], 6))
}