
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore, useSlidesStore } from '@/store'
import { PPTShapeElement, ShapeText } from '@/types/slides'
import { ContextmenuItem } from '@/components/Contextmenu/types'
import useElementOutline from '@/views/components/element/hooks/useElementOutline'
import useElementShadow from '@/views/components/element/hooks/useElementShadow'
import useElementFlip from '@/views/components/element/hooks/useElementFlip'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

import GradientDefs from './GradientDefs.vue'
import ProsemirrorEditor from '@/views/components/element/ProsemirrorEditor.vue'

export default defineComponent({
  name: 'editable-element-shape',
  components: {
    GradientDefs,
    ProsemirrorEditor,
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTShapeElement>,
      required: true,
    },
    selectElement: {
      type: Function as PropType<(e: MouseEvent | TouchEvent, element: PPTShapeElement, canMove?: boolean) => void>,
      required: true,
    },
    contextmenus: {
      type: Function as PropType<() => ContextmenuItem[] | null>,
    },
  },
  setup(props) {
    const mainStore = useMainStore()
    const slidesStore = useSlidesStore()
    const { handleElementId } = storeToRefs(mainStore)

    const { addHistorySnapshot } = useHistorySnapshot()

    const handleSelectElement = (e: MouseEvent | TouchEvent, canMove = true) => {
      if (props.elementInfo.lock) return
      e.stopPropagation()

      props.selectElement(e, props.elementInfo, canMove)
    }

    const outline = computed(() => props.elementInfo.outline)
    const { outlineWidth, outlineStyle, outlineColor } = useElementOutline(outline)
    
    const shadow = computed(() => props.elementInfo.shadow)
    const { shadowStyle } = useElementShadow(shadow)

    const flipH = computed(() => props.elementInfo.flipH)
    const flipV = computed(() => props.elementInfo.flipV)
    const { flipStyle } = useElementFlip(flipH, flipV)

    const editable = ref(false)
    
    watch(handleElementId, () => {
      if (handleElementId.value !== props.elementInfo.id) {
        if (editable.value) editable.value = false
      }
    })

    const text = computed<ShapeText>(() => {
      const defaultText: ShapeText = {
        content: '',
        defaultFontName: '마이크로소프트',
        defaultColor: '#000',
        align: 'middle',
      }
      if (!props.elementInfo.text) return defaultText

      return props.elementInfo.text
    })

    const updateText = (content: string) => {
      const _text = { ...text.value, content }
      slidesStore.updateElement({
        id: props.elementInfo.id, 
        props: { text: _text },
      })
      
      addHistorySnapshot()
    }

    const checkEmptyText = () => {
      if (!props.elementInfo.text) return

      const pureText = props.elementInfo.text.content.replaceAll(/<[^>]+>/g, '')
      if (!pureText) {
        slidesStore.removeElementProps({ id: props.elementInfo.id, propName: 'text' })
        addHistorySnapshot()
      }
    }

    return {
      shadowStyle,
      outlineWidth,
      outlineStyle,
      outlineColor,
      flipStyle,
      editable,
      text,
      handleSelectElement,
      updateText,
      checkEmptyText,
    }
  },
})
