import { storeToRefs } from 'pinia'
import { useSlidesStore } from '@/store'
import { PPTElement } from '@/types/slides'
import { ElementOrderCommands } from '@/types/edit'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

export default () => {
  const slidesStore = useSlidesStore()
  const { currentSlide } = storeToRefs(slidesStore)

  const { addHistorySnapshot } = useHistorySnapshot()

  /**
   * 조합 요소 수준 범위 가져오기
   * @param elementList 이 페이지의 모든 요소 목록
   * @param combineElementList 조합 요소 목록
   */
  const getCombineElementLevelRange = (elementList: PPTElement[], combineElementList: PPTElement[]) => {
    return {
      minLevel: elementList.findIndex(_element => _element.id === combineElementList[0].id),
      maxLevel: elementList.findIndex(_element => _element.id === combineElementList[combineElementList.length - 1].id),
    }
  }

  /**
   * 한층 위로 올리고 있다.
   * @param elementList 이 페이지의 모든 요소 목록
   * @param element 현재 작업 요소
   */
  const moveUpElement = (elementList: PPTElement[], element: PPTElement) => {
    const copyOfElementList: PPTElement[] = JSON.parse(JSON.stringify(elementList))

    // 조작되는 요소가 조합원일 경우 해당 조합의 모든 멤버를 함께 이동시켜야 합니다.
    if (element.groupId) {

      // 이 그룹의 모든 멤버와 모든 멤버의 계층 범위를 가져옵니다.
      const combineElementList = copyOfElementList.filter(_element => _element.groupId === element.groupId)
      const { minLevel, maxLevel } = getCombineElementLevelRange(elementList, combineElementList)

      // 이미 맨 위에 있어 더 이상 이동할 수 없습니다
      if (maxLevel === elementList.length - 1) return

      // 조합원 범위의 최대값을 사용하여 조합의 한 층 위의 요소를 가져옵니다. 그런 다음 조합 요소를 요소 목록에서 제거하고 제거된 요소 목록을 저장합니다.
      // 만약 상층 요소가 다른 조합에 있다면, 제거된 결합 요소를 상층 조합 위에 삽입합니다.
      // 만약 상위 요소가 그룹에 속하지 않는다면, 제거된 결합 요소를 상위 요소 위에 삽입합니다.
      const nextElement = copyOfElementList[maxLevel + 1]
      const movedElementList = copyOfElementList.splice(minLevel, combineElementList.length)

      if (nextElement.groupId) {
        const nextCombineElementList = copyOfElementList.filter(_element => _element.groupId === nextElement.groupId)
        copyOfElementList.splice(minLevel + nextCombineElementList.length, 0, ...movedElementList)
      }
      else copyOfElementList.splice(minLevel + 1, 0, ...movedElementList)
    }

    // 조작된 요소가 결합 요소의 구성원이 아닌 경우
    else {

      // 목록의 요소 계층 가져오기
      const level = elementList.findIndex(item => item.id === element.id)

      // 이미 맨 위에 있어 더 이상 이동할 수 없습니다
      if (level === elementList.length - 1) return

      // 컴포지트 위의 요소를 가져옵니다. 그리고 컴포지트 요소를 요소 목록에서 제거하고 제거된 요소 목록을 저장합니다.）
      const nextElement = copyOfElementList[level + 1]
      const movedElement = copyOfElementList.splice(level, 1)[0]

      // 조합원 범위의 최대값을 사용하여 조합의 한 층 위의 요소를 가져옵니다. 그런 다음 조합 요소를 요소 목록에서 제거하고 제거된 요소 목록을 저장합니다.
      // 만약 상층 요소가 다른 조합에 있다면, 제거된 결합 요소를 상층 조합 위에 삽입합니다.
      // 만약 상위 요소가 그룹에 속하지 않는다면, 제거된 결합 요소를 상위 요소 위에 삽입합니다.
      if (nextElement.groupId) {
        const combineElementList = copyOfElementList.filter(_element => _element.groupId === nextElement.groupId)
        copyOfElementList.splice(level + combineElementList.length, 0, movedElement)
      }
      else copyOfElementList.splice(level + 1, 0, movedElement)
    }

    return copyOfElementList
  }

  /**
   * 한 층 아래로 이동하면, 조작 방식이 위쪽으로 이동한다.
   * @param elementList 이 페이지의 모든 요소 목록
   * @param element 현재 작업 요소
   */
  const moveDownElement = (elementList: PPTElement[], element: PPTElement) => {
    const copyOfElementList: PPTElement[] = JSON.parse(JSON.stringify(elementList))

    if (element.groupId) {
      const combineElementList = copyOfElementList.filter(_element => _element.groupId === element.groupId)
      const { minLevel } = getCombineElementLevelRange(elementList, combineElementList)
      if (minLevel === 0) return

      const prevElement = copyOfElementList[minLevel - 1]
      const movedElementList = copyOfElementList.splice(minLevel, combineElementList.length)

      if (prevElement.groupId) {
        const prevCombineElementList = copyOfElementList.filter(_element => _element.groupId === prevElement.groupId)
        copyOfElementList.splice(minLevel - prevCombineElementList.length, 0, ...movedElementList)
      }
      else copyOfElementList.splice(minLevel - 1, 0, ...movedElementList)
    }

    else {
      const level = elementList.findIndex(item => item.id === element.id)
      if (level === 0) return

      const prevElement = copyOfElementList[level - 1]
      const movedElement = copyOfElementList.splice(level, 1)[0]

      if (prevElement.groupId) {
        const combineElementList = copyOfElementList.filter(_element => _element.groupId === prevElement.groupId)
        copyOfElementList.splice(level - combineElementList.length, 0, movedElement)
      }
      else copyOfElementList.splice(level - 1, 0, movedElement)
    }

    return copyOfElementList
  }

  /**
   * 꼭대기 층
   * @param elementList 이 페이지의 모든 요소 목록
   * @param element 현재 작업 요소
   */
  const moveTopElement = (elementList: PPTElement[], element: PPTElement) => {
    const copyOfElementList: PPTElement[] = JSON.parse(JSON.stringify(elementList))

    // 조작되는 요소가 조합원일 경우 해당 조합의 모든 멤버를 함께 이동시켜야 합니다.
    if (element.groupId) {

      // 이 그룹의 모든 멤버와 모든 멤버의 계층 범위를 가져옵니다.
      const combineElementList = copyOfElementList.filter(_element => _element.groupId === element.groupId)
      const { minLevel, maxLevel } = getCombineElementLevelRange(elementList, combineElementList)

      // 이미 맨 위에 있어 더 이상 이동할 수 없습니다
      if (maxLevel === elementList.length - 1) return null

      // 콤비네이션 요소를 요소 목록에서 제거하고 제거된 요소를 요소 목록의 맨 위에 추가합니다
      const movedElementList = copyOfElementList.splice(minLevel, combineElementList.length)
      copyOfElementList.push(...movedElementList)
    }

    // 조작된 요소가 결합 요소의 구성원이 아닌 경우
    else {

      // 목록의 요소 계층 가져오기
      const level = elementList.findIndex(item => item.id === element.id)

      // 이미 맨 위에 있어 더 이상 이동할 수 없습니다
      if (level === elementList.length - 1) return null

      // 콤비네이션 요소를 요소 목록에서 제거하고 제거된 요소를 요소 목록의 맨 아래에 추가합니다
      copyOfElementList.splice(level, 1)
      copyOfElementList.push(element)
    }

    return copyOfElementList
  }

  /**
   * 조작 방식과 저변을 마련해 놓았다. 머리 꼭대기
   * @param elementList 모든 원소 목록 페이지
   * @param element 현재 작업 요소
   */
  const moveBottomElement = (elementList: PPTElement[], element: PPTElement) => {
    const copyOfElementList: PPTElement[] = JSON.parse(JSON.stringify(elementList))

    if (element.groupId) {
      const combineElementList = copyOfElementList.filter(_element => _element.groupId === element.groupId)
      const { minLevel } = getCombineElementLevelRange(elementList, combineElementList)
      if (minLevel === 0) return

      const movedElementList = copyOfElementList.splice(minLevel, combineElementList.length)
      copyOfElementList.unshift(...movedElementList)
    }

    else {
      const level = elementList.findIndex(item => item.id === element.id)
      if (level === 0) return

      copyOfElementList.splice(level, 1)
      copyOfElementList.unshift(element)
    }

    return copyOfElementList
  }

  /**
   * 요소 계층 조정
   * @param element 계층 조정이 필요한 요소
   * @param command 조정 명령: 위로, 아래로, 위로, 아래로
   */
  const orderElement = (element: PPTElement, command: ElementOrderCommands) => {
    let newElementList
    
    if (command === ElementOrderCommands.UP) newElementList = moveUpElement(currentSlide.value.elements, element)
    else if (command === ElementOrderCommands.DOWN) newElementList = moveDownElement(currentSlide.value.elements, element)
    else if (command === ElementOrderCommands.TOP) newElementList = moveTopElement(currentSlide.value.elements, element)
    else if (command === ElementOrderCommands.BOTTOM) newElementList = moveBottomElement(currentSlide.value.elements, element)

    if (!newElementList) return

    slidesStore.updateSlide({ elements: newElementList })
    addHistorySnapshot()
  }

  return {
    orderElement,
  }
}