import { LinePoint } from '@/types/slides'


export interface LinePoolItem {
  path: string
  style: 'solid' | 'dashed'
  points: [LinePoint, LinePoint]
  isBroken?: boolean
  isCurve?: boolean
  isCubic?: boolean
}

interface PresetLine {
  type: string
  children: LinePoolItem[]
}
export const basicFields = [{
  type: 'input',
  icon: 'text-field',
  formItemFlag: true,
  options: {
    name: '',
    label: '',
    labelAlign: '',
    type: 'text',
    defaultValue: '',
    placeholder: '',
    columnWidth: '200px',
    size: '',
    labelWidth: null,
    labelHidden: false,
    readonly: false,
    disabled: false,
    hidden: false,
    clearable: true,
    showPassword: false,
    required: false,
    requiredHint: '',
    validation: '',
    validationHint: '',
    customClass: '',
    labelIconClass: null,
    labelIconPosition: 'rear',
    labelTooltip: null,
    minLength: null,
    maxLength: null,
    showWordLimit: false,
    prefixIcon: '',
    suffixIcon: '',
    appendButton: false,
    appendButtonDisabled: false,
    buttonIcon: 'el-icon-search',
    onCreated: '',
    onMounted: '',
    onInput: '',
    onChange: '',
    onFocus: '',
    onBlur: '',
    onValidate: '',
  },
}


]
export const Basic_List: PresetLine[] = [
  {
    type: '직선',
    children: [
      { path: 'M 0 0 L 20 20', style: 'solid', points: ['', ''] },

    ],
  },
  {
    type: '꺾은 선, 곡선',
    children: [
      { path: 'M 0 0 L 0 20 L 20 20', style: 'solid', points: ['', 'arrow'], isBroken: true },

    ],
  },
]