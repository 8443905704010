import { onMounted, onUnmounted, ref } from 'vue'
import { throttle } from 'lodash'
import { storeToRefs } from 'pinia'
import { useSlidesStore } from '@/store'
import { KEYS } from '@/configs/hotkey'
import { ANIMATION_CLASS_PREFIX } from '@/configs/animation'

import { message } from 'ant-design-vue'

export default () => {
  const slidesStore = useSlidesStore()
  const { slides, slideIndex, formatedAnimations } = storeToRefs(slidesStore)

  // 현재 페이지의 요소 애니메이션을 실행할 위치
  const animationIndex = ref(0)

  // 애니메이션 실행 상태
  const inAnimation = ref(false)

  // 재생된 페이지 최소 인덱스
  const playedSlidesMinIndex = ref(slideIndex.value)

  // 요소 애니메이션 실행
  const runAnimation = () => {
    // 애니메이션을 실행하고 있을 때 다른 애니메이션 시작 금지
    if (inAnimation.value) return

    const { animations, autoNext } = formatedAnimations.value[animationIndex.value]
    animationIndex.value += 1

    // 그림 실행 시작 태그
    inAnimation.value = true

    let endAnimationCount = 0

    // 이 위치의 모든 애니메이션을 차례로 실행합니다
    for (const animation of animations) {
      const elRef: HTMLElement | null = document.querySelector(`#screen-element-${animation.elId} [class^=base-element-]`)
      if (!elRef) {
        endAnimationCount += 1
        continue
      }

      const animationName = `${ANIMATION_CLASS_PREFIX}${animation.effect}`
      
      // 애니메이션을 실행하기 전에 애니메이션 상태를 지웁니다(있을 경우).
      elRef.style.removeProperty('--animate-duration')
      for (const classname of elRef.classList) {
        if (classname.indexOf(ANIMATION_CLASS_PREFIX) !== -1) elRef.classList.remove(classname, `${ANIMATION_CLASS_PREFIX}animated`)
      }
      
      // 동화를 집행하다.
      elRef.style.setProperty('--animate-duration', `${animation.duration}ms`)
      elRef.classList.add(animationName, `${ANIMATION_CLASS_PREFIX}animated`)

      // 애니메이션 실행 종료, '퇴장' 이외의 애니메이션 상태 지우기
      const handleAnimationEnd = () => {
        if (animation.type !== 'out') {
          elRef.style.removeProperty('--animate-duration')
          elRef.classList.remove(animationName, `${ANIMATION_CLASS_PREFIX}animated`)
        }

        // 이 위치의 모든 애니메이션이 끝났다고 판단한 후, 애니메이션이 끝났다고 표시하고 (필요한 경우) 아래로 계속 실행하려고 시도합니다.
        endAnimationCount += 1
        if (endAnimationCount === animations.length) {
          inAnimation.value = false
          if (autoNext) runAnimation()
        }
      }
      elRef.addEventListener('animationend', handleAnimationEnd, { once: true })
    }
  }

  // 원소가 철폐되기 전 애니메이션으로 이동 외에 색인이 필요한 상태다. 지우기 애니메이션
  const revokeAnimation = () => {
    animationIndex.value -= 1
    const { animations } = formatedAnimations.value[animationIndex.value]

    for (const animation of animations) {
      const elRef: HTMLElement | null = document.querySelector(`#screen-element-${animation.elId} [class^=base-element-]`)
      if (!elRef) continue
      
      elRef.style.removeProperty('--animate-duration')
      for (const classname of elRef.classList) {
        if (classname.indexOf(ANIMATION_CLASS_PREFIX) !== -1) elRef.classList.remove(classname, `${ANIMATION_CLASS_PREFIX}animated`)
      }
    }

    // 실행 취소 시 위치가 강조된 애니메이션만 있는 경우 실행 취소 한 번 더 실행
    if (animations.every(item => item.type === 'attention')) execPrev()
  }

  // 자동 재생 끄기
  const autoPlayTimer = ref(0)
  const closeAutoPlay = () => {
    if (autoPlayTimer.value) {
      clearInterval(autoPlayTimer.value)
      autoPlayTimer.value = 0
    }
  }
  onUnmounted(closeAutoPlay)

  const throttleMassage = throttle(function(msg) {
    message.success(msg)
  }, 1000, { leading: true, trailing: false })

  // 위로/ 아래로 재생
  // 요소 애니메이션 보기시, 동화상 재생을 우선적으로 수행하고 애니메이션이 없으면 페이지 넘김을 수행합니다.
  // 재생 중...애니메이션이 실행되기 전의 상태로 되돌리기 때문에 애니메이션을 거꾸로 재생할 필요가 없습니다
  // 이전 페이지로 돌아가기만약 이 페이지가 재생되지 않은 경우(동영상 상태가 존재하지 않는다는 의미), 애니메이션 인덱스가 필요합니다.최소값(초기상태), 그렇지 않으면 최대값(최종상태)
  const execPrev = () => {
    if (formatedAnimations.value.length && animationIndex.value > 0) {
      revokeAnimation()
    }
    else if (slideIndex.value > 0) {
      slidesStore.updateSlideIndex(slideIndex.value - 1)
      if (slideIndex.value < playedSlidesMinIndex.value) {
        animationIndex.value = 0
        playedSlidesMinIndex.value = slideIndex.value
      }
      else animationIndex.value = formatedAnimations.value.length
      inAnimation.value = false
    }
    else {
      throttleMassage('벌써 1페이지예요')
      inAnimation.value = false
    }
  }
  const execNext = () => {
    if (formatedAnimations.value.length && animationIndex.value < formatedAnimations.value.length) {
      runAnimation()
    }
    else if (slideIndex.value < slides.value.length - 1) {
      slidesStore.updateSlideIndex(slideIndex.value + 1)
      animationIndex.value = 0
      inAnimation.value = false
    }
    else {
      throttleMassage('벌써 마지막 페이지입니다')
      closeAutoPlay()
      inAnimation.value = false
    }
  }

  // 자동 재생
  const autoPlay = () => {
    closeAutoPlay()
    message.success('자동 재생시작')
    autoPlayTimer.value = setInterval(execNext, 2500)
  }

  // 마우스 스크롤 페이지 넘기기
  const mousewheelListener = throttle(function(e: WheelEvent) {
    if (e.deltaY < 0) execPrev()
    else if (e.deltaY > 0) execNext()
  }, 500, { leading: true, trailing: false })

  // 터치스크린 위아래로 페이지 넘기기
  const touchInfo = ref<{ x: number; y: number; } | null>(null)

  const touchStartListener = (e: TouchEvent) => {
    touchInfo.value = {
      x: e.changedTouches[0].pageX,
      y: e.changedTouches[0].pageY,
    }
  }
  const touchEndListener = (e: TouchEvent) => {
    if (!touchInfo.value) return

    const offsetX = Math.abs(touchInfo.value.x - e.changedTouches[0].pageX)
    const offsetY = e.changedTouches[0].pageY - touchInfo.value.y

    if ( Math.abs(offsetY) > offsetX && Math.abs(offsetY) > 50 ) {
      touchInfo.value = null

      if (offsetY > 0) execPrev()
      else execNext()
    }
  }

  // 단축키 페이지 넘기기
  const keydownListener = (e: KeyboardEvent) => {
    const key = e.key.toUpperCase()

    if (key === KEYS.UP || key === KEYS.LEFT || key === KEYS.PAGEUP) execPrev()
    else if (
      key === KEYS.DOWN || 
      key === KEYS.RIGHT ||
      key === KEYS.SPACE || 
      key === KEYS.ENTER ||
      key === KEYS.PAGEDOWN
    ) execNext()
  }

  onMounted(() => document.addEventListener('keydown', keydownListener))
  onUnmounted(() => document.removeEventListener('keydown', keydownListener))

  // 이전/ 이전 슬라이드로 전환 (요소 무시 입장 애니메이션)
  const turnPrevSlide = () => {
    slidesStore.updateSlideIndex(slideIndex.value - 1)
    animationIndex.value = 0
  }
  const turnNextSlide = () => {
    slidesStore.updateSlideIndex(slideIndex.value + 1)
    animationIndex.value = 0
  }

  // 슬라이드를 지정한 페이지로 전환합니다
  const turnSlideToIndex = (index: number) => {
    slidesStore.updateSlideIndex(index)
    animationIndex.value = 0
  }
  const turnSlideToId = (id: string) => {
    const index = slides.value.findIndex(slide => slide.id === id)
    if (index !== -1) {
      slidesStore.updateSlideIndex(index)
      animationIndex.value = 0
    }
  }

  return {
    autoPlayTimer,
    autoPlay,
    closeAutoPlay,
    mousewheelListener,
    touchStartListener,
    touchEndListener,
    turnPrevSlide,
    turnNextSlide,
    turnSlideToIndex,
    turnSlideToId,
    execPrev,
    execNext,
    animationIndex,
  }
}