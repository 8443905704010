import Dexie from 'dexie'
import { databaseId } from '@/store/main'
import { Slide } from '@/types/slides'
import { LOCALSTORAGE_KEY_DISCARDED_DB } from '@/configs/storage'

export interface Snapshot {
  index: number
  slides: Slide[]
}

const databaseNamePrefix = 'Bitist'

// 만료/ 만료 데이터베이스 삭제
// 응용 프로그램이 종료될 때 (브라우저를 닫거나 새로 고치면) 데이터베이스 ID가 localStorage에 기록되며, 이 ID가 가리키는 데이터베이스가 비활성화되었음을 나타냅니다.
// 응용 프로그램을 초기화할 때 현재 모든 데이터베이스를 검사하면 기록되지 않은 데이터베이스를 삭제합니다
// 또한 초기화 시간으로부터 12시간 이상 떨어진 데이터베이스도 삭제(원인 이외의 제대로 삭제되지 않은 라이브러리의 발생을 방지하기 위함)
export const deleteDiscardedDB = async () => {
  const now = new Date().getTime()

  const localStorageDiscardedDB = localStorage.getItem(LOCALSTORAGE_KEY_DISCARDED_DB)
  const localStorageDiscardedDBList: string[] = localStorageDiscardedDB ? JSON.parse(localStorageDiscardedDB) : []

  const databaseNames = await Dexie.getDatabaseNames()
  const discardedDBNames = databaseNames.filter(name => {
    if (name.indexOf(databaseNamePrefix) === -1) return false
    
    const [prefix, id, time] = name.split('_')
    if (prefix !== databaseNamePrefix || !id || !time) return true
    if (localStorageDiscardedDBList.includes(id)) return true
    if (now - (+time) >= 1000 * 60 * 60 * 12) return true

    return false
  })

  for (const name of discardedDBNames) Dexie.delete(name)
  localStorage.removeItem(LOCALSTORAGE_KEY_DISCARDED_DB)
}

class BitistDB extends Dexie {
  public snapshots: Dexie.Table<Snapshot, number>

  public constructor() {
    super(`${databaseNamePrefix}_${databaseId}_${new Date().getTime()}`)
    this.version(1).stores({
      snapshots: '++id'
    })
    this.snapshots = this.table('snapshots')
  }
}

export const db = new BitistDB()