import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1adba489"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "element-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconVolumeNotice = _resolveComponent("IconVolumeNotice")!
  const _component_AudioPlayer = _resolveComponent("AudioPlayer")!

  return (_openBlock(), _createElementBlock("div", {
    class: "screen-element-audio",
    style: _normalizeStyle({
      top: _ctx.elementInfo.top + 'px',
      left: _ctx.elementInfo.left + 'px',
      width: _ctx.elementInfo.width + 'px',
      height: _ctx.elementInfo.height + 'px',
    })
  }, [
    _createElementVNode("div", {
      class: "rotate-wrapper",
      style: _normalizeStyle({ transform: `rotate(${_ctx.elementInfo.rotate}deg)` })
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_IconVolumeNotice, {
          class: "audio-icon",
          style: _normalizeStyle({
            fontSize: _ctx.audioIconSize,
            color: _ctx.elementInfo.color,
          }),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggle()))
        }, null, 8, ["style"]),
        (_ctx.inCurrentSlide)
          ? (_openBlock(), _createBlock(_component_AudioPlayer, {
              key: 0,
              class: "audio-player",
              ref: "audioPlayerRef",
              style: _normalizeStyle({ ..._ctx.audioPlayerPosition }),
              src: _ctx.elementInfo.src,
              loop: _ctx.elementInfo.loop,
              autoplay: _ctx.elementInfo.autoplay,
              scale: _ctx.scale
            }, null, 8, ["style", "src", "loop", "autoplay", "scale"]))
          : _createCommentVNode("", true)
      ])
    ], 4)
  ], 4))
}