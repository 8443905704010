import { IBarChartOptions, ILineChartOptions, IPieChartOptions } from 'chartist'

export const enum ShapePathFormulasKeys {
  ROUND_RECT = 'roundRect',
  ROUND_RECT_DIAGONAL = 'roundRectDiagonal',
  ROUND_RECT_SINGLE = 'roundRectSingle',
  ROUND_RECT_SAMESIDE = 'roundRectSameSide',
  CUT_RECT_DIAGONAL = 'cutRectDiagonal',
  CUT_RECT_SINGLE = 'cutRectSingle',
  CUT_RECT_SAMESIDE = 'cutRectSameSide',
  MESSAGE = 'message',
  ROUND_MESSAGE = 'roundMessage',
  L = 'L',
  RING_RECT = 'ringRect',
  PLUS = 'plus',
}

export const enum ElementTypes {
  TEXT = 'text',
  IMAGE = 'image',
  SHAPE = 'shape',
  LINE = 'line',
  CHART = 'chart',
  TABLE = 'table',
  LATEX = 'latex',
  VIDEO = 'video',
  AUDIO = 'audio',
}

/**
 * 원소 그림자
 *
 * h: 수평 오프셋
 *
 * v: 수직 오프셋
 *
 * blur: 흐릿한 거리도
 *
 * color: 그림자색깔
 */
export interface PPTElementShadow {
  h: number
  v: number
  blur: number
  color: string
}

/**
 * 요소 경계선
 *
 * style?: 엣지상자 스타일 (실선 또는 점선)
 *
 * width?: 엣지테두리 너비
 *
 * color?: 가장자리액자색
 */
export interface PPTElementOutline {
  style?: 'dashed' | 'solid'
  width?: number
  color?: string
}

/**
 * 요소 하이퍼링크
 *
 * type: 링크 형식(웹페이지, 슬라이드 페이지)
 *
 * target: 대상주소(페이지 링크, 슬라이드 페이지 ID)
 */
export interface PPTElementLink {
  type: 'web' | 'slide'
  target: string
}


/**
 * 요소 일반 속성
 *
 * id: 요소 ID
 *
 * left: 요소 수평 방향위치(캔버스 왼쪽)
 *
 * top: 요소 수직 방향위치 (캔버스 맨 위까지)
 *
 * lock?: 요소 잠금
 *
 * 그룹 ID?: 그룹합ID(같은 그룹ID를 가진 요소)같은 컴포넌트의 멤버)
 *
 * width: 요소 폭
 *
 * height: 요소 높음도
 *
 * rotate: 회전각도
 *
 * 링크?: 하이퍼링크
 */
interface PPTBaseElement {
  id: string
  left: number
  top: number
  lock?: boolean
  groupId?: string
  width: number
  height: number
  rotate: number
  link?: PPTElementLink
}


/**
 * 텍스트 요소
 *
 * type: 요소 클래스유형(text)
 *
 * * content: 텍스트 내용(HTML 문자열)
 *
 * * defaultFontName: 기본 글꼴 (텍스트에 있는 HTML)L 인라인 스타일 덮어쓰기)
 *
 * * defaultColor: 기본 색상 (텍스트 내용에서 HTML로 연결됨)스타일 덮어쓰기)
 *
 * * outline?: 테두리
 *
 * fill?: 충전색
 *
 * * lineHeight?: 행 높이(배), 기본 1.5
 *
 * * wordSpace?: 글자 간격, 기본값 0
 *
 * * opacity?: 불투명도, 기본값 1
 *
 * * shadow?: 그림자
 *
 * * textIndent?: 단락 첫 줄 들여쓰기
 *
 * * paragraphSpace?: 세그먼트 간격, 기본 5px
 */
export interface PPTTextElement extends PPTBaseElement {
  type: 'text'
  content: string
  defaultFontName: string
  defaultColor: string
  outline?: PPTElementOutline
  fill?: string
  lineHeight?: number
  wordSpace?: number
  opacity?: number
  shadow?: PPTElementShadow
  textIndent?: number
  paragraphSpace?: number
}


/**
 * 그림 뒤집기, 모양 뒤집기
 *
 * 플립H?: 수평 플립
 *
 * 플립브이?: 수직으로 뒤집기
 */
export interface ImageOrShapeFlip {
  flipH?: boolean
  flipV?: boolean
}

/**
 * 이미지 필터
 *
 * * https://developer.mozilla.org/zh-CN/docs/Web/CSS/filter
 *
 * * 'blur'?: 흐림, 기본 0(px)
 *
 * * 'brightness'?: 밝기, 기본 100(%)
 *
 * * 'contrast'?: 대비, 기본 100 (%)
 *
 * * 'grayscale'?: 그레이스케일, 기본 0(%)
 *
 * * 'saturate'?: 채도, 기본 100 (%)
 *
 * * 'hue-rotate'?: 색상 회전, 기본값 0 (deg)
 *
 * * 'opacity'?: 불투명도, 기본 100 (%)
 */
export interface ImageElementFilters {
  'blur'?: string
  'brightness'?: string
  'contrast'?: string
  'grayscale'?: string
  'saturate'?: string
  'hue-rotate'?: string
  'opacity'?: string
}

export type ImageClipDataRange = [[number, number], [number, number]]

/**
 * 사진 커팅
 *
 * range: 커팅예: [10, 10], [90, 90]] 는 원본 그림을 왼쪽 상단 모서리에서 10%, 10%에서 90%, 90%까지 잘라낸 범위를 나타냅니다.
 *
 * shape: 커팅모양, configs/imageClip.tsCLIPPATHS 참조
 */
export interface ImageElementClip {
  range: ImageClipDataRange
  shape: string
}

/**
 * 이미지 요소
 *
 * type: 요소 클래스유형(image)
 *
 * * fixedRatio: 그림 가로세로 비율 고정
 *
 * src: 그림 주소
 *
 * * outline?: 테두리
 *
 * * filters?: 이미지 필터
 *
 * 클립?: 커팅정보
 *
 * flipH?: 물플립 플립
 *
 * 플립브이?: 늘어짐휙휙 돌다
 *
 * * shadow?: 그림자
 */
export interface PPTImageElement extends PPTBaseElement {
  type: 'image'
  fixedRatio: boolean
  src: string
  outline?: PPTElementOutline
  filters?: ImageElementFilters
  clip?: ImageElementClip
  flipH?: boolean
  flipV?: boolean
  shadow?: PPTElementShadow
}


/**
 * 형상 그라데이션
 *
 * type: 그라데이션 클래스타입(레이디얼,리니어)
 *
 * color: 그라데이션색깔
 *
 * rotate: 점가변 각도 (선형 그라데이션)
 */
export interface ShapeGradient {
  type: 'linear' | 'radial'
  color: [string, string]
  rotate: number
}

/**
 * 도형 안의 텍스트
 *
 * content: 문본 내용(HTML 문자열)
 *
 * * defaultFontName: 기본 글꼴 (텍스트 내용에서 HTML 인라인 스타일로 덮어쓰기)）
 *
 * * defaultColor: 기본 색상 (텍스트 내용에서 HTML 인라인 스타일로 덮어쓰기)
 *
 * align: 텍스트 쌍정방향 (수직방향)
 */
export interface ShapeText {
  content: string
  defaultFontName: string
  defaultColor: string
  align: 'top' | 'middle' | 'bottom'
}

/**
 * 형상 원소
 *
 * type: 요소 클래스타입(shape)
 *
 * * viewBox: SVG의 ViewBox 속성 (예: [1000, 1])000] '00 1000 1000'을 뜻해요
 *
 * path: 모양 경로지름, SVG path의 d 속성
 *
 * * fixedRatio: 고정 셰이프의 가로 세로 비율
 *
 * fill: 채우기,그라데이션이 없을 때 유효함
 *
 * * gradient?: 그림% 1개의 캡션을 편집했습니다.
 *
 * * outline?: 테두리
 *
 * * opacity?: 불투명도
 *
 * flipH?: 물플립 플립
 *
 * 플립브이?: 늘어짐휙휙 돌다
 *
 * * shadow?: 그림자
 *
 * * special?: 특수 도형 (경로 사용 등 해석하기 어려운 도형을 표시합니다) LQCA 이외의 유형으로 내보내기 후 그림 형식으로 바뀝니다)
 *
 * text?: 모양내부 텍스트
 *
 * * pathFormula?: 형상 경로 계산 공식
 * 일반적인 경우, 쉐입의크기가 변할 때 viewBox 기반 가로 크기만 조정viewBox 자체와 path는 변하지 않습니다.
 * 근데 약간 모양도 희망도 있고좀 더 정확한 위치 제어가 가능해지면 경로를 제공해야 한다.확대/ 축소할 때 viewBox를 업데이트하고 path를 다시 계산하여 도형을 다시 그리는 계산식
 */
export interface PPTShapeElement extends PPTBaseElement {
  type: 'shape'
  viewBox: [number, number]
  path: string
  fixedRatio: boolean
  fill: string
  gradient?: ShapeGradient
  outline?: PPTElementOutline
  opacity?: number
  flipH?: boolean
  flipV?: boolean
  shadow?: PPTElementShadow
  special?: boolean
  text?: ShapeText
  pathFormula?: ShapePathFormulasKeys
}


export type LinePoint = '' | 'arrow' | 'dot'

/**
 * 선 요소
 *
 * type: 요소 클래스유형(line)
 *
 * start: 시작점위치 ([x, y])
 *
 * end: 끝 위치（[x, y]）
 *
 * style: 라인스타일(실선,점선)
 *
 * color: 라인색깔
 *
 * points: 끝점 스타일 ([시작점 스타일, 끝점 스타일], 옵션: 없음, 화살표·도트)
 *
 * * shadow?: 그림자
 *
 * * broken?: 접힌 선 제어점 위치 ([x, y])
 *
 * 커브?: 둘서브 커브 제어점 위치 ([x, y])
 *
 * 커브?: 셋서브 커브 제어점 위치([x1, y1], [x2, y2]]）
 */
export interface PPTLineElement extends Omit<PPTBaseElement, 'height' | 'rotate'> {
  type: 'line'
  start: [number, number]
  end: [number, number]
  style: 'solid' | 'dashed'
  color: string
  points: [LinePoint, LinePoint]
  shadow?: PPTElementShadow
  broken?: [number, number]
  curve?: [number, number]
  cubic?: [[number, number], [number, number]]
}


export type PresetChartType = 'bar' | 'horizontalBar' | 'line' | 'area' | 'scatter' | 'pie' | 'ring'
export type ChartType = 'bar' | 'line' | 'pie'
export type ChartOptions = ILineChartOptions & IBarChartOptions & IPieChartOptions
export interface ChartData {
  labels: string[]
  legends: string[]
  series: number[][]
}

/**
 * 그래프 요소
 *
 * type: 요소 클래스형(chart)
 *
 * fill?: 충전색
 *
 * * chartType: 그래프 기초 유형 (bar/line/pie), 모든 것도표 유형은 모두 이 세 가지 기본 유형에서 파생된 것이다
 *
 * data: 그래프 수의거하다
 *
 * * options?: 그래프 설정 항목
 *
 * * outline?: 테두리
 *
 * * themeColor: 테마색
 *
 * * gridColor?: 그리드 & 좌표 색상
 *
 * * legend?: 범례/위치
 */
export interface PPTChartElement extends PPTBaseElement {
  type: 'chart'
  fill?: string
  chartType: ChartType
  data: ChartData
  options?: ChartOptions
  outline?: PPTElementOutline
  themeColor: string[]
  gridColor?: string
  legend?: '' | 'top' | 'bottom'
}


/**
 * 표 셀 스타일
 *
 * bold?: 굵게 하기
 *
 * em?: 이탤릭체
 *
 * * underline?: 밑줄
 *
 * * strikethrough?: 선 삭제
 *
 * color?: 글꼴 색상
 *
 * * backcolor?: 충전색
 *
 * fontsize?: 글자체격
 *
 * fontname?: 글자체
 *
 * align?: 정렬 방식
 */
export interface TableCellStyle {
  bold?: boolean
  em?: boolean
  underline?: boolean
  strikethrough?: boolean
  color?: string
  backcolor?: string
  fontsize?: string
  fontname?: string
  align?: 'left' | 'center' | 'right'
}


/**
 * 표 셀
 *
 * id: 셀 ID
 *
 * colspan: 합병렬수
 *
 * rowspan: 합병렬수
 *
 * text: 텍스트 내용
 *
 * style?: 단위격식
 */
export interface TableCell {
  id: string
  colspan: number
  rowspan: number
  text: string
  style?: TableCellStyle
}

/**
 * 표제
 *
 * color: 테마색
 *
 * * rowHeader: 제목줄
 *
 * * rowFooter: 본점
 *
 * * colHeader: 제1열
 *
 * * colFooter: 맨 뒷줄
 */
export interface TableTheme {
  color: string
  rowHeader: boolean
  rowFooter: boolean
  colHeader: boolean
  colFooter: boolean
}

/**
 * 표 요소
 *
 * type: 요소 클래스유형(table)
 *
 * * outline: 테두리
 *
 * theme?: 주제목
 *
 * * colWidths: 열 너비 배열, 예를 들어 [30, 50, 20]은 세 열 너비를 나타냅니다.도수는 각각 30%, 50%, 20%
 *
 * data: 표 수의거하다
 */
export interface PPTTableElement extends PPTBaseElement {
  type: 'table'
  outline: PPTElementOutline
  theme?: TableTheme
  colWidths: number[]
  data: TableCell[][]
}


/**
 * LaTeX 요소 (식)
 *
 * type: 요소 유형 (latex)）
 *
 * latex: latex 코드
 *
 * * path: svg path
 *
 * color: 색상
 *
 * strokeWidth: 경로 폭도
 *
 * viewBox: SVG의 viewBox 속성
 *
 * fixedRatio: 모양 고정넓고 높은 비율
 */
export interface PPTLatexElement extends PPTBaseElement {
  type: 'latex'
  latex: string
  path: string
  color: string
  strokeWidth: number
  viewBox: [number, number]
  fixedRatio: boolean
}

/**
 * 비디오 요소
 *
 * type: 요소 클래스유형 (video)
 *
 * src: 비디오 주소
 *
 * poster: 미리표지를 보다
 */
export interface PPTVideoElement extends PPTBaseElement {
  type: 'video'
  src: string
  poster?: string
}

/**
 * 오디오 요소
 *
 * type: 요소 클래스유형(audio)
 *
 * * fixedRatio: 아이콘 가로 세로 크기 조정
 *
 * color: 아이콘색깔
 *
 * loop: 재생놓다.
 *
 * * autoplay: 자동 재생
 *
 * src: 오디오 주소
 */
export interface PPTAudioElement extends PPTBaseElement {
  type: 'audio'
  fixedRatio: boolean
  color: string,
  loop: boolean,
  autoplay: boolean,
  src: string
}


export type PPTElement = PPTTextElement | PPTImageElement | PPTShapeElement | PPTLineElement | PPTChartElement | PPTTableElement | PPTLatexElement | PPTVideoElement | PPTAudioElement


/**
 * 요소 애니메이션
 *
 * id: 애니메이션 id
 *
 * elId: 원소 ID
 *
 * effect: 동작그림 효과
 *
 * type: 애니메이션유형(입장, 퇴장, 강조)
 *
 * * duration: 애니메이션 지속 시간
 *
 * * trigger: 애니메이션 시작 (click - 단일)탭 타임, Meantime - 이전 동작과 함께그림 그리기, Auto - 애니메이션 시작 후)
 */
export interface PPTAnimation {
  id: string
  elId: string
  effect: string
  type: 'in' | 'out' | 'attention'
  duration: number
  trigger: 'click' | 'meantime' | 'auto'
}

/**
 * 슬라이드 배경
 *
 * type: 배경 형식(순색, 이미지, 그라데이션)
 *
 * color?: 배경색상 (순색)
 *
 * image?: 그림주소(그림)
 *
 * * imageSize?: 그림 채우기 방식
 *
 * * gradientType?: 그라데이션 타입(선형, 방사형)
 *
 * * gradientColor?: 그라데이션 컬러
 *
 * * gradientRotate?: 그라데이션 각도(선형)
 */
export interface SlideBackground {
  type: 'solid' | 'image' | 'gradient'
  color?: string
  image?: string
  imageSize?: 'cover' | 'contain' | 'repeat'
  gradientType?: 'linear' | 'radial'
  gradientColor?: [string, string]
  gradientRotate?: number
}


export type TurningMode = 'no' | 'fade' | 'slideX' | 'slideY'

/**
 * 슬라이드 페이지
 *
 * id: 페이지 ID
 *
 * * elements: 원소 집합
 *
 * 리마크?: 비붓다
 *
 * * background?: 페이지 배경
 *
 * * animations?: 요소 애니메이션 모음
 *
 * * turningMode?: 페이지 넘기기
 */
export interface Slide {
  id: string
  elements: PPTElement[]
  remark?: string
  background?: SlideBackground
  animations?: PPTAnimation[]
  turningMode?: TurningMode
  content_id?: string
  main_theme?: MainTheme
}

/**
 * 슬라이드 테마
 *
 * * backgroundColor: 페이지 배경색
 *
 * * themeColor: 테마 색상, 기본 생성에 사용되는 셰이프 색상 등
 *
 * * fontColor: 글꼴 색상
 *
 * * fontName: 글꼴
 */
export interface SlideTheme {
  backgroundColor: string
  themeColor: string
  fontColor: string
  fontName: string
}


export interface MainTheme {
  headerColor1: string
  headerColor2: string
  bodyColor1: string
  bodyColor2: string
}
