
import { computed, defineComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore } from '@/store'
import { DialogForExportTypes } from '@/types/export'

import ExportImage from './ExportImage.vue'
import ExportJSON from './ExportJSON.vue'
import ExportPDF from './ExportPDF.vue'
import ExportPPTX from './ExportPPTX.vue'
import ExportSpecificFile from './ExportSpecificFile.vue'

interface TabItem {
  key: DialogForExportTypes
  label: string
}

export default defineComponent({
  name: 'export-dialog',
  setup() {
    const mainStore = useMainStore()
    const { dialogForExport } = storeToRefs(mainStore)

    const setDialogForExport = mainStore.setDialogForExport

    const tabs: TabItem[] = [
      { key: 'bitist', label: 'bitist 파일 내보내기' },
      { key: 'pptx', label: '추출 PPTX' },
      { key: 'image', label: '그림 내보내기' },
      { key: 'json', label: '추출 JSON' },
      { key: 'pdf', label: '인쇄/ 내보내기 PDF' },
    ]

    const currentDialogComponent = computed(() => {
      const dialogMap = {
        'image': ExportImage,
        'json': ExportJSON,
        'pdf': ExportPDF,
        'pptx': ExportPPTX,
        'bitist': ExportSpecificFile,
      }
      return dialogMap[dialogForExport.value] || null
    })

    return {
      currentDialogComponent,
      tabs,
      dialogForExport,
      setDialogForExport,
    }
  },
})
