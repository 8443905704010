
import { computed, defineComponent, ref, watch } from 'vue'
import { nanoid } from 'nanoid'
import { storeToRefs } from 'pinia'
import { useMainStore, useSlidesStore } from '@/store'
import { PPTAnimation } from '@/types/slides'
import { 
  ENTER_ANIMATIONS,
  EXIT_ANIMATIONS,
  ATTENTION_ANIMATIONS,
  ANIMATION_DEFAULT_DURATION,
  ANIMATION_DEFAULT_TRIGGER,
  ANIMATION_CLASS_PREFIX,
} from '@/configs/animation'
import { ELEMENT_TYPE_ZH } from '@/configs/element'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

import Draggable from 'vuedraggable'

const animationEffects: { [key: string]: string } = {}
for (const effect of ENTER_ANIMATIONS) {
  for (const animation of effect.children) {
    animationEffects[animation.value] = animation.name
  }
}
for (const effect of EXIT_ANIMATIONS) {
  for (const animation of effect.children) {
    animationEffects[animation.value] = animation.name
  }
}
for (const effect of ATTENTION_ANIMATIONS) {
  for (const animation of effect.children) {
    animationEffects[animation.value] = animation.name
  }
}

type AnimationType = 'in' | 'out' | 'attention'
interface TabItem {
  key: AnimationType
  label: string
}

const animationTypes: AnimationType[] = ['in', 'out', 'attention']

export default defineComponent({
  name: 'element-animation-panel',
  components: {
    Draggable,
  },
  setup() {
    const slidesStore = useSlidesStore()
    const { handleElement, handleElementId } = storeToRefs(useMainStore())
    const { currentSlide, formatedAnimations, currentSlideAnimations } = storeToRefs(slidesStore)

    const tabs: TabItem[] = [
      { key: 'in', label: '입장' },
      { key: 'out', label: '퇴장' },
      { key: 'attention', label: '강조' },
    ]
    const activeTab = ref('in')

    watch(() => handleElementId.value, () => {
      animationPoolVisible.value = false
    })

    const hoverPreviewAnimation = ref('')
    const animationPoolVisible = ref(false)

    const { addHistorySnapshot } = useHistorySnapshot()

    // 현재 페이지의 애니메이션 목록
    const animationSequence = computed(() => {
      const animationSequence = []
      for (let i = 0; i < formatedAnimations.value.length; i++) {
        const item = formatedAnimations.value[i]
        for (let j = 0; j < item.animations.length; j++) {
          const animation = item.animations[j]
          const el = currentSlide.value.elements.find(el => el.id === animation.elId)
          if (!el) continue

          const elType = ELEMENT_TYPE_ZH[el.type]
          const animationEffect = animationEffects[animation.effect]
          animationSequence.push({
            ...animation,
            index: j === 0 ? i + 1 : '',
            elType,
            animationEffect,
          })
        }
      }
      return animationSequence
    })

    // 현재 선택된 요소의 입장 애니메이션 정보
    const handleElementAnimation = computed(() => {
      const animations = currentSlideAnimations.value
      const animation = animations.filter(item => item.elId === handleElementId.value)
      return animation || []
    })

    // 요소 애니메이션 삭제
    const deleteAnimation = (id: string) => {
      const animations = currentSlideAnimations.value.filter(item => item.id !== id)
      slidesStore.updateSlide({ animations })
      addHistorySnapshot()
    }

    // 드래그하여 애니메이션 순서를 수정한 후 데이터를 동기화합니다
    const handleDragEnd = (eventData: { newIndex: number; oldIndex: number }) => {
      const { newIndex, oldIndex } = eventData
      if (oldIndex === newIndex) return

      const animations: PPTAnimation[] = JSON.parse(JSON.stringify(currentSlideAnimations.value))
      const animation = animations[oldIndex]
      animations.splice(oldIndex, 1)
      animations.splice(newIndex, 0, animation)
      
      slidesStore.updateSlide({ animations })
      addHistorySnapshot()
    }

    // 그림 미리 보기 실행
    const runAnimation = (elId: string, effect: string, duration: number) => {
      const elRef = document.querySelector(`#editable-element-${elId} [class^=editable-element-]`)
      if (elRef) {
        const animationName = `${ANIMATION_CLASS_PREFIX}${effect}`
        document.documentElement.style.setProperty('--animate-duration', `${duration}ms`)
        elRef.classList.add(`${ANIMATION_CLASS_PREFIX}animated`, animationName)

        const handleAnimationEnd = () => {
          document.documentElement.style.removeProperty('--animate-duration')
          elRef.classList.remove(`${ANIMATION_CLASS_PREFIX}animated`, animationName)
        }
        elRef.addEventListener('animationend', handleAnimationEnd, { once: true })
      }
    }

    // 요소 애니메이션 기간 수정
    const updateElementAnimationDuration = (id: string, duration: number) => {
      if (duration < 100 || duration > 5000) return

      const animations = currentSlideAnimations.value.map(item => {
        if (item.id === id) return { ...item, duration }
        return item
      })
      slidesStore.updateSlide({ animations })
      addHistorySnapshot()
    }

    // 트리거 방식 수정
    const updateElementAnimationTrigger = (id: string, trigger: 'click' | 'meantime' | 'auto') => {
      const animations = currentSlideAnimations.value.map(item => {
        if (item.id === id) return { ...item, trigger }
        return item
      })
      slidesStore.updateSlide({ animations })
      addHistorySnapshot()
    }

    // 요소 애니메이션을 수정하고 미리 보기를 수행합니다.
    const updateElementAnimation = (type: AnimationType, effect: string) => {
      const animations = currentSlideAnimations.value.map(item => {
        if (item.id === handleAnimationId.value) return { ...item, type, effect }
        return item
      })
      slidesStore.updateSlide({ animations })
      animationPoolVisible.value = false
      addHistorySnapshot()

      const animationItem = currentSlideAnimations.value.find(item => item.elId === handleElementId.value)
      const duration = animationItem?.duration || ANIMATION_DEFAULT_DURATION

      runAnimation(handleElementId.value, effect, duration)
    }

    const handleAnimationId = ref('')
    // 요소 애니메이션을 추가하고 미리 보기를 수행합니다.
    const addAnimation = (type: AnimationType, effect: string) => {
      if (handleAnimationId.value) {
        updateElementAnimation(type, effect)
        return
      }

      const animations: PPTAnimation[] = JSON.parse(JSON.stringify(currentSlideAnimations.value))
      animations.push({
        id: nanoid(10),
        elId: handleElementId.value,
        type,
        effect,
        duration: ANIMATION_DEFAULT_DURATION,
        trigger: ANIMATION_DEFAULT_TRIGGER,
      })
      slidesStore.updateSlide({ animations })
      animationPoolVisible.value = false
      addHistorySnapshot()

      runAnimation(handleElementId.value, effect, ANIMATION_DEFAULT_DURATION)
    }

    // 애니메이션 선택 패널이 600ms 동안 열린 후 마스크 레이어를 제거합니다. 그렇지 않으면 패널이 열린 후 마우스 미리보기로 빠르게 미끄러지면 떨림이 발생할 수 있습니다.
    const popoverMaskHide = ref(false)
    const handlePopoverVisibleChange = (visible: boolean) => {
      if (visible) {
        setTimeout(() => popoverMaskHide.value = true, 600)
      }
      else popoverMaskHide.value = false
    }

    const openAnimationPool = (elementId: string) => {
      animationPoolVisible.value = true
      handleAnimationId.value = elementId
      handlePopoverVisibleChange(true)
    }

    return {
      tabs,
      activeTab,
      handleAnimationId,
      handleElement,
      animationPoolVisible,
      animationSequence,
      hoverPreviewAnimation,
      handleElementAnimation,
      popoverMaskHide,
      animations: {
        in: ENTER_ANIMATIONS,
        out: EXIT_ANIMATIONS,
        attention: ATTENTION_ANIMATIONS,
      },
      prefix: ANIMATION_CLASS_PREFIX,
      animationTypes,
      addAnimation,
      deleteAnimation,
      handleDragEnd,
      runAnimation,
      updateElementAnimationDuration,
      updateElementAnimationTrigger,
      handlePopoverVisibleChange,
      openAnimationPool,
    }
  },
})
