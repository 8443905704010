
import { defineComponent, onMounted, provide, ref, watch, watchEffect } from 'vue'
import { throttle } from 'lodash'
import { storeToRefs } from 'pinia'
import { useMainStore, useSlidesStore, useKeyboardStore } from '@/store'
import { ContextmenuItem } from '@/components/Contextmenu/types'
import { PPTElement } from '@/types/slides'
import { AlignmentLineProps } from '@/types/edit'
import { injectKeySlideScale } from '@/types/injectKey'
import { removeAllRanges } from '@/utils/selection'
import { KEYS } from '@/configs/hotkey'

import useViewportSize from './hooks/useViewportSize'
import useMouseSelection from './hooks/useMouseSelection'
import useDropImageOrText from './hooks/useDropImageOrText'
import useRotateElement from './hooks/useRotateElement'
import useScaleElement from './hooks/useScaleElement'
import useSelectElement from './hooks/useSelectElement'
import useDragElement from './hooks/useDragElement'
import useDragLineElement from './hooks/useDragLineElement'
import useInsertFromCreateSelection from './hooks/useInsertFromCreateSelection'

import useDeleteElement from '@/hooks/useDeleteElement'
import useCopyAndPasteElement from '@/hooks/useCopyAndPasteElement'
import useSelectAllElement from '@/hooks/useSelectAllElement'
import useScaleCanvas from '@/hooks/useScaleCanvas'
import useScreening from '@/hooks/useScreening'
import useSlideHandler from '@/hooks/useSlideHandler'

import EditableElement from './EditableElement.vue'
import MouseSelection from './MouseSelection.vue'
import ViewportBackground from './ViewportBackground.vue'
import AlignmentLine from './AlignmentLine.vue'
import Ruler from './Ruler.vue'
import ElementCreateSelection from './ElementCreateSelection.vue'
import MultiSelectOperate from './Operate/MultiSelectOperate.vue'
import Operate from './Operate/index.vue'
import LinkDialog from './LinkDialog.vue'

export default defineComponent({
  name: 'editor-canvas',
  components: {
    EditableElement,
    MouseSelection,
    ViewportBackground,
    AlignmentLine,
    Ruler,
    ElementCreateSelection,
    MultiSelectOperate,
    Operate,
    LinkDialog,
  },
  setup() {
    const mainStore = useMainStore()
    const {
      activeElementIdList,
      activeGroupElementId,
      handleElementId,
      editorAreaFocus,
      showGridLines,
      showRuler,
      creatingElement,
      canvasScale,
    } = storeToRefs(mainStore)
    const { currentSlide } = storeToRefs(useSlidesStore())
    const { ctrlKeyState, spaceKeyState } = storeToRefs(useKeyboardStore())

    const viewportRef = ref<HTMLElement>()
    const alignmentLines = ref<AlignmentLineProps[]>([])

    const linkDialogVisible = ref(false)
    const openLinkDialog = () => linkDialogVisible.value = true

    watch(handleElementId, () => {
      mainStore.setActiveGroupElementId('')
    })

    const elementList = ref<PPTElement[]>([])
    const setLocalElementList = () => {
      elementList.value = currentSlide.value ? JSON.parse(JSON.stringify(currentSlide.value.elements)) : []
    }
    watchEffect(setLocalElementList)

    const canvasRef = ref<HTMLElement>()
    const { dragViewport, viewportStyles } = useViewportSize(canvasRef)

    useDropImageOrText(canvasRef)

    const { mouseSelection, mouseSelectionVisible, mouseSelectionQuadrant, updateMouseSelection } = useMouseSelection(elementList, viewportRef)

    const { dragElement } = useDragElement(elementList, alignmentLines, canvasScale)
    const { dragLineElement } = useDragLineElement(elementList)
    const { selectElement } = useSelectElement(elementList, dragElement)
    const { scaleElement, scaleMultiElement } = useScaleElement(elementList, alignmentLines, canvasScale)
    const { rotateElement } = useRotateElement(elementList, viewportRef)

    const { selectAllElement } = useSelectAllElement()
    const { deleteAllElements } = useDeleteElement()
    const { pasteElement } = useCopyAndPasteElement()
    const { enterScreeningFromStart } = useScreening()
    const { updateSlideIndex } = useSlideHandler()

    // 컴포넌트 렌더링 시 요소 포커스가 있을 경우 지워야 함
    // 이 경우: 초점 요소가 있는 경우재생 모드로 들어갔습니다. 종료할 때 원래 포커스를 지워야 합니다. (페이지가 바뀌었을 수도 있습니다.)
    onMounted(() => {
      if (activeElementIdList.value.length) mainStore.setActiveElementIdList([])
    })

    // 캔버스 공백 영역 클릭: 초점 요소 비우기, 캔버스 초점 설정, 텍스트 선택 해제
    const handleClickBlankArea = (e: MouseEvent) => {
      mainStore.setActiveElementIdList([])

      if (!spaceKeyState.value) updateMouseSelection(e)
      else dragViewport(e)

      if (!editorAreaFocus.value) mainStore.setEditorareaFocus(true)
      removeAllRanges()
    }

    // 캔버스 편집 영역 초점 제거
    const removeEditorAreaFocus = () => {
      if (editorAreaFocus.value) mainStore.setEditorareaFocus(false)
    }

    // 마우스 스크롤
    const { scaleCanvas } = useScaleCanvas()
    const throttleScaleCanvas = throttle(scaleCanvas, 100, { leading: true, trailing: false })
    const throttleUpdateSlideIndex = throttle(updateSlideIndex, 300, { leading: true, trailing: false })

    const handleMousewheelCanvas = (e: WheelEvent) => {
      e.preventDefault()

      // Ctrl 키를 누른 상태에서: 캔버스 크기 조정
      if (ctrlKeyState.value) {
        if (e.deltaY > 0) throttleScaleCanvas('-')
        else if (e.deltaY < 0) throttleScaleCanvas('+')
      }
      // 페이지를 위아래로 넘기다
      else {
        if (e.deltaY > 0) throttleUpdateSlideIndex(KEYS.DOWN)
        else if (e.deltaY < 0) throttleUpdateSlideIndex(KEYS.UP)
      }
    }

    // 스위치 격자선
    const toggleGridLines = () => {
      mainStore.setGridLinesState(!showGridLines.value)
    }

    // 스위치척도
    const toggleRuler = () => {
      mainStore.setRulerState(!showRuler.value)
    }

    // 마우스가 그리는 영역에 요소를 삽입합니다.
    const { insertElementFromCreateSelection } = useInsertFromCreateSelection(viewportRef)

    const contextmenus = (): ContextmenuItem[] => {
      return [
        {
          text: '붙이다',
          subText: 'Ctrl + V',
          handler: pasteElement,
        },
        {
          text: '모두선택',
          subText: 'Ctrl + A',
          handler: selectAllElement,
        },
        {
          text: '격자선',
          subText: showGridLines.value ? '√' : '',
          handler: toggleGridLines,
        },
        {
          text: '척도',
          subText: showRuler.value ? '√' : '',
          handler: toggleRuler,
        },
        {
          text: '페이지초기화',
          handler: deleteAllElements,
        },
        { divider: true },
        {
          text: '슬라이드쇼',
          subText: 'F5',
          handler: enterScreeningFromStart,
        },
      ]
    }

    provide(injectKeySlideScale, canvasScale)
    const test = () => { alert('kkkkk')}
    return {
      test,
      elementList,
      activeElementIdList,
      handleElementId,
      activeGroupElementId,
      canvasRef,
      viewportRef,
      viewportStyles,
      canvasScale,
      mouseSelection,
      mouseSelectionVisible,
      mouseSelectionQuadrant,
      creatingElement,
      alignmentLines,
      linkDialogVisible,
      spaceKeyState,
      showRuler,
      openLinkDialog,
      handleClickBlankArea,
      removeEditorAreaFocus,
      insertElementFromCreateSelection,
      selectElement,
      rotateElement,
      scaleElement,
      dragLineElement,
      scaleMultiElement,
      handleMousewheelCanvas,
      contextmenus,
    }
  },
})
