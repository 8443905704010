
import { computed, defineComponent, PropType } from 'vue'
import { PPTImageElement } from '@/types/slides'
import useElementShadow from '@/views/components/element/hooks/useElementShadow'
import useElementFlip from '@/views/components/element/hooks/useElementFlip'
import useClipImage from './useClipImage'
import useFilter from './useFilter'

import ImageOutline from './ImageOutline/index.vue'

export default defineComponent({
  name: 'base-element-image',
  components: {
    ImageOutline,
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTImageElement>,
      required: true,
    },
  },
  setup(props) {
    const shadow = computed(() => props.elementInfo.shadow)
    const { shadowStyle } = useElementShadow(shadow)

    const flipH = computed(() => props.elementInfo.flipH)
    const flipV = computed(() => props.elementInfo.flipV)
    const { flipStyle } = useElementFlip(flipH, flipV)
    
    const clip = computed(() => props.elementInfo.clip)
    const { clipShape, imgPosition } = useClipImage(clip)

    const filters = computed(() => props.elementInfo.filters)
    const { filter } = useFilter(filters)

    return {
      imgPosition,
      filter,
      flipStyle,
      shadowStyle,
      clipShape,
    }
  },
})
