
import { defineComponent, Ref, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore, useSlidesStore } from '@/store'
import { PPTElement, TableCell } from '@/types/slides'
import { ElementAlignCommands, ElementOrderCommands } from '@/types/edit'
import emitter, { EmitterEvents } from '@/utils/emitter'
import useOrderElement from '@/hooks/useOrderElement'
import useAlignElementToCanvas from '@/hooks/useAlignElementToCanvas'
import useDeleteElement from '@/hooks/useDeleteElement'
import useAddSlidesOrElements from '@/hooks/useAddSlidesOrElements'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

interface TabItem {
  key: 'style' | 'common'
  label: string
}

const colors = ['#000000', '#ffffff', '#eeece1', '#1e497b', '#4e81bb', '#e2534d', '#9aba60', '#8165a0', '#47acc5', '#f9974c', '#c21401', '#ff1e02', '#ffc12a', '#ffff3a', '#90cf5b', '#00af57']

export default defineComponent({
  name: 'element-toolbar',
  setup() {
    const mainStore = useMainStore()
    const slidesStore = useSlidesStore()
    const { handleElement, handleElementId, richTextAttrs } = storeToRefs(mainStore)

    const { addHistorySnapshot } = useHistorySnapshot()

    const updateElement = (id: string, props: Partial<PPTElement>) => {
      slidesStore.updateElement({ id, props })
      addHistorySnapshot()
    }

    const tabs: TabItem[] = [
      { key: 'style', label: '형식' },
      { key: 'common', label: '배치' },
    ]
    const activeTab = ref('common')

    const { orderElement } = useOrderElement()
    const { alignElementToCanvas } = useAlignElementToCanvas()
    const { addElementsFromData } = useAddSlidesOrElements()
    const { deleteElement } = useDeleteElement()

    const copyElement = () => {
      const element: PPTElement = JSON.parse(JSON.stringify(handleElement.value))
      addElementsFromData([element])
    }

    const emitRichTextCommand = (command: string, value?: string) => {
      emitter.emit(EmitterEvents.RICH_TEXT_COMMAND, { action: { command, value } })
    }

    const updateFontColor = (color: string) => {
      if (!handleElement.value) return
      if (handleElement.value.type === 'text' || (handleElement.value.type === 'shape' && handleElement.value.text?.content)) {
        emitter.emit(EmitterEvents.RICH_TEXT_COMMAND, { action: { command: 'color', value: color } })
      }
      if (handleElement.value.type === 'table') {
        const data: TableCell[][] = JSON.parse(JSON.stringify(handleElement.value.data))
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < data[i].length; j++) {
            const style = data[i][j].style || {}
            data[i][j].style = { ...style, color }
          }
        }
        updateElement(handleElementId.value, { data })
      }
      if (handleElement.value.type === 'latex') {
        updateElement(handleElementId.value, { color })
      }
    }

    const updateFill = (color: string) => {
      if (!handleElement.value) return
      if (
        handleElement.value.type === 'text' ||
        handleElement.value.type === 'shape' ||
        handleElement.value.type === 'chart'
      ) updateElement(handleElementId.value, { fill: color })

      if (handleElement.value.type === 'table') {
        const data: TableCell[][] = JSON.parse(JSON.stringify(handleElement.value.data))
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < data[i].length; j++) {
            const style = data[i][j].style || {}
            data[i][j].style = { ...style, backcolor: color }
          }
        }
        updateElement(handleElementId.value, { data })
      }

      if (handleElement.value.type === 'audio') updateElement(handleElementId.value, { color })
    }

    return {
      handleElement: handleElement as Ref<PPTElement>,
      tabs,
      activeTab,
      richTextAttrs,
      colors,
      orderElement,
      alignElementToCanvas,
      copyElement,
      deleteElement,
      emitRichTextCommand,
      updateFontColor,
      updateFill,
      ElementOrderCommands,
      ElementAlignCommands,
    }
  },
})
