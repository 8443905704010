import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6cc1801a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ruler" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "h",
      style: _normalizeStyle({
        width: _ctx.viewportStyles.width * _ctx.canvasScale + 'px',
        left: _ctx.viewportStyles.left + 'px',
      })
    }, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (marker) => {
        return _createElementVNode("div", {
          class: _normalizeClass(["ruler-marker-100", { 'hide': _ctx.markerSize < 36, 'omit': _ctx.markerSize < 72 }]),
          key: `marker-100-${marker}`
        }, [
          _createElementVNode("span", null, _toDisplayString(marker * 100), 1)
        ], 2)
      }), 64))
    ], 4),
    _createElementVNode("div", {
      class: "v",
      style: _normalizeStyle({
        height: _ctx.viewportStyles.height * _ctx.canvasScale + 'px',
        top: _ctx.viewportStyles.top + 'px',
      })
    }, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (marker) => {
        return _createElementVNode("div", {
          class: _normalizeClass(["ruler-marker-100", { 'hide': _ctx.markerSize < 36, 'omit': _ctx.markerSize < 72 }]),
          key: marker,
          style: _normalizeStyle({ height: _ctx.markerSize + 'px' })
        }, [
          _createElementVNode("span", null, _toDisplayString(marker * 100), 1)
        ], 6)
      }), 64))
    ], 4)
  ]))
}