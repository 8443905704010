import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { nanoid } from 'nanoid'
import { useMainStore, useSlidesStore } from '@/store'
import { PPTElement } from '@/types/slides'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

export default () => {
  const mainStore = useMainStore()
  const slidesStore = useSlidesStore()
  const { activeElementIdList, activeElementList, handleElementId } = storeToRefs(mainStore)
  const { currentSlide } = storeToRefs(slidesStore)

  const { addHistorySnapshot } = useHistorySnapshot()

  /**
   * 현재 선택된 요소를 결합할 수 있는지 여부를 결정합니다.
   */
  const canCombine = computed(() => {
    if (activeElementList.value.length < 2) return false

    const firstGroupId = activeElementList.value[0].groupId
    if (!firstGroupId) return true

    const inSameGroup = activeElementList.value.every(el => (el.groupId && el.groupId) === firstGroupId)
    return !inSameGroup
  })

  /**
   * 현재 선택된 요소를 결합합니다: 현재 선택된 요소에 동일한 그룹 ID를 부여합니다
   */
  const combineElements = () => {
    if (!activeElementList.value.length) return

    // 다음 작업을 위해 새 요소 목록을 만듭니다
    let newElementList: PPTElement[] = JSON.parse(JSON.stringify(currentSlide.value.elements))

    // 그룹 ID 생성
    const groupId = nanoid(10)

    // 조합할 요소 목록을 수집하고 고유 그룹 ID를 지정합니다.
    const combineElementList: PPTElement[] = []
    for (const element of newElementList) {
      if (activeElementIdList.value.includes(element.id)) {
        element.groupId = groupId
        combineElementList.push(element)
      }
    }

    // 그룹 내 모든 요소 구성원의 계층이 연속적으로 존재하는지 확인합니다. 구체적인 조작 방법은 다음과 같습니다.
    // 그룹 내 최상위 요소의 레벨을 먼저 가져옵니다. 새로운 요소 목록에서 요소를 제거합니다.，
    // 위에서 수집한 조합이 필요한 요소 목록을 최상층 요소의 계층적 위치에 따라 새로운 요소 목록에 삽입합니다.
    const combineElementMaxLevel = newElementList.findIndex(_element => _element.id === combineElementList[combineElementList.length - 1].id)
    const combineElementIdList = combineElementList.map(_element => _element.id)
    newElementList = newElementList.filter(_element => !combineElementIdList.includes(_element.id))

    const insertLevel = combineElementMaxLevel - combineElementList.length + 1
    newElementList.splice(insertLevel, 0, ...combineElementList)

    slidesStore.updateSlide({ elements: newElementList })
    addHistorySnapshot()
  }

  /**
   * 요소 결합 취소: 선택한 요소의 그룹 ID 제거
   */
  const uncombineElements = () => {
    if (!activeElementList.value.length) return
    const hasElementInGroup = activeElementList.value.some(item => item.groupId)
    if (!hasElementInGroup) return
    
    const newElementList: PPTElement[] = JSON.parse(JSON.stringify(currentSlide.value.elements))
    for (const element of newElementList) {
      if (activeElementIdList.value.includes(element.id) && element.groupId) delete element.groupId
    }
    slidesStore.updateSlide({ elements: newElementList })

    // 조합을 취소한 후 활성화 요소 상태를 재설정해야 합니다
    // 현재 동작 중인 요소로 기본값으로 재설정하고, 존재하지 않으면 비어 있습니다
    const handleElementIdList = handleElementId.value ? [handleElementId.value] : []
    mainStore.setActiveElementIdList(handleElementIdList)

    addHistorySnapshot()
  }

  return {
    canCombine,
    combineElements,
    uncombineElements,
  }
}