import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScreenElement = _resolveComponent("ScreenElement")!

  return (_openBlock(), _createElementBlock("div", {
    class: "screen-slide",
    style: _normalizeStyle({
      width: _ctx.VIEWPORT_SIZE + 'px',
      height: _ctx.VIEWPORT_SIZE * _ctx.viewportRatio + 'px',
      transform: `scale(${_ctx.scale})`,
    })
  }, [
    _createElementVNode("div", {
      class: "background",
      style: _normalizeStyle({ ..._ctx.backgroundStyle })
    }, null, 4),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slide.elements, (element, index) => {
      return (_openBlock(), _createBlock(_component_ScreenElement, {
        key: element.id,
        elementInfo: element,
        elementIndex: index + 1,
        animationIndex: _ctx.animationIndex,
        turnSlideToId: _ctx.turnSlideToId,
        manualExitFullscreen: _ctx.manualExitFullscreen
      }, null, 8, ["elementInfo", "elementIndex", "animationIndex", "turnSlideToId", "manualExitFullscreen"]))
    }), 128))
  ], 4))
}