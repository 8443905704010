import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77651bf3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "canvas-tool" }
const _hoisted_2 = { class: "left-handler" }
const _hoisted_3 = { class: "right-handler" }
const _hoisted_4 = { class: "viewport-size-preset" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBack = _resolveComponent("IconBack")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_IconNext = _resolveComponent("IconNext")!
  const _component_Popover = _resolveComponent("Popover")!
  const _component_IconFullScreen = _resolveComponent("IconFullScreen")!
  const _component_LaTeXEditor = _resolveComponent("LaTeXEditor")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Tooltip, {
        mouseLeaveDelay: 0,
        mouseEnterDelay: 0.5,
        title: "실행취소"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IconBack, {
            class: _normalizeClass(["handler-item", { 'disable': !_ctx.canUndo }]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.undo()))
          }, null, 8, ["class"])
        ]),
        _: 1
      }, 8, ["mouseEnterDelay"]),
      _createVNode(_component_Tooltip, {
        mouseLeaveDelay: 0,
        mouseEnterDelay: 0.5,
        title: "재실행"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IconNext, {
            class: _normalizeClass(["handler-item", { 'disable': !_ctx.canRedo }]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.redo()))
          }, null, 8, ["class"])
        ]),
        _: 1
      }, 8, ["mouseEnterDelay"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Popover, {
        trigger: "click",
        visible: _ctx.canvasScaleVisible,
        "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.canvasScaleVisible) = $event))
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.canvasScalePresetList, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "preset-item",
                key: item,
                onClick: ($event: any) => (_ctx.applyCanvasPresetScale(item))
              }, _toDisplayString(item) + "%", 9, _hoisted_5))
            }), 128))
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.canvasScalePercentage), 1)
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_Tooltip, {
        mouseLeaveDelay: 0,
        mouseEnterDelay: 0.5,
        title: "화면적응"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IconFullScreen, {
            class: "handler-item viewport-size-adaptation",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.resetCanvas()))
          })
        ]),
        _: 1
      }, 8, ["mouseEnterDelay"])
    ]),
    _createVNode(_component_Modal, {
      visible: _ctx.latexEditorVisible,
      "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.latexEditorVisible) = $event)),
      footer: null,
      centered: "",
      width: 880,
      destroyOnClose: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_LaTeXEditor, {
          onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.latexEditorVisible = false)),
          onUpdate: _cache[5] || (_cache[5] = data => { _ctx.createLatexElement(data); _ctx.latexEditorVisible = false })
        })
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}