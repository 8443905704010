
import { computed, defineComponent, PropType } from 'vue'
import { storeToRefs } from 'pinia'
import { useSlidesStore } from '@/store'
import { ElementTypes, PPTElement } from '@/types/slides'

import BaseImageElement from '@/views/components/element/ImageElement/BaseImageElement.vue'
import BaseTextElement from '@/views/components/element/TextElement/BaseTextElement.vue'
import BaseShapeElement from '@/views/components/element/ShapeElement/BaseShapeElement.vue'
import BaseLineElement from '@/views/components/element/LineElement/BaseLineElement.vue'
import ScreenChartElement from '@/views/components/element/ChartElement/ScreenChartElement.vue'
import BaseTableElement from '@/views/components/element/TableElement/BaseTableElement.vue'
import BaseLatexElement from '@/views/components/element/LatexElement/BaseLatexElement.vue'
import ScreenVideoElement from '@/views/components/element/VideoElement/ScreenVideoElement.vue'
import ScreenAudioElement from '@/views/components/element/AudioElement/ScreenAudioElement.vue'

export default defineComponent({
  name: 'screen-element',
  props: {
    elementInfo: {
      type: Object as PropType<PPTElement>,
      required: true,
    },
    elementIndex: {
      type: Number,
      required: true,
    },
    animationIndex: {
      type: Number,
      required: true,
    },
    turnSlideToId: {
      type: Function as PropType<(id: string) => void>,
      required: true,
    },
    manualExitFullscreen: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const currentElementComponent = computed(() => {
      const elementTypeMap = {
        [ElementTypes.IMAGE]: BaseImageElement,
        [ElementTypes.TEXT]: BaseTextElement,
        [ElementTypes.SHAPE]: BaseShapeElement,
        [ElementTypes.LINE]: BaseLineElement,
        [ElementTypes.CHART]: ScreenChartElement,
        [ElementTypes.TABLE]: BaseTableElement,
        [ElementTypes.LATEX]: BaseLatexElement,
        [ElementTypes.VIDEO]: ScreenVideoElement,
        [ElementTypes.AUDIO]: ScreenAudioElement,
      }
      return elementTypeMap[props.elementInfo.type] || null
    })

    const { formatedAnimations, theme } = storeToRefs(useSlidesStore())

    // 입력 애니메이션 실행 대기 여부를 판단합니다. 입력 대기 요소는 숨겨야 합니다.
    const needWaitAnimation = computed(() => {
      // 이 페이지의 애니메이션 시퀀스에서 요소의 위치
      const elementIndexInAnimation = formatedAnimations.value.findIndex(item => {
        const elIds = item.animations.map(item => item.elId)
        return elIds.includes(props.elementInfo.id)
      })

      // 요소가 애니메이션을 설정하지 않았습니다
      if (elementIndexInAnimation === -1) return false

      // 요소를 이미 애니메이션화했다면 숨길 필요가 없습니다
      // 구체적으로는: 실행이 완료된 경우다음 애니메이션은 입장하기 때문에 숨길 필요가 없습니다. 이미 실행된 마지막 애니메이션이 퇴장된 경우, 퇴장 애니메이션이 종료된 상태로 남아 있기 때문에 숨길 필요가 없습니다.
      if (elementIndexInAnimation < props.animationIndex) return false

      // 요소가 애니메이션을 실행하지 않으면 실행할 첫 번째 애니메이션을 가져옵니다
      // 실행할 첫 번째 애니메이션이 입장일 경우 숨김숨기지 않으면 숨길 필요가 없다
      const firstAnimation = formatedAnimations.value[elementIndexInAnimation].animations.find(item => item.elId === props.elementInfo.id)
      if (firstAnimation?.type === 'in') return true
      return false
    })

    // 요소 바인딩의 하이퍼링크 열기
    const openLink = () => {
      const link = props.elementInfo.link
      if (!link) return

      if (link.type === 'web') {
        props.manualExitFullscreen()
        window.open(link.target)
      }
      else if (link.type === 'slide') {
        props.turnSlideToId(link.target)
      }
    }

    return {
      currentElementComponent,
      needWaitAnimation,
      theme,
      openLink,
    }
  },
})
