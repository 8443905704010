import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3bbcc75c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "element-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StaticTable = _resolveComponent("StaticTable")!

  return (_openBlock(), _createElementBlock("div", {
    class: "base-element-table",
    style: _normalizeStyle({
      top: _ctx.elementInfo.top + 'px',
      left: _ctx.elementInfo.left + 'px',
      width: _ctx.elementInfo.width + 'px',
    })
  }, [
    _createElementVNode("div", {
      class: "rotate-wrapper",
      style: _normalizeStyle({ transform: `rotate(${_ctx.elementInfo.rotate}deg)` })
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_StaticTable, {
          data: _ctx.elementInfo.data,
          width: _ctx.elementInfo.width,
          colWidths: _ctx.elementInfo.colWidths,
          outline: _ctx.elementInfo.outline,
          theme: _ctx.elementInfo.theme
        }, null, 8, ["data", "width", "colWidths", "outline", "theme"])
      ])
    ], 4)
  ], 4))
}