
import { defineComponent, PropType, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useSlidesStore } from '@/store'
import useLoadSlides from '@/hooks/useLoadSlides'
import { Mode } from '@/types/mobile'

import ThumbnailSlide from '@/views/components/ThumbnailSlide/index.vue'

export default defineComponent({
  name: 'mobile-preview',
  components: {
    ThumbnailSlide,
  },
  props: {
    changeMode: {
      type: Function as PropType<(mode: Mode) => void>,
      required: true,
    },
  },
  setup() {
    const { slides } = storeToRefs(useSlidesStore())
    const { slidesLoadLimit } = useLoadSlides()

    const mobileRef = ref<HTMLElement>()
    const screenWidth = ref(0)

    onMounted(() => {
      if (!mobileRef.value) return
      screenWidth.value = mobileRef.value.clientWidth
    })

    return {
      slides,
      slidesLoadLimit,
      mobileRef,
      screenWidth,
    }
  },
})
