import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e9bce30"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-editor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_AlignmentLine = _resolveComponent("AlignmentLine")!
  const _component_MobileOperate = _resolveComponent("MobileOperate")!
  const _component_MobileEditableElement = _resolveComponent("MobileEditableElement")!
  const _component_SlideToolbar = _resolveComponent("SlideToolbar")!
  const _component_ElementToolbar = _resolveComponent("ElementToolbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header, { changeMode: _ctx.changeMode }, null, 8, ["changeMode"]),
    _createElementVNode("div", {
      class: "content",
      ref: "contentRef",
      onTouchstart: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClickBlankArea()))
    }, [
      _createElementVNode("div", {
        class: "viewport-wrapper",
        style: _normalizeStyle(_ctx.viewportStyles)
      }, [
        _createElementVNode("div", {
          class: "background",
          style: _normalizeStyle(_ctx.backgroundStyle)
        }, null, 4),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alignmentLines, (line, index) => {
          return (_openBlock(), _createBlock(_component_AlignmentLine, {
            key: index,
            type: line.type,
            axis: line.axis,
            length: line.length,
            canvasScale: _ctx.canvasScale
          }, null, 8, ["type", "axis", "length", "canvasScale"]))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elementList, (element) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: element.id
          }, [
            (element.type !== 'line')
              ? (_openBlock(), _createBlock(_component_MobileOperate, {
                  key: 0,
                  elementInfo: element,
                  isSelected: _ctx.activeElementIdList.includes(element.id),
                  canvasScale: _ctx.canvasScale,
                  scaleElement: _ctx.scaleElement
                }, null, 8, ["elementInfo", "isSelected", "canvasScale", "scaleElement"]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128)),
        _createElementVNode("div", {
          class: "viewport",
          style: _normalizeStyle({ transform: `scale(${_ctx.canvasScale})` })
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elementList, (element, index) => {
            return (_openBlock(), _createBlock(_component_MobileEditableElement, {
              key: element.id,
              elementInfo: element,
              elementIndex: index + 1,
              selectElement: _ctx.selectElement
            }, null, 8, ["elementInfo", "elementIndex", "selectElement"]))
          }), 128))
        ], 4)
      ], 4)
    ], 544),
    _createVNode(_component_SlideToolbar),
    (_ctx.handleElement)
      ? (_openBlock(), _createBlock(_component_ElementToolbar, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}