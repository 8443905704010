
import { computed, defineComponent, PropType } from 'vue'
import { ElementTypes, PPTElement } from '@/types/slides'
import { ContextmenuItem } from '@/components/Contextmenu/types'

import useLockElement from '@/hooks/useLockElement'
import useDeleteElement from '@/hooks/useDeleteElement'
import useCombineElement from '@/hooks/useCombineElement'
import useOrderElement from '@/hooks/useOrderElement'
import useAlignElementToCanvas from '@/hooks/useAlignElementToCanvas'
import useCopyAndPasteElement from '@/hooks/useCopyAndPasteElement'
import useSelectAllElement from '@/hooks/useSelectAllElement'

import { ElementOrderCommands, ElementAlignCommands } from '@/types/edit'

import ImageElement from '@/views/components/element/ImageElement/index.vue'
import TextElement from '@/views/components/element/TextElement/index.vue'
import ShapeElement from '@/views/components/element/ShapeElement/index.vue'
import LineElement from '@/views/components/element/LineElement/index.vue'
import ChartElement from '@/views/components/element/ChartElement/index.vue'
import TableElement from '@/views/components/element/TableElement/index.vue'
import LatexElement from '@/views/components/element/LatexElement/index.vue'
import VideoElement from '@/views/components/element/VideoElement/index.vue'
import AudioElement from '@/views/components/element/AudioElement/index.vue'

export default defineComponent({
  name: 'editable-element',
  props: {
    elementInfo: {
      type: Object as PropType<PPTElement>,
      required: true,
    },
    elementIndex: {
      type: Number,
      required: true,
    },
    isMultiSelect: {
      type: Boolean,
      required: true,
    },
    selectElement: {
      type: Function as PropType<(e: MouseEvent | TouchEvent, element: PPTElement, canMove?: boolean) => void>,
      required: true,
    },
    openLinkDialog: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const currentElementComponent = computed(() => {
      const elementTypeMap = {
        [ElementTypes.IMAGE]: ImageElement,
        [ElementTypes.TEXT]: TextElement,
        [ElementTypes.SHAPE]: ShapeElement,
        [ElementTypes.LINE]: LineElement,
        [ElementTypes.CHART]: ChartElement,
        [ElementTypes.TABLE]: TableElement,
        [ElementTypes.LATEX]: LatexElement,
        [ElementTypes.VIDEO]: VideoElement,
        [ElementTypes.AUDIO]: AudioElement,
      }
      return elementTypeMap[props.elementInfo.type] || null
    })

    const { orderElement } = useOrderElement()
    const { alignElementToCanvas } = useAlignElementToCanvas()
    const { combineElements, uncombineElements } = useCombineElement()
    const { deleteElement } = useDeleteElement()
    const { lockElement, unlockElement } = useLockElement()
    const { copyElement, pasteElement, cutElement } = useCopyAndPasteElement()
    const { selectAllElement } = useSelectAllElement()

    const contextmenus = (): ContextmenuItem[] => {
      if (props.elementInfo.lock) {
        return [{
          text: '잠금해제', 
          handler: () => unlockElement(props.elementInfo),
        }]
      }

      return [
        {
          text: '잘라내다.',
          subText: 'Ctrl + X',
          handler: cutElement,
        },
        {
          text: '복사',
          subText: 'Ctrl + C',
          handler: copyElement,
        },
        {
          text: '붙이다',
          subText: 'Ctrl + V',
          handler: pasteElement,
        },
        { divider: true },
        {
          text: '수평중앙',
          handler: () => alignElementToCanvas(ElementAlignCommands.HORIZONTAL),
          children: [
            { text: '수평수직중앙', handler: () => alignElementToCanvas(ElementAlignCommands.CENTER), },
            { text: '수평중앙', handler: () => alignElementToCanvas(ElementAlignCommands.HORIZONTAL) },
            { text: '왼쪽정렬', handler: () => alignElementToCanvas(ElementAlignCommands.LEFT) },
            { text: '우측정렬', handler: () => alignElementToCanvas(ElementAlignCommands.RIGHT) },
          ],
        },
        {
          text: '수직중앙',
          handler: () => alignElementToCanvas(ElementAlignCommands.VERTICAL),
          children: [
            { text: '수평수직중앙', handler: () => alignElementToCanvas(ElementAlignCommands.CENTER) },
            { text: '수직중앙', handler: () => alignElementToCanvas(ElementAlignCommands.VERTICAL) },
            { text: '위쪽정렬', handler: () => alignElementToCanvas(ElementAlignCommands.TOP) },
            { text: '아래정렬', handler: () => alignElementToCanvas(ElementAlignCommands.BOTTOM) },
          ],
        },
        { divider: true },
        {
          text: '맨위정렬',
          disable: props.isMultiSelect && !props.elementInfo.groupId,
          handler: () => orderElement(props.elementInfo, ElementOrderCommands.TOP),
          children: [
            { text: '위에붙임', handler: () => orderElement(props.elementInfo, ElementOrderCommands.TOP) },
            { text: '위로이동', handler: () => orderElement(props.elementInfo, ElementOrderCommands.UP) },
          ],
        },
        {
          text: '바닥정렬',
          disable: props.isMultiSelect && !props.elementInfo.groupId,
          handler: () => orderElement(props.elementInfo, ElementOrderCommands.BOTTOM),
          children: [
            { text: '아래붙임', handler: () => orderElement(props.elementInfo, ElementOrderCommands.BOTTOM) },
            { text: '아래이동', handler: () => orderElement(props.elementInfo, ElementOrderCommands.DOWN) },
          ],
        },
        { divider: true },
        {
          text: '링크설정',
          handler: props.openLinkDialog,
        },
        {
          text: props.elementInfo.groupId ? '그룹취소' : '그룹',
          subText: 'Ctrl + G',
          handler: props.elementInfo.groupId ? uncombineElements : combineElements,
          hide: !props.isMultiSelect,
        },
        {
          text: '모두선택',
          subText: 'Ctrl + A',
          handler: selectAllElement,
        },
        {
          text: '잠금',
          subText: 'Ctrl + L',
          handler: lockElement,
        },
        {
          text: '삭제',
          subText: 'Delete',
          handler: deleteElement,
        },
      ]
    }

    return {
      currentElementComponent,
      contextmenus,
    }
  },
})
