export const SYS_FONTS = [
  { label: 'Arial', value: 'Arial' },
  { label: 'Microsoft', value: 'Microsoft Yahei' },
  { label: '송체', value: 'SimSun' },
  { label: '블랙바디', value: 'SimHei' },
  { label: '해체', value: 'KaiTi' },
  { label: '신송체', value: 'NSimSun' },
  { label: '송모사', value: 'FangSong' },
  { label: '애플', value: 'PingFang SC' },
  { label: '한글고딕체', value: 'STHeiti' },
  { label: '한글해체', value: 'STKaiti' },
  { label: '한글송체', value: 'STSong' },
  { label: '한글체체', value: 'STFangSong' },
  { label: '군문중체', value: 'STZhongSong' },
  { label: '한글호박', value: 'STHupo' },
  { label: '한글신위', value: 'STXinwei' },
  { label: '한글예서', value: 'STLiti' },
  { label: '한글행해', value: 'STXingkai' },
  { label: '동청흑체', value: 'Hiragino Sans GB' },
  { label: '난정흑', value: 'Lantinghei SC' },
  { label: '하필체', value: 'Hanzipen SC' },
  { label: '손글씨체', value: 'Hannotate SC' },
  { label: '송체', value: 'Songti SC' },
  { label: '인형체', value: 'Wawati SC' },
  { label: '행해', value: 'Xingkai SC' },
  { label: '둥근체', value: 'Yuanti SC' },
  { label: '한국어세흑', value: 'STXihei' },
  { label: '미니동그라미', value: 'YouYuan' },
  { label: '예서', value: 'LiSu' },
]

export const WEB_FONTS = [
  { label: 'TsangerXWZ', value: 'TsangerXWZ' },
  { label: 'YouSheBiaoTiHei', value: 'YouSheBiaoTiHei' },
  { label: 'FengGuangMingRui', value: 'FengGuangMingRui' },
  { label: 'shetumodengxiaofangti', value: 'shetumodengxiaofangti' },
  { label: 'HappyZcool-2016', value: 'HappyZcool-2016' },
  { label: 'ZiZhiQuZiMaiTi', value: 'ZiZhiQuZiMaiTi' },
  { label: 'sucaijishikangkangti', value: 'sucaijishikangkangti' },
  { label: 'sucaijishikufangti', value: 'sucaijishikufangti' },
  { label: 'tuniuleiyuan', value: 'tuniuleiyuan' },
  { label: 'ZhenyanGB', value: 'ZhenyanGB' },
]