// axios
import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({


  // You can add your headers here
  // ================================
  baseURL: 'https://admin.lms.next-meta.co/rest/',
  //baseURL: 'http://admin.ncu.or.kr/rest/',
  //  baseURL: 'https://kls.lms.next-meta.co/rest',
  timeout: 30000,
  // headers: {'X-Custom-Header': 'foobar'}
  headers: { 'Content-Type': 'application/json',

  }
})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = sessionStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    //if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

//Vue.prototype.$http = axiosIns

export default axiosIns

