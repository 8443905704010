
import { computed, defineComponent, nextTick, ref, watch, PropType } from 'vue'
import { storeToRefs } from 'pinia'
import { useSlidesStore } from '@/store'
import { ContextmenuItem } from '@/components/Contextmenu/types'
import { enterFullscreen } from '@/utils/fullscreen'
import { parseText2Paragraphs } from '@/utils/textParser'
import useScreening from '@/hooks/useScreening'
import useLoadSlides from '@/hooks/useLoadSlides'
import useExecPlay from './hooks/useExecPlay'
import useSlideSize from './hooks/useSlideSize'
import useFullscreen from './hooks/useFullscreen'

import ThumbnailSlide from '@/views/components/ThumbnailSlide/index.vue'
import ScreenSlideList from './ScreenSlideList.vue'
import WritingBoardTool from './WritingBoardTool.vue'

export default defineComponent({
  name: 'presenter-view',
  components: {
    ScreenSlideList,
    ThumbnailSlide,
    WritingBoardTool,
  },
  props: {
    changeViewMode: {
      type: Function as PropType<(mode: 'base' | 'presenter') => void>,
      required: true,
    },
  },
  setup(props) {
    const { slides, slideIndex, viewportRatio, currentSlide } = storeToRefs(useSlidesStore())

    const slideListWrapRef = ref<HTMLElement>()
    const thumbnailsRef = ref<HTMLElement>()
    const writingBoardToolVisible = ref(false)
    const laserPen = ref(false)

    const {
      mousewheelListener,
      touchStartListener,
      touchEndListener,
      turnPrevSlide,
      turnNextSlide,
      turnSlideToIndex,
      turnSlideToId,
      animationIndex,
    } = useExecPlay()

    const { slideWidth, slideHeight } = useSlideSize(slideListWrapRef)
    const { exitScreening } = useScreening()
    const { slidesLoadLimit } = useLoadSlides()
    const { fullscreenState, manualExitFullscreen } = useFullscreen()

    const remarkFontSize = ref(16)
    const currentSlideRemark = computed(() => {
      return parseText2Paragraphs(currentSlide.value.remark || '비고없음')
    })

    const handleMousewheelThumbnails = (e: WheelEvent) => {
      if (!thumbnailsRef.value) return
      thumbnailsRef.value.scrollBy(e.deltaY, 0)
    }

    const setRemarkFontSize = (fontSize: number) => {
      if (fontSize < 12 || fontSize > 40) return
      remarkFontSize.value = fontSize
    }

    watch(slideIndex, () => {
      nextTick(() => {
        if (!thumbnailsRef.value) return

        const activeThumbnailRef: HTMLElement | null = thumbnailsRef.value.querySelector('.thumbnail.active')
        if (!activeThumbnailRef) return

        const width = thumbnailsRef.value.offsetWidth
        const offsetLeft = activeThumbnailRef.offsetLeft
        thumbnailsRef.value.scrollTo({ left: offsetLeft - width / 2, behavior: 'smooth' })
      })
    })

    const contextmenus = (): ContextmenuItem[] => {
      return [
        {
          text: '이전페이지',
          subText: '↑ ←',
          disable: slideIndex.value <= 0,
          handler: () => turnPrevSlide(),
        },
        {
          text: '다음페이지',
          subText: '↓ →',
          disable: slideIndex.value >= slides.value.length - 1,
          handler: () => turnNextSlide(),
        },
        {
          text: '첫 페이지',
          disable: slideIndex.value === 0,
          handler: () => turnSlideToIndex(0),
        },
        {
          text: '마지막 페이지',
          disable: slideIndex.value === slides.value.length - 1,
          handler: () => turnSlideToIndex(slides.value.length - 1),
        },
        { divider: true },
        {
          text: '그리기도구',
          handler: () => writingBoardToolVisible.value = true,
        },
        {
          text: '일반보기',
          handler: () => props.changeViewMode('base'),
        },
        { divider: true },
        {
          text: '종료',
          subText: 'ESC',
          handler: exitScreening,
        },
      ]
    }

    return {
      slides,
      slideIndex,
      viewportRatio,
      remarkFontSize,
      currentSlideRemark,
      setRemarkFontSize,
      slideListWrapRef,
      thumbnailsRef,
      slideWidth,
      slideHeight,
      animationIndex,
      turnSlideToId,
      mousewheelListener,
      touchStartListener,
      touchEndListener,
      turnSlideToIndex,
      contextmenus,
      slidesLoadLimit,
      handleMousewheelThumbnails,
      exitScreening,
      fullscreenState,
      enterFullscreen,
      manualExitFullscreen,
      writingBoardToolVisible,
      laserPen,
    }
  },
})
