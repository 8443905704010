import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54efa7c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["screen-element", { 'link': _ctx.elementInfo.link }]),
    id: `screen-element-${_ctx.elementInfo.id}`,
    style: _normalizeStyle({
      zIndex: _ctx.elementIndex,
      color: _ctx.theme.fontColor,
      fontFamily: _ctx.theme.fontName,
      visibility: _ctx.needWaitAnimation ? 'hidden' : 'visible',
    }),
    title: _ctx.elementInfo.link?.target || '',
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openLink()))
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentElementComponent), { elementInfo: _ctx.elementInfo }, null, 8, ["elementInfo"]))
  ], 14, _hoisted_1))
}