import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3719d97c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "animation-index"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkHandler = _resolveComponent("LinkHandler")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["operate", { 'multi-select': _ctx.isMultiSelect && !_ctx.isActive }]),
    style: _normalizeStyle({
      top: _ctx.elementInfo.top * _ctx.canvasScale + 'px',
      left: _ctx.elementInfo.left * _ctx.canvasScale + 'px',
      transform: `rotate(${_ctx.rotate}deg)`,
      transformOrigin: `${_ctx.elementInfo.width * _ctx.canvasScale / 2}px ${_ctx.height * _ctx.canvasScale / 2}px`,
    })
  }, [
    (_ctx.isSelected)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentOperateComponent), {
          key: 0,
          elementInfo: _ctx.elementInfo,
          handlerVisible: !_ctx.elementInfo.lock && (_ctx.isActiveGroupElement || !_ctx.isMultiSelect),
          rotateElement: _ctx.rotateElement,
          scaleElement: _ctx.scaleElement,
          dragLineElement: _ctx.dragLineElement
        }, null, 8, ["elementInfo", "handlerVisible", "rotateElement", "scaleElement", "dragLineElement"]))
      : _createCommentVNode("", true),
    (_ctx.toolbarState === 'elAnimation' && _ctx.elementIndexListInAnimation.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elementIndexListInAnimation, (index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "index-item",
              key: index
            }, _toDisplayString(index + 1), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.isActive && _ctx.elementInfo.link)
      ? (_openBlock(), _createBlock(_component_LinkHandler, {
          key: 2,
          elementInfo: _ctx.elementInfo,
          link: _ctx.elementInfo.link,
          openLinkDialog: _ctx.openLinkDialog,
          onMousedown: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, null, 8, ["elementInfo", "link", "openLinkDialog"]))
      : _createCommentVNode("", true)
  ], 6))
}