import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`mouse-selection quadrant-${_ctx.quadrant}`),
    style: _normalizeStyle({
      top: _ctx.top + 'px',
      left: _ctx.left + 'px',
      width: _ctx.width + 'px',
      height: _ctx.height + 'px',
    })
  }, null, 6))
}