
import { computed, defineComponent, PropType } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore } from '@/store'

interface ViewportStyles {
  top: number
  left: number
  width: number
  height: number
}

export default defineComponent({
  props: {
    viewportStyles: {
      type: Object as PropType<ViewportStyles>,
      required: true,
    },
  },
  setup(props) {
    const { canvasScale } = storeToRefs(useMainStore())

    const markerSize = computed(() => {
      return props.viewportStyles.width * canvasScale.value / 10
    })

    return {
      canvasScale,
      markerSize,
    }
  },
})
