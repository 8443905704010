
import { computed, defineComponent, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore } from '@/store'
import { ToolbarStates } from '@/types/toolbar'

import ElementStylePanel from './ElementStylePanel/index.vue'
import ElementPositionPanel from './ElementPositionPanel.vue'
import ElementAnimationPanel from './ElementAnimationPanel.vue'
import SlideDesignPanel from './SlideThemaPanel.vue'
import SlideAnimationPanel from './SlideAnimationPanel.vue'
import MultiPositionPanel from './MultiPositionPanel.vue'
import SymbolPanel from './SymbolPanel.vue'

interface ElementTabs {
  label: string
  value: ToolbarStates
}

export default defineComponent({
  name: 'toolbar',
  setup() {
    const mainStore = useMainStore()
    const { activeElementIdList, handleElement, toolbarState } = storeToRefs(mainStore)

    const elementTabs = computed<ElementTabs[]>(() => {
      if (handleElement.value?.type === 'text') {
        return [
          { label: '모양', value: ToolbarStates.EL_STYLE },
          { label: '기호', value: ToolbarStates.SYMBOL },
          { label: '위치', value: ToolbarStates.EL_POSITION },
          { label: '애니', value: ToolbarStates.EL_ANIMATION },
        ]
      }
      return [
        { label: '모양', value: ToolbarStates.EL_STYLE },
        { label: '위치', value: ToolbarStates.EL_POSITION },
        { label: '애니', value: ToolbarStates.EL_ANIMATION },
      ]
    })
    const slideTabs = [
      { label: '디자인', value: ToolbarStates.SLIDE_DESIGN },
    ]
    const multiSelectTabs = [
      { label: '모양', value: ToolbarStates.EL_STYLE },
      { label: '위치', value: ToolbarStates.MULTI_POSITION },
    ]

    const setToolbarState = (value: ToolbarStates) => {
      mainStore.setToolbarState(value)
    }

    const currentTabs = computed(() => {
      if (!activeElementIdList.value.length) return slideTabs
      else if (activeElementIdList.value.length > 1) return multiSelectTabs
      return elementTabs.value
    })

    watch(currentTabs, () => {
      const currentTabsValue: ToolbarStates[] = currentTabs.value.map(tab => tab.value)
      if (!currentTabsValue.includes(toolbarState.value)) {
        mainStore.setToolbarState(currentTabsValue[0])
      }
    })

    const currentPanelComponent = computed(() => {
      const panelMap = {
        [ToolbarStates.EL_STYLE]: ElementStylePanel,
        [ToolbarStates.EL_POSITION]: ElementPositionPanel,
        [ToolbarStates.EL_ANIMATION]: ElementAnimationPanel,
        [ToolbarStates.SLIDE_DESIGN]: SlideDesignPanel,
        [ToolbarStates.SLIDE_ANIMATION]: SlideAnimationPanel,
        [ToolbarStates.MULTI_POSITION]: MultiPositionPanel,
        [ToolbarStates.SYMBOL]: SymbolPanel,
      }
      return panelMap[toolbarState.value] || null
    })

    return {
      toolbarState,
      currentTabs,
      setToolbarState,
      currentPanelComponent,
    }
  },
})
