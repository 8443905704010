<template>
    <div class="mod-config">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <el-form-item>
                <el-input v-model="dataForm.key" placeholder="매개변수 이름" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button @click="getDataList()">조회</el-button>
                <el-button v-if="isAuth('generator:clTypeCont:save')" type="primary" @click="addOrUpdateHandle()">신규</el-button>
                <el-button v-if="isAuth('generator:clTypeCont:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">일괄 삭제</el-button>
            </el-form-item>
        </el-form>
        <el-table
                :data="dataList"
                border
                v-loading="dataListLoading"
                @selection-change="selectionChangeHandle"
                style="width: 100%;">
            <el-table-column
                    type="selection"
                    header-align="center"
                    align="center"
                    width="50">
            </el-table-column>
                    <el-table-column
                            prop="seqTy"
                            header-align="center"
                            align="center"
                            label="타입순번">
                    </el-table-column>
                    <el-table-column
                            prop="seqTyc"
                            header-align="center"
                            align="center"
                            label="클래스-타입-컨텐츠 순번">
                    </el-table-column>
                    <el-table-column
                            prop="html1"
                            header-align="center"
                            align="center"
                            label="html_1">
                    </el-table-column>
            <el-table-column
                    fixed="right"
                    header-align="center"
                    align="center"
                    width="150"
                    label="동작">
                <template v-slot="scope">
                    <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.clTypeContId)">개정하다</el-button>
                    <el-button type="text" size="small" @click="deleteHandle(scope.row.clTypeContId)">삭제</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageIndex"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :total="totalPage"
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <!-- 팝업, 추가/수정 -->
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    </div>
</template>

<script>
// import AddOrUpdate from './clTypeCont-add-or-update'
export default {
  data() {
    return {
      dataForm: {
        key: ''
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false
    }
  },
  components: {
    // AddOrUpdate
  },
  activated() {
    this.getDataList()
  },
  methods: {
    // 데이터 목록 가져오기
    getDataList() {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/rest/selectLA40430'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'key': this.dataForm.key
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.dataList = data.page.list
          this.totalPage = data.page.totalCount
        }
        else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 페이지 당
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 현재 페이지
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 다중 선택
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 추가/수정
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    // 삭제
    deleteHandle(id) {
      const ids = id ? [id] : this.dataListSelections.map(item => {
        return item.clTypeContId
      })
      this.$confirm(`승인[id=${ids.join(',')}]진행[${id ? '삭제' : '일괄삭제'}]동작?`, '힌트', {
        confirmButtonText: '승인',
        cancelButtonText: '취소',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/rest/deleteLA40430'),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '성공처리',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          }
          else {
            this.$message.error(data.msg)
          }
        })
      })
    }
  }
}
</script>
