import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b1415b7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "operates" }
const _hoisted_2 = {
  key: 1,
  class: "drag-mask"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElementCreateSelection = _resolveComponent("ElementCreateSelection")!
  const _component_AlignmentLine = _resolveComponent("AlignmentLine")!
  const _component_MultiSelectOperate = _resolveComponent("MultiSelectOperate")!
  const _component_Operate = _resolveComponent("Operate")!
  const _component_ViewportBackground = _resolveComponent("ViewportBackground")!
  const _component_MouseSelection = _resolveComponent("MouseSelection")!
  const _component_EditableElement = _resolveComponent("EditableElement")!
  const _component_Ruler = _resolveComponent("Ruler")!
  const _component_LinkDialog = _resolveComponent("LinkDialog")!
  const _component_Modal = _resolveComponent("Modal")!
  const _directive_contextmenu = _resolveDirective("contextmenu")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "canvas",
    ref: "canvasRef",
    onWheel: _cache[3] || (_cache[3] = $event => _ctx.handleMousewheelCanvas($event)),
    onMousedown: _cache[4] || (_cache[4] = $event => _ctx.handleClickBlankArea($event)),
    style: _normalizeStyle({
      backgroundImage:'url('+require('../../../assets/images/phone_img1.png')+')'
    })
  }, [
    (_ctx.creatingElement)
      ? (_openBlock(), _createBlock(_component_ElementCreateSelection, {
          key: 0,
          onCreated: _cache[0] || (_cache[0] = data => _ctx.insertElementFromCreateSelection(data))
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "viewport-wrapper",
      style: _normalizeStyle({

        width: _ctx.viewportStyles.width * _ctx.canvasScale + 'px',
        height: _ctx.viewportStyles.height * _ctx.canvasScale + 'px',
        left: '50%',
        top: '125px',
        marginLeft: ((_ctx.viewportStyles.width*_ctx.canvasScale)/2)*-1 +'px',


      })
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alignmentLines, (line, index) => {
          return (_openBlock(), _createBlock(_component_AlignmentLine, {
            key: index,
            type: line.type,
            axis: line.axis,
            length: line.length,
            canvasScale: _ctx.canvasScale
          }, null, 8, ["type", "axis", "length", "canvasScale"]))
        }), 128)),
        (_ctx.activeElementIdList.length > 1)
          ? (_openBlock(), _createBlock(_component_MultiSelectOperate, {
              key: 0,
              elementList: _ctx.elementList,
              scaleMultiElement: _ctx.scaleMultiElement
            }, null, 8, ["elementList", "scaleMultiElement"]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elementList, (element) => {
          return (_openBlock(), _createBlock(_component_Operate, {
            key: element.id,
            elementInfo: element,
            isSelected: _ctx.activeElementIdList.includes(element.id),
            isActive: _ctx.handleElementId === element.id,
            isActiveGroupElement: _ctx.activeGroupElementId === element.id,
            isMultiSelect: _ctx.activeElementIdList.length > 1,
            rotateElement: _ctx.rotateElement,
            scaleElement: _ctx.scaleElement,
            openLinkDialog: _ctx.openLinkDialog,
            dragLineElement: _ctx.dragLineElement
          }, null, 8, ["elementInfo", "isSelected", "isActive", "isActiveGroupElement", "isMultiSelect", "rotateElement", "scaleElement", "openLinkDialog", "dragLineElement"]))
        }), 128)),
        _createVNode(_component_ViewportBackground)
      ]),
      _createElementVNode("div", {
        class: "viewport",
        ref: "viewportRef",
        style: _normalizeStyle({ transform: `scale(${_ctx.canvasScale})` })
      }, [
        (_ctx.mouseSelectionVisible)
          ? (_openBlock(), _createBlock(_component_MouseSelection, {
              key: 0,
              top: _ctx.mouseSelection.top,
              left: _ctx.mouseSelection.left,
              width: _ctx.mouseSelection.width,
              height: _ctx.mouseSelection.height,
              quadrant: _ctx.mouseSelectionQuadrant
            }, null, 8, ["top", "left", "width", "height", "quadrant"]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elementList, (element, index) => {
          return (_openBlock(), _createBlock(_component_EditableElement, {
            key: element.id,
            elementInfo: element,
            elementIndex: index + 1,
            isMultiSelect: _ctx.activeElementIdList.length > 1,
            selectElement: _ctx.selectElement,
            openLinkDialog: _ctx.openLinkDialog
          }, null, 8, ["elementInfo", "elementIndex", "isMultiSelect", "selectElement", "openLinkDialog"]))
        }), 128))
      ], 4)
    ], 4),
    (_ctx.spaceKeyState)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.showRuler)
      ? (_openBlock(), _createBlock(_component_Ruler, {
          key: 2,
          viewportStyles: _ctx.viewportStyles
        }, null, 8, ["viewportStyles"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Modal, {
      visible: _ctx.linkDialogVisible,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.linkDialogVisible) = $event)),
      footer: null,
      centered: "",
      width: 540,
      destroyOnClose: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_LinkDialog, {
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.linkDialogVisible = false))
        })
      ]),
      _: 1
    }, 8, ["visible"])
  ], 36)), [
    [_directive_contextmenu, _ctx.contextmenus],
    [_directive_click_outside, _ctx.removeEditorAreaFocus]
  ])
}