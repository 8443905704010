
import { defineComponent, ref } from 'vue'

import { message } from 'ant-design-vue'

export default defineComponent({
  name: 'table-generator',
  emits: ['insert', 'close'],
  setup(props, { emit }) {
    const endCell = ref<number[]>([])
    const customRow = ref(3)
    const customCol = ref(3)
    const isCustom = ref(false)

    const handleClickTable = () => {
      if (!endCell.value.length) return
      const [row, col] = endCell.value
      emit('insert', { row, col })
    }

    const insertCustomTable = () => {
      if (customRow.value < 1 || customRow.value > 20) return message.warning('행수/열수는 0~20 사이여야 합니다!')
      if (customCol.value < 1 || customCol.value > 20) return message.warning('행수/열수는 0~20 사이여야 합니다!')
      emit('insert', { row: customRow.value, col: customCol.value })
      isCustom.value = false
    }

    const close = () => {
      emit('close')
      isCustom.value = false
    }

    return {
      endCell,
      customRow,
      customCol,
      handleClickTable,
      insertCustomTable,
      isCustom,
      close,
    }
  },
})
