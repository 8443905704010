
import { computed, defineComponent, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore, useKeyboardStore } from '@/store'

export default defineComponent({
  name: 'element-create-selection',
  emits: ['created'],
  setup(props, { emit }) {
    const mainStore = useMainStore()
    const { creatingElement } = storeToRefs(mainStore)
    const { ctrlOrShiftKeyActive } = storeToRefs(useKeyboardStore())

    const start = ref<[number, number]>()
    const end = ref<[number, number]>()

    const selectionRef = ref<HTMLElement>()
    const offset = ref({
      x: 0,
      y: 0,
    })
    onMounted(() => {
      if (!selectionRef.value) return
      const { x, y } = selectionRef.value.getBoundingClientRect()
      offset.value = { x, y }
    })

    // 마우스 드래그: 요소 생성 위치 크기 만들기
    // 범위 시작과 끝 위치 가져오기
    const createSelection = (e: MouseEvent) => {
      let isMouseDown = true

      const startPageX = e.pageX
      const startPageY = e.pageY
      start.value = [startPageX, startPageY]

      document.onmousemove = e => {
        if (!creatingElement.value || !isMouseDown) return

        let currentPageX = e.pageX
        let currentPageY = e.pageY

        // Ctrl 키나 Shift 키를 누르고 있을 때:
        // 비선 요소에는 자물쇠 필요가로 세로 크기, 선 요소에 수평 또는 세로 방향 잠금
        if (ctrlOrShiftKeyActive.value) {
          const moveX = currentPageX - startPageX
          const moveY = currentPageY - startPageY

          // 수평 및 수직 방향의 드래그 거리, 후방은 드래그 거리가 큰 방향을 기준으로 반대 방향의 데이터를 계산합니다.
          const absX = Math.abs(moveX)
          const absY = Math.abs(moveY)

          if (creatingElement.value.type === 'shape') {

            // 역방향 드래그 여부 판단: 왼쪽 위에서 오른쪽 아래로 순방향 동작, 모든 경우 역방향 동작
            const isOpposite = (moveY > 0 && moveX < 0) || (moveY < 0 && moveX > 0)

            if (absX > absY) {
              currentPageY = isOpposite ? startPageY - moveX : startPageY + moveX
            }
            else {
              currentPageX = isOpposite ? startPageX - moveY : startPageX + moveY
            }
          }

          else if (creatingElement.value.type === 'line') {
            if (absX > absY) currentPageY = startPageY
            else currentPageX = startPageX
          }
        }

        end.value = [currentPageX, currentPageY]
      }

      document.onmouseup = e => {
        document.onmousemove = null
        document.onmouseup = null

        if (e.button === 2) {
          setTimeout(() => mainStore.setCreatingElement(null), 0)
          return
        }

        isMouseDown = false

        const endPageX = e.pageX
        const endPageY = e.pageY

        const minSize = 30

        if (
          creatingElement.value?.type === 'line' &&
          (Math.abs(endPageX - startPageX) >= minSize || Math.abs(endPageY - startPageY) >= minSize)
        ) {
          emit('created', {
            start: start.value,
            end: end.value,
          })
        }
        else if (
          creatingElement.value?.type !== 'line' &&
          (Math.abs(endPageX - startPageX) >= minSize && Math.abs(endPageY - startPageY) >= minSize)
        ) {
          emit('created', {
            start: start.value,
            end: end.value,
          })
        }
        else {
          const defaultSize = 200
          const minX = Math.min(endPageX, startPageX)
          const minY = Math.min(endPageY, startPageY)
          const maxX = Math.max(endPageX, startPageX)
          const maxY = Math.max(endPageY, startPageY)
          const offsetX = maxX - minX >= minSize ? maxX - minX : defaultSize
          const offsetY = maxY - minY >= minSize ? maxY - minY : defaultSize
          emit('created', {
            start: [minX, minY],
            end: [minX + offsetX, minY + offsetY],
          })
        }
      }
    }

    // 선 그리기 경로 데이터 (선 그리기 요소 유형이 선일 경우에만 사용)
    const lineData = computed(() => {
      if (!start.value || !end.value) return null
      if (!creatingElement.value || creatingElement.value.type !== 'line') return null

      const [_startX, _startY] = start.value
      const [_endX, _endY] = end.value
      const minX = Math.min(_startX, _endX)
      const maxX = Math.max(_startX, _endX)
      const minY = Math.min(_startY, _endY)
      const maxY = Math.max(_startY, _endY)

      const svgWidth = maxX - minX >= 24 ? maxX - minX : 24
      const svgHeight = maxY - minY >= 24 ? maxY - minY : 24

      const startX = _startX === minX ? 0 : maxX - minX
      const startY = _startY === minY ? 0 : maxY - minY
      const endX = _endX === minX ? 0 : maxX - minX
      const endY = _endY === minY ? 0 : maxY - minY

      const path = `M${startX}, ${startY} L${endX}, ${endY}`

      return {
        svgWidth,
        svgHeight,
        startX,
        startY,
        endX,
        endY,
        path,
      }
    })

    // 생성 범위의 시작과 끝 위치에 따라 요소가 생성되었을 때의 위치와 크기를 계산합니다
    const position = computed(() => {
      if (!start.value || !end.value) return {}

      const [startX, startY] = start.value
      const [endX, endY] = end.value
      const minX = Math.min(startX, endX)
      const maxX = Math.max(startX, endX)
      const minY = Math.min(startY, endY)
      const maxY = Math.max(startY, endY)

      const width = maxX - minX
      const height = maxY - minY

      return {
        left: minX - offset.value.x + 'px',
        top: minY - offset.value.y + 'px',
        width: width + 'px',
        height: height + 'px',
      }
    })

    return {
      selectionRef,
      start,
      end,
      creatingElement,
      createSelection,
      lineData,
      position,
    }
  },
})
