import { customAlphabet } from 'nanoid'
import { defineStore } from 'pinia'
import { CreatingElement } from '@/types/edit'
import { ToolbarStates } from '@/types/toolbar'
import { DialogForExportTypes } from '@/types/export'
import { DialogForQuizPop } from '@/types/quizpop'
import { DialogForScreenPreview } from '@/types/screenPreview'
import { SYS_FONTS } from '@/configs/font'
import { TextAttrs, defaultRichTextAttrs } from '@/utils/prosemirror/utils'
import { isSupportFont } from '@/utils/font'

import { useSlidesStore } from './slides'


export interface MainState {
  activeElementIdList: string[]
  handleElementId: string
  activeGroupElementId: string
  canvasPercentage: number
  canvasScale: number
  canvasDragged: boolean
  thumbnailsFocus: boolean
  editorAreaFocus: boolean
  disableHotkeys: boolean
  showGridLines: boolean
  showRuler: boolean
  creatingElement: CreatingElement | null
  availableFonts: typeof SYS_FONTS
  toolbarState: ToolbarStates
  clipingImageElementId: string
  isScaling: boolean
  richTextAttrs: TextAttrs
  selectedTableCells: string[]
  selectedSlidesIndex: number[]
  dialogForExport: DialogForExportTypes
  dialogSave: DialogForExportTypes
  dialogQuizPop: DialogForQuizPop
  dialogScreenPreview : DialogForScreenPreview
  databaseId: string
}

const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')
export const databaseId = nanoid(10)

// @ts-ignore
export const useMainStore = defineStore('main', {
  state: (): MainState => ({
    activeElementIdList: [], // 선택한 요소 ID 모음입니다. handleElementId를 포함합니다.
    handleElementId: '', // 작업 중인 요소 ID
    activeGroupElementId: '', // 그룹 멤버 중 선발되는 요소는 독립할 수 있다. 조작하는 요소 ID
    canvasPercentage: 100, // 캔버스 보기 영역 백분율
    canvasScale: 0.5, // 캔버스 크기 조정 (폭 1000px 기준)
    canvasDragged: false, // 캔버스가 끌려서 움직이다
    thumbnailsFocus: false, // 왼쪽 탐색 미리 보기 영역 초점 맞추기
    editorAreaFocus: false, //  영역 초점 편집
    disableHotkeys: false, // 단축키 사용 안 함
    showGridLines: false, // 격자선 보이기
    showRuler: false, // 눈금자 보이기
    creatingElement: null, // 삽입 중인 요소 정보, 삽입된 요소 (문자, 모양, 선) 을 그립니다.
    availableFonts: SYS_FONTS, // 현재 환경에서 사용 가능한 글꼴
    toolbarState: ToolbarStates.SLIDE_DESIGN, // 오른쪽 도구막대 상태
    clipingImageElementId: '', // 현재 컷팅 중인 그림 ID
    richTextAttrs: defaultRichTextAttrs, // 리치 텍스트 상태
    selectedTableCells: [], // 선택한 테이블 셀
    isScaling: false, // 요소 확대/ 축소 진행 중
    selectedSlidesIndex: [], // 현재 선택된 페이지 색인이 집합하다.
    dialogForExport: '', // 패널 내보내기
    dialogSave: '', // 저장 내보내기
    dialogQuizPop: '',
    dialogScreenPreview: '', // 미리보기 팝업
    databaseId, // 현재 응용 프로그램을 식별하는 indexedDB 데이터베이스 ID
  }),

  getters: {
    activeElementList(state) {
      const slidesStore = useSlidesStore()
      const currentSlide = slidesStore.currentSlide
      if (!currentSlide || !currentSlide.elements) return []
      return currentSlide.elements.filter(element => state.activeElementIdList.includes(element.id))
    },

    handleElement(state) {
      const slidesStore = useSlidesStore()
      const currentSlide = slidesStore.currentSlide
      if (!currentSlide || !currentSlide.elements) return null
      return currentSlide.elements.find(element => state.handleElementId === element.id) || null
    },
  },

  actions: {
    setActiveElementIdList(activeElementIdList: string[]) {
      if (activeElementIdList.length === 1) this.handleElementId = activeElementIdList[0]
      else this.handleElementId = ''

      this.activeElementIdList = activeElementIdList
    },

    setHandleElementId(handleElementId: string) {
      this.handleElementId = handleElementId
    },

    setActiveGroupElementId(activeGroupElementId: string) {
      this.activeGroupElementId = activeGroupElementId
    },

    setCanvasPercentage(percentage: number) {
      this.canvasPercentage = percentage
    },

    setCanvasScale(scale: number) {
      this.canvasScale = scale
    },

    setCanvasDragged(isDragged: boolean) {
      this.canvasDragged = isDragged
    },

    setThumbnailsFocus(isFocus: boolean) {
      this.thumbnailsFocus = isFocus
    },

    setEditorareaFocus(isFocus: boolean) {
      this.editorAreaFocus = isFocus
    },

    setDisableHotkeysState(disable: boolean) {
      this.disableHotkeys = disable
    },

    setGridLinesState(show: boolean) {
      this.showGridLines = show
    },

    setRulerState(show: boolean) {
      this.showRuler = show
    },

    setCreatingElement(element: CreatingElement | null) {
      this.creatingElement = element
    },

    setAvailableFonts() {
      this.availableFonts = SYS_FONTS.filter(font => isSupportFont(font.value))
    },

    setToolbarState(toolbarState: ToolbarStates) {
      this.toolbarState = toolbarState
    },

    setClipingImageElementId(elId: string) {
      this.clipingImageElementId = elId
    },

    setRichtextAttrs(attrs: TextAttrs) {
      this.richTextAttrs = attrs
    },

    setSelectedTableCells(cells: string[]) {
      this.selectedTableCells = cells
    },

    setScalingState(isScaling: boolean) {
      this.isScaling = isScaling
    },

    updateSelectedSlidesIndex(selectedSlidesIndex: number[]) {
      this.selectedSlidesIndex = selectedSlidesIndex
    },

    setDialogForExport(type: DialogForExportTypes) {
      this.dialogForExport = type
    },

    setDialogForQuizPop(type: DialogForQuizPop) {
      this.dialogQuizPop = type
    },

    setDialogSave(type: DialogForExportTypes) {
      this.dialogSave = type
    },

    setDialogForScreenPreview(type: DialogForScreenPreview) {
      this.dialogScreenPreview = type
    }

  },
})
