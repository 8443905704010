
import { defineComponent, PropType, ref, StyleValue } from 'vue'
import WritingBoard from '@/components/WritingBoard.vue'

const writingBoardColors = ['#000000', '#ffffff', '#1e497b', '#4e81bb', '#e2534d', '#9aba60', '#8165a0', '#47acc5', '#f9974c', '#ffff3a']

type WritingBoardModel = 'pen' | 'mark' | 'eraser'

export default defineComponent({
  name: 'writing-board-tool',
  emits: ['close'],
  components: {
    WritingBoard,
  },
  props: {
    slideWidth: {
      type: Number,
      required: true,
    },
    slideHeight: {
      type: Number,
      required: true,
    },
    position: {
      type: Object as PropType<StyleValue>,
      default: () => ({
        right: '5px',
        bottom: '5px',
      })
    },
  },
  setup(props, { emit }) {
    const writingBoardRef = ref()
    const writingBoardColor = ref('#e2534d')
    const writingBoardModel = ref<WritingBoardModel>('pen')
    const blackboard = ref(false)

    const changeModel = (model: WritingBoardModel) => {
      writingBoardModel.value = model
    }

    // 캔버스 위의 먹물을 지우다
    const clearCanvas = () => {
      writingBoardRef.value.clearCanvas()
    }

    // 브러시 색상 수정, 지우개 상태일 때 브러시 상태로 전환
    const changeColor = (color: string) => {
      if (writingBoardModel.value === 'eraser') writingBoardModel.value = 'pen'
      writingBoardColor.value = color
    }
    
    // 쓰기 패드 닫기
    const closeWritingBoard = () => {
      emit('close')
    }

    return {
      writingBoardRef,
      writingBoardColors,
      writingBoardColor,
      writingBoardModel,
      blackboard,
      changeModel,
      clearCanvas,
      changeColor,
      closeWritingBoard,
    }
  },
})
