import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eb1555e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu-content" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "text" }
const _hoisted_4 = {
  key: 0,
  class: "sub-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menu_content = _resolveComponent("menu-content", true)!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (menu, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: menu.text || index
      }, [
        (!menu.hide)
          ? (_openBlock(), _createElementBlock("li", {
              key: 0,
              class: _normalizeClass(["menu-item", {'divider': menu.divider, 'disable': menu.disable}]),
              onClick: _withModifiers(($event: any) => (_ctx.handleClickMenuItem(menu)), ["stop"])
            }, [
              (!menu.divider)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["menu-item-content", {
            'has-children': menu.children,
            'has-handler': menu.handler,
          }])
                  }, [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(menu.text), 1),
                    (menu.subText && !menu.children)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(menu.subText), 1))
                      : _createCommentVNode("", true),
                    (menu.children && menu.children.length)
                      ? (_openBlock(), _createBlock(_component_menu_content, {
                          key: 1,
                          class: "sub-menu",
                          menus: menu.children,
                          handleClickMenuItem: _ctx.handleClickMenuItem
                        }, null, 8, ["menus", "handleClickMenuItem"]))
                      : _createCommentVNode("", true)
                  ], 2))
                : _createCommentVNode("", true)
            ], 10, _hoisted_2))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}