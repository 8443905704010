
import { defineComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useSlidesStore } from '@/store'
import useExport from '@/hooks/useExport'
import axios from 'axios'
export default defineComponent({
  name: 'export-json-dialog',
  setup(props, { emit }) {
    const close = () => emit('close')

    const { slides } = storeToRefs(useSlidesStore())

    const exportJSON = () => {

      const data = {}


    }

    return {
      slides,
      exportJSON,
      close,
    }
  },
})
