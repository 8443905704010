import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4118f3dd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "element-content" }
const _hoisted_2 = ["width", "height", "stroke", "stroke-width"]
const _hoisted_3 = ["transform"]
const _hoisted_4 = ["d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "base-element-latex",
    style: _normalizeStyle({
      top: _ctx.elementInfo.top + 'px',
      left: _ctx.elementInfo.left + 'px',
      width: _ctx.elementInfo.width + 'px',
      height: _ctx.elementInfo.height + 'px',
    })
  }, [
    _createElementVNode("div", {
      class: "rotate-wrapper",
      style: _normalizeStyle({ transform: `rotate(${_ctx.elementInfo.rotate}deg)` })
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(), _createElementBlock("svg", {
          overflow: "visible",
          width: _ctx.elementInfo.width,
          height: _ctx.elementInfo.height,
          stroke: _ctx.elementInfo.color,
          "stroke-width": _ctx.elementInfo.strokeWidth,
          fill: "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }, [
          _createElementVNode("g", {
            transform: `scale(${_ctx.elementInfo.width / _ctx.elementInfo.viewBox[0]}, ${_ctx.elementInfo.height / _ctx.elementInfo.viewBox[1]}) translate(0,0) matrix(1,0,0,1,0,0)`
          }, [
            _createElementVNode("path", {
              d: _ctx.elementInfo.path
            }, null, 8, _hoisted_4)
          ], 8, _hoisted_3)
        ], 8, _hoisted_2))
      ])
    ], 4)
  ], 4))
}