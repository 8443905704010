
import { computed, defineComponent, Ref, ref, watch } from 'vue'
import { round } from 'lodash'
import { storeToRefs } from 'pinia'
import { useMainStore, useSlidesStore } from '@/store'
import { PPTElement } from '@/types/slides'
import { ElementAlignCommands, ElementOrderCommands } from '@/types/edit'
import { MIN_SIZE } from '@/configs/element'
import useOrderElement from '@/hooks/useOrderElement'
import useAlignElementToCanvas from '@/hooks/useAlignElementToCanvas'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

export default defineComponent({
  name: 'element-positopn-panel',
  setup() {
    const slidesStore = useSlidesStore()
    const { handleElement, handleElementId } = storeToRefs(useMainStore())

    const left = ref(0)
    const top = ref(0)
    const width = ref(0)
    const height = ref(0)
    const rotate = ref(0)
    const fixedRatio = ref(false)

    const minSize = computed(() => {
      if (!handleElement.value) return 20
      return MIN_SIZE[handleElement.value.type] || 20
    })

    watch(handleElement, () => {
      if (!handleElement.value) return

      left.value = round(handleElement.value.left, 1)
      top.value = round(handleElement.value.top, 1)

      fixedRatio.value = 'fixedRatio' in handleElement.value && !!handleElement.value.fixedRatio

      if (handleElement.value.type !== 'line') {
        width.value = round(handleElement.value.width, 1)
        height.value = round(handleElement.value.height, 1)
        rotate.value = 'rotate' in handleElement.value && handleElement.value.rotate !== undefined ? round(handleElement.value.rotate, 1) : 0
      }
    }, { deep: true, immediate: true })

    const { orderElement } = useOrderElement()
    const { alignElementToCanvas } = useAlignElementToCanvas()

    const { addHistorySnapshot } = useHistorySnapshot()

    // 요소 위치 설정
    const updateLeft = (value: number) => {
      const props = { left: value }
      slidesStore.updateElement({ id: handleElementId.value, props })
      addHistorySnapshot()
    }
    const updateTop = (value: number) => {
      const props = { top: value }
      slidesStore.updateElement({ id: handleElementId.value, props })
      addHistorySnapshot()
    }

    // 요소 폭, 높이, 회전 각도 설정
    const updateWidth = (value: number) => {
      const props = { width: value }
      slidesStore.updateElement({ id: handleElementId.value, props })
      addHistorySnapshot()
    }
    const updateHeight = (value: number) => {
      const props = { height: value }
      slidesStore.updateElement({ id: handleElementId.value, props })
      addHistorySnapshot()
    }
    const updateRotate = (value: number) => {
      const props = { rotate: value }
      slidesStore.updateElement({ id: handleElementId.value, props })
      addHistorySnapshot()
    }

    // 고정 원소의 종횡비
    const updateFixedRatio = (value: boolean) => {
      const props = { fixedRatio: value }
      slidesStore.updateElement({ id: handleElementId.value, props })
      addHistorySnapshot()
    }

    // 요소를 45도 회전(시계방향 또는 반시계방향)
    const updateRotate45 = (command: '+' | '-') => {
      let _rotate = Math.floor(rotate.value / 45) * 45
      if (command === '+') _rotate = _rotate + 45
      else if (command === '-') _rotate = _rotate - 45

      if (_rotate < -180) _rotate = -180
      if (_rotate > 180) _rotate = 180

      const props = { rotate: _rotate }
      slidesStore.updateElement({ id: handleElementId.value, props })
      addHistorySnapshot()
    }

    return {
      handleElement: handleElement as Ref<PPTElement>,
      orderElement,
      alignElementToCanvas,
      left,
      top,
      width,
      height,
      rotate,
      fixedRatio,
      minSize,
      updateLeft,
      updateTop,
      updateWidth,
      updateHeight,
      updateRotate,
      updateFixedRatio,
      updateRotate45,
      ElementOrderCommands,
      ElementAlignCommands,
    }
  },
})
