

import { defineComponent, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useScreenStore, useMainStore, useSnapshotStore } from '@/store'
import { LOCALSTORAGE_KEY_DISCARDED_DB } from '@/configs/storage'
import { isPC } from './utils/common'

import Editor from './views/Editor/index.vue'
import Screen from './views/Screen/index.vue'
import Mobile from './views/Mobile/index.vue'



export default defineComponent({
  name: 'app',
  components: {
    Editor,
    Screen,
    Mobile,
  },
  setup() {

    const url = window.location.href.split('?')

    // eslint-disable-next-line eqeqeq
    if (!url || url.length === 1 || url[1].indexOf('seq_ty') === -1) {
      return false
    }
    const mainStore = useMainStore()
    const snapshotStore = useSnapshotStore()
    const { databaseId } = storeToRefs(mainStore)
    const { screening } = storeToRefs(useScreenStore())

    if (process.env.NODE_ENV === 'production') {
      window.onbeforeunload = () => false
    }

    onMounted(() => {
      snapshotStore.initSnapshotDatabase()
      mainStore.setAvailableFonts()
    })

    // 로그아웃할 때 localStorage에 이번 indexedDB의 데이터베이스 ID를 기록하여 나중에 데이터베이스를 지울 때 사용합니다
    window.addEventListener('unload', () => {
      const discardedDB = localStorage.getItem(LOCALSTORAGE_KEY_DISCARDED_DB)
      const discardedDBList: string[] = discardedDB ? JSON.parse(discardedDB) : []

      discardedDBList.push(databaseId.value)

      const newDiscardedDB = JSON.stringify(discardedDBList)
      localStorage.setItem(LOCALSTORAGE_KEY_DISCARDED_DB, newDiscardedDB)
    })

    return {
      screening,
      isPC: isPC(),
    }
  },
})
