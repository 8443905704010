
import { computed, defineComponent, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useSlidesStore } from '@/store'
import useExport from '@/hooks/useExport'

export default defineComponent({
  name: 'export-bitist-dialog',
  setup(props, { emit }) {
    const { slides, currentSlide } = storeToRefs(useSlidesStore())

    const rangeType = ref<'all' | 'current' | 'custom'>('all')
    const range = ref<[number, number]>([1, slides.value.length])

    const selectedSlides = computed(() => {
      if (rangeType.value === 'all') return slides.value
      if (rangeType.value === 'current') return [currentSlide.value]
      return slides.value.filter((item, index) => {
        const [min, max] = range.value
        return index >= min - 1 && index <= max - 1
      })
    })

    const close = () => emit('close')

    const { exportSpecificFile } = useExport()
    
    return {
      slides,
      rangeType,
      range,
      selectedSlides,
      exportSpecificFile,
      close,
    }
  },
})
