
import { defineComponent, PropType } from 'vue'
import { OperateBorderLines } from '@/types/edit'

export default defineComponent({
  name: 'border-line',
  props: {
    type: {
      type: String as PropType<OperateBorderLines>,
      required: true,
    },
    isWide: {
      type: Boolean,
      default: false,
    },
  },
})
