import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ea75e66"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "line-pool" }
const _hoisted_2 = { class: "category-name" }
const _hoisted_3 = { class: "line-list" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  overflow: "visible",
  width: "20",
  height: "20"
}
const _hoisted_6 = ["d", "stroke-dasharray", "marker-start", "marker-end"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinePointMarker = _resolveComponent("LinePointMarker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lineList, (item, i) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "category",
        key: item.type
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(item.type), 1),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (line, j) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "line-item",
              key: j
            }, [
              _createElementVNode("div", {
                class: "line-content",
                onClick: ($event: any) => (_ctx.selectLine(line))
              }, [
                (_openBlock(), _createElementBlock("svg", _hoisted_5, [
                  _createElementVNode("defs", null, [
                    (line.points[0])
                      ? (_openBlock(), _createBlock(_component_LinePointMarker, {
                          key: 0,
                          class: "line-marker",
                          id: `preset-line-${i}-${j}`,
                          position: "start",
                          type: line.points[0],
                          color: "currentColor",
                          baseSize: 2
                        }, null, 8, ["id", "type"]))
                      : _createCommentVNode("", true),
                    (line.points[1])
                      ? (_openBlock(), _createBlock(_component_LinePointMarker, {
                          key: 1,
                          class: "line-marker",
                          id: `preset-line-${i}-${j}`,
                          position: "end",
                          type: line.points[1],
                          color: "currentColor",
                          baseSize: 2
                        }, null, 8, ["id", "type"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("path", {
                    class: "line-path",
                    d: line.path,
                    stroke: "currentColor",
                    fill: "none",
                    "stroke-width": "2",
                    "stroke-dasharray": line.style === 'solid' ? '0, 0' : '4, 1',
                    "marker-start": line.points[0] ? `url(#${`preset-line-${i}-${j}`}-${line.points[0]}-start)` : '',
                    "marker-end": line.points[1] ? `url(#${`preset-line-${i}-${j}`}-${line.points[1]}-end)` : ''
                  }, null, 8, _hoisted_6)
                ]))
              ], 8, _hoisted_4)
            ]))
          }), 128))
        ])
      ]))
    }), 128))
  ]))
}