
import { defineComponent, onUnmounted, Ref, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore, useSlidesStore } from '@/store'
import { ChartData, ChartOptions, PPTChartElement } from '@/types/slides'
import emitter, { EmitterEvents } from '@/utils/emitter'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

import ElementOutline from '../../common/ElementOutline.vue'
import ColorButton from '../../common/ColorButton.vue'
import ChartDataEditor from './ChartDataEditor.vue'

const presetChartThemes = [
  ['#d87c7c', '#919e8b', '#d7ab82', '#6e7074', '#61a0a8', '#efa18d'],
  ['#dd6b66', '#759aa0', '#e69d87', '#8dc1a9', '#ea7e53', '#eedd78'],
  ['#516b91', '#59c4e6', '#edafda', '#93b7e3', '#a5e7f0', '#cbb0e3'],
  ['#893448', '#d95850', '#eb8146', '#ffb248', '#f2d643', '#ebdba4'],
  ['#4ea397', '#22c3aa', '#7bd9a5', '#d0648a', '#f58db2', '#f2b3c9'],
  ['#3fb1e3', '#6be6c1', '#626c91', '#a0a7e6', '#c4ebad', '#96dee8'],
  ['#fc97af', '#87f7cf', '#f7f494', '#72ccff', '#f7c5a0', '#d4a4eb'],
  ['#c1232b', '#27727b', '#fcce10', '#e87c25', '#b5c334', '#fe8463'],
  ['#2ec7c9', '#b6a2de', '#5ab1ef', '#ffb980', '#d87a80', '#8d98b3'],
  ['#e01f54', '#001852', '#f5e8c8', '#b8d2c7', '#c6b38e', '#a4d8c2'],
  ['#c12e34', '#e6b600', '#0098d9', '#2b821d', '#005eaa', '#339ca8'],
  ['#8a7ca8', '#e098c7', '#8fd3e8', '#71669e', '#cc70af', '#7cb4cc'],
]

export default defineComponent({
  name: 'chart-style-panel',
  components: {
    ElementOutline,
    ChartDataEditor,
    ColorButton,
  },
  setup() {
    const mainStore = useMainStore()
    const slidesStore = useSlidesStore()
    const { handleElement, handleElementId } = storeToRefs(mainStore)
    const { theme } = storeToRefs(slidesStore)

    const chartDataEditorVisible = ref(false)
    const presetThemesVisible = ref(false)
    const presetThemeColorHoverIndex = ref<[number, number]>([-1, -1])

    const { addHistorySnapshot } = useHistorySnapshot()

    const fill = ref<string>('#000')

    const themeColor = ref<string[]>([])
    const gridColor = ref('')
    const legend = ref('')

    const lineSmooth = ref(true)
    const showLine = ref(true)
    const showArea = ref(false)
    const horizontalBars = ref(false)
    const donut = ref(false)
    const stackBars = ref(false)

    watch(handleElement, () => {
      if (!handleElement.value || handleElement.value.type !== 'chart') return
      fill.value = handleElement.value.fill || '#fff'

      if (handleElement.value.options) {
        const {
          lineSmooth: _lineSmooth,
          showLine: _showLine,
          showArea: _showArea,
          horizontalBars: _horizontalBars,
          donut: _donut,
          stackBars: _stackBars,
        } = handleElement.value.options

        if (_lineSmooth !== undefined) lineSmooth.value = _lineSmooth as boolean
        if (_showLine !== undefined) showLine.value = _showLine
        if (_showArea !== undefined) showArea.value = _showArea
        if (_horizontalBars !== undefined) horizontalBars.value = _horizontalBars
        if (_donut !== undefined) donut.value = _donut
        if (_stackBars !== undefined) stackBars.value = _stackBars
      }

      themeColor.value = handleElement.value.themeColor
      gridColor.value = handleElement.value.gridColor || '#333'
      legend.value = handleElement.value.legend || ''
    }, { deep: true, immediate: true })

    const updateElement = (props: Partial<PPTChartElement>) => {
      slidesStore.updateElement({ id: handleElementId.value, props })
      addHistorySnapshot()
    }

    // 그래프 데이터 설정
    const updateData = (data: ChartData) => {
      chartDataEditorVisible.value = false
      updateElement({ data })
    }

    // 색칠설정
    const updateFill = (value: string) => {
      updateElement({ fill: value })
    }

    // 기타 옵션 설정: 막대 그래프, 꺾은 선 그래프, 꺾은 선 그래프, 산점도, 원형 그래프, 꺾은 선 그래프 스위치 곡선
    const updateOptions = (optionProps: ChartOptions) => {
      const _handleElement = handleElement.value as PPTChartElement

      const newOptions = { ..._handleElement.options, ...optionProps }
      updateElement({ options: newOptions })
    }

    // 테마색상설정
    const updateTheme = (color: string, index: number) => {
      const props = {
        themeColor: themeColor.value.map((c, i) => i === index ? color : c),
      }
      updateElement(props)
    }

    // 테마색상추가
    const addThemeColor = () => {
      const props = {
        themeColor: [...themeColor.value, theme.value.themeColor],
      }
      updateElement(props)
    }

    // 미리 설정된 테마 색상 사용하기
    const applyPresetTheme = (colors: string[], index: number) => {
      const themeColor = colors.slice(0, index + 1)
      updateElement({ themeColor })
      presetThemesVisible.value = false
    }

    // 테마 색상 삭제
    const deleteThemeColor = (index: number) => {
      const props = {
        themeColor: themeColor.value.filter((c, i) => i !== index),
      }
      updateElement(props)
    }

    // 그리드 색상 설정
    const updateGridColor = (gridColor: string) => {
      updateElement({ gridColor })
    }

    // 범례 위치 설정/표시 안 함
    const updateLegend = (legend: '' | 'top' | 'bottom') => {
      updateElement({ legend })
    }

    const openDataEditor = () => chartDataEditorVisible.value = true

    emitter.on(EmitterEvents.OPEN_CHART_DATA_EDITOR, openDataEditor)
    onUnmounted(() => {
      emitter.off(EmitterEvents.OPEN_CHART_DATA_EDITOR, openDataEditor)
    })

    return {
      chartDataEditorVisible,
      presetThemesVisible,
      presetThemeColorHoverIndex,
      handleElement: handleElement as Ref<PPTChartElement>,
      updateData,
      fill,
      updateFill,
      lineSmooth,
      showLine,
      showArea,
      horizontalBars,
      donut,
      stackBars,
      updateOptions,
      themeColor,
      gridColor,
      legend,
      updateTheme,
      addThemeColor,
      deleteThemeColor,
      updateGridColor,
      updateLegend,
      presetChartThemes,
      applyPresetTheme,
    }
  },
})
