
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'fullscreen-spin',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    tip: {
      type: String,
      default: '',
    },
  },
})
