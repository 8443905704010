
import { defineComponent, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore, useSnapshotStore } from '@/store'
import { getImageDataURL } from '@/utils/image'
import { ShapePoolItem } from '@/configs/shapes'
import { LinePoolItem } from '@/configs/lines'
import useScaleCanvas from '@/hooks/useScaleCanvas'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'
import useCreateElement from '@/hooks/useCreateElement'

import ShapePool from './ShapePool.vue'
import LinePool from './LinePool.vue'
import ChartPool from './ChartPool.vue'
import TableGenerator from './TableGenerator.vue'
import MediaInput from './MediaInput.vue'
import LaTeXEditor from '@/components/LaTeXEditor/index.vue'
import BasicFields from './basicFields.vue'

export default defineComponent({
  name: 'canvas-tool',
  components: {
    ShapePool,
    LinePool,
    ChartPool,
    TableGenerator,
    MediaInput,
    LaTeXEditor,
    BasicFields,
  },
  setup() {
    const mainStore = useMainStore()
    const { creatingElement } = storeToRefs(mainStore)
    const { canUndo, canRedo } = storeToRefs(useSnapshotStore())
    const { redo, undo } = useHistorySnapshot()

    const {
      scaleCanvas,
      setCanvasScalePercentage,
      resetCanvas,
      canvasScalePercentage,
    } = useScaleCanvas()

    const canvasScalePresetList = [200, 150, 100, 80, 50]
    const canvasScaleVisible = ref(false)

    const applyCanvasPresetScale = (value: number) => {
      setCanvasScalePercentage(value)
      canvasScaleVisible.value = false
    }

    const {
      createImageElement,
      createChartElement,
      createTableElement,
      createLatexElement,
      createVideoElement,
      createAudioElement,
    } = useCreateElement()

    const insertImageElement = (files: File[]) => {
      const imageFile = files[0]
      if (!imageFile) return
      getImageDataURL(imageFile).then(dataURL => createImageElement(dataURL))
    }

    const shapePoolVisible = ref(false)
    const linePoolVisible = ref(false)
    const chartPoolVisible = ref(false)
    const tableGeneratorVisible = ref(false)
    const mediaInputVisible = ref(false)
    const latexEditorVisible = ref(false)
    // 기본필드추가
    const basicFieldsVisible = ref(false)

    // 텍스트 범위 그리기
    const drawText = () => {
      mainStore.setCreatingElement({
        type: 'text',
      })
    }

    // 도형 범위 그리기
    const drawShape = (shape: ShapePoolItem) => {
      mainStore.setCreatingElement({
        type: 'shape',
        data: shape,
      })
      shapePoolVisible.value = false
    }

    // 선경로 그리기
    const drawLine = (line: LinePoolItem) => {
      mainStore.setCreatingElement({
        type: 'line',
        data: line,
      })
      linePoolVisible.value = false
    }

    return {
      scaleCanvas,
      resetCanvas,
      canvasScalePercentage,
      canvasScaleVisible,
      canvasScalePresetList,
      applyCanvasPresetScale,
      canUndo,
      canRedo,
      redo,
      undo,
      insertImageElement,
      shapePoolVisible,
      linePoolVisible,
      basicFieldsVisible,
      chartPoolVisible,
      tableGeneratorVisible,
      mediaInputVisible,
      latexEditorVisible,
      creatingElement,
      drawText,
      drawShape,
      drawLine,
      createChartElement,
      createTableElement,
      createLatexElement,
      createVideoElement,
      createAudioElement,
    }
  }
})
