export const ELEMENT_TYPE_ZH = {
  text: '텍스트',
  image: '그림',
  shape: '모양',
  라인: '라인',
  chart: '그래프',
  table: '표',
  video: '동영상',
  오디오: '오디오',
}

export const MIN_SIZE = {
  text: 20,
  image: 20,
  shape: 15,
  chart: 200,
  table: 20,
  video: 250,
  audio: 20,
}