
import { computed, PropType, defineComponent, provide } from 'vue'
import { storeToRefs } from 'pinia'
import { useSlidesStore } from '@/store'
import { Slide } from '@/types/slides'
import { injectKeySlideScale } from '@/types/injectKey'
import { VIEWPORT_SIZE } from '@/configs/canvas'
import useSlideBackgroundStyle from '@/hooks/useSlideBackgroundStyle'

import ThumbnailElement from './ThumbnailElement.vue'

export default defineComponent({
  name: 'thumbnail-slide',
  components: {
    ThumbnailElement,
  },
  props: {
    slide: {
      type: Object as PropType<Slide>,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { viewportRatio } = storeToRefs(useSlidesStore())

    const background = computed(() => props.slide.background)
    const { backgroundStyle } = useSlideBackgroundStyle(background)

    const scale = computed(() => props.size / VIEWPORT_SIZE)
    provide(injectKeySlideScale, scale)

    return {
      scale,
      backgroundStyle,
      VIEWPORT_SIZE,
      viewportRatio,
    }
  },
})
